<template>
    <div id="riprendi">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="inRicerca" v-bind="attrs" v-on="on" > RIPRENDI RICERCA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 inRicerca lighten-2"> RIPRENDERE LA RICERCA? </v-card-title>
                <v-card-text>
                    <b>Riprendere</b> la ricerca, porterà l'incarico nello stato di 'In Ricerca'
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn depressed color="inRicerca" @click="dialog = false;RiprendiRicerca()" > RIPRENDI </v-btn>
                        <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
    data() {
        return {
            dialog:false
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['riprendiIncaricoAction']),
        RiprendiRicerca(){
            this.toogleLoading(true)
            this.riprendiIncaricoAction(this.incarico._id).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Ripresa Incarico:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Ripresa Incarico:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 20px;
}
</style>