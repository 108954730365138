<template>
    <div id="user_preview">
        <router-link :to="'/admin/agenti/'+user._id">
        <div class="card elevation-9 buzz-out-on-hover">
            <div class="img" v-if="user.foto" :style="'background-image: url(\''+user.foto+'\')'"></div>
            <div class="img" v-if="!user.foto" :style="'background-image: url(\''+require('../../assets/imgs/empty-profile.png')+'\')'"></div>
            <div class="nome">{{user.nome + ' ' + user.cognome}}</div>
            <div class="figura">{{user.figura}}</div>
        </div>
        </router-link>
        
    </div>
</template>

<script>
export default {
    props:['user'],
}
</script>

<style lang="scss" scoped>
#user_preview{
    max-width: 200px;
    a{
        text-decoration: none;
        color: rgb(27, 27, 27);    
    }
    
    .card{
        min-width: 100px;
        margin: 10px;
        overflow: hidden;
        padding: 15px;
        background: white;
        border-radius: 10px;
        transition-duration: 0.2s;
        .img{
            width: 100px;
            background-color: rgb(255, 255, 255);
            padding-bottom: 100px;
            border-radius: 10px;
            margin: 0px auto;
            background-position: center center;
            background-size: cover;
        }
        .nome{
            font-weight: 600;
            text-align: center;
            line-height: 15px;
            margin-top: 10px;
        }
        .figura{
            margin-top: 5px;
            font-weight: 400;
            text-align: center;
        }
    }
}
</style>