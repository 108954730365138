import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    videoFormazione:[],
    retrivedVideoFormazione:false,
}

const getters = {
    videoFormazione: state => state.videoFormazione,
    retrivedVideoFormazione: state => state.retrivedVideoFormazione,
}

const actions = {
    async createVideoFormazione({commit},form){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/videoFormazione/add',{
                form: form,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("createVideoFormazioneFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async deleteVideoFormazione({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/videoFormazione/delete',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("deleteVideoFormazioneFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async fetchAllVideoFormazione({commit}){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.get(Server+'/videoFormazione/getAll',config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("fetchAllVideoFormazioneFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async editVideoFormazione({commit},form){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/videoFormazione/edit',{
                form: form,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editVideoFormazioneFun",response.data);
                resolve(response.data); 
            })
        })
    },
}

const mutations = {
    createVideoFormazioneFun(state,response){
        state.videoFormazione.push(response.formazione);
    },
    fetchAllVideoFormazioneFun(state,response){
        state.videoFormazione = response.formazioni;
        state.retrivedVideoFormazione = true;
    },
    deleteVideoFormazioneFun(state,response){
        var index = state.videoFormazione.findIndex(formazione => formazione._id == response.formazione._id);
        if(index!=-1){
            state.videoFormazione.splice(index,1);
        }
    },
    editVideoFormazioneFun(state,response){
        var index = state.videoFormazione.findIndex(formazione => formazione._id == response.formazione._id);
        if(index!=-1){
            state.videoFormazione[index]=response.formazione;
        }
    }
}



export default{
    state,
    getters,
    actions,
    mutations
}