<template>
    <div id="incarico_settings">
        <div class="flexxable">
            <div class="impostazioni elevation-4">
                <v-form ref="form" @submit.prevent="submit" v-model="isFormValid">
                    <v-select :items="optionsStato" v-model="form.stato" label="Stato" outlined dense ></v-select>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-dialog ref="dialog1" v-model="modal1" :return-value.sync="form.data_inizio" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.data_inizio" label="Data Inizio." readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="form.data_inizio" scrollable >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog1.save(form.data_inizio)" > OK </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field 
                                    v-model="form.numero_perse" 
                                    type="number"
                                    max="3"
                                    min="0"
                                    label="Numero Perse" 
                                    clearable outlined dense
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    

                    <div class="divisore">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">UTENTE</div>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="form.utente.nome" label="Nome" clearable outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field v-model="form.utente.cognome" label="Cognome" clearable outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row style="margin-top:-25px">
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="form.utente.email" :rules="emailRules" label="Email" clearable outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field v-model="form.utente.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="divisore">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">DATI RICERCA</div>
                    </div>
                    <vuetify-money v-model="form.costo_ricerca.prezzo" label="Costo Ricerca" :options="optionsCostoRicerca" clearable outlined dense />    
                    <v-row>
                        <v-col cols="4" class="tryFlex">
                            <v-checkbox v-model="form.costo_ricerca.pagato" label="Pagata Ricerca"></v-checkbox>    
                        </v-col>
                        <v-col cols="8">
                            <v-dialog ref="dialog2" v-model="modal2" :return-value.sync="form.costo_ricerca.dataPagamento" persistent width="290px" :disabled="!form.costo_ricerca.pagato">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.costo_ricerca.dataPagamento" label="Data Pagamento Ricerca" readonly v-bind="attrs" v-on="on" clearable outlined dense :disabled="!form.costo_ricerca.pagato"></v-text-field>
                                </template>
                                <v-date-picker v-model="form.costo_ricerca.dataPagamento" scrollable >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal2 = false" > Cancel </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog2.save(form.costo_ricerca.dataPagamento)" > OK </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <div class="datiImmobile" v-if="form.stato!='In Ricerca' && form.stato!='Ricerca Sospesa' && form.stato!='Rinuncia Ricerca'">
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">DATI IMMOBILE</div>
                        </div>
                        <v-text-field v-model="form.immobile.indirizzo" label="Indirizzo Immobile" clearable outlined dense></v-text-field>
                        <vuetify-money v-model="form.immobile.valore" label="Valore Immobile" :options="optionsCostoRicerca" clearable outlined dense /> 
                    </div>
                    <div class="datiAsta" 
                        v-if="form.stato!='In Ricerca' && form.stato!='Ricerca Sospesa' && form.stato!='Rinuncia Ricerca' && form.stato!='Ricerca Conclusa'">
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">DATI ASTA</div>
                        </div>
                        <vuetify-money v-model="form.importo_asta" label="Importo Asta" :options="optionsCostoRicerca" clearable outlined dense />
                        <v-dialog ref="dialog3" v-model="modal3" :return-value.sync="form.data_asta" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.data_asta" label="Data Pagamento Provvigione" readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                            </template>
                            <v-date-picker v-model="form.data_asta" scrollable >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal3 = false" > Cancel </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog3.save(form.data_asta)" > OK </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <v-row style="margin-bottom:0px">
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    prefix="%"
                                    v-model="form.provvigione.perc" 
                                    type="number" 
                                    label="Percentuale Provvigione" 
                                    clearable outlined dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" class="noMtOnLow">
                                <vuetify-money v-model="form.provvigione.euro_num" label="Provvigione in Euro" :options="optionsCostoRicerca" clearable outlined dense />
                            </v-col>
                            <v-col cols="12" sm="4" class="noMtOnLow">
                                <v-text-field v-model="form.provvigione.euro_let" label="Provvigione in Lettere" clearable outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-25px">
                            <v-col cols="4" class="tryFlex">
                                <v-checkbox v-model="form.provvigione.pagato" label="Pagata Provvigione"></v-checkbox>    
                            </v-col>
                            <v-col cols="8">
                                <v-dialog ref="dialog4" v-model="modal4" :return-value.sync="form.provvigione.dataPagamento" persistent width="290px" :disabled="!form.provvigione.pagato">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="form.provvigione.dataPagamento" label="Data Pagamento Provvigione" readonly v-bind="attrs" v-on="on" clearable outlined dense :disabled="!form.provvigione.pagato"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.provvigione.dataPagamento" scrollable >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modal4 = false" > Cancel </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog4.save(form.provvigione.dataPagamento)" > OK </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </div>

                    <v-btn block color="orange" type="submit" :disabled="!isFormValid"> MODIFICA </v-btn>
                </v-form>
            </div>
            <div class="actions elevation-4">
                <v-dialog v-model="dialog_resettaP" width="500" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" dark v-bind="attrs" v-on="on" > CANCELLA </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h5"> SEI SICURO? </v-card-title>
                        <v-card-text>
                            <b class="redText">Cancellare</b> implica non poter recuperare più nessuna informazione da questo incarico.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dialog_resettaP = false" > Annulla </v-btn>
                            <v-btn color="error" @click="cancella" > CANCELLA </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props:['incarico'],
    data() {
        return {
            dialog_resettaP:false,
            modal1:false,
            modal2:false,
            modal3:false,
            modal4:false,
            form:{
                _id:'',
                stato:'',
                data_inizio:'',
                numero_perse:0,
                utente:{
                    nome:undefined,
                    cognome:undefined,
                    email:undefined,
                    cellulare:undefined,
                },
                costo_ricerca:{
                    prezzo:undefined,
                    pagato:undefined,
                    dataPagamento:undefined,
                },
                immobile:{
                    indirizzo:undefined,
                    valore:undefined,
                },
                importo_asta:undefined,
                provvigione:{
                    perc:undefined,
                    euro_num:undefined,
                    euro_let:undefined,
                    pagato:undefined,
                    dataPagamento:undefined,
                },
                data_asta:undefined
            },
            isFormValid:false,
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
            optionsStato: ['In Ricerca','Ricerca Sospesa','Ricerca Conclusa','In Asta','Aggiudicata','Persa','Rinuncia Ricerca','Rinuncia Asta'],
            emailRules: [
                v => !v || /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  'E-mail non valida',
            ],
        }
    },
    watch:{
        incarico(){
            this.setupForm();
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['deleteIncaricoFromAdmin','editIncaricoFromAdmin']),
        cancella(){
            this.toogleLoading(true)
            this.deleteIncaricoFromAdmin(this.incarico._id).then(()=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Incarico:',
                    text: 'Riuscita',
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$router.push('/admin/incarichi');
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Incarico:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        },
        submit(){
            if(!this.form.data_inizio){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Incarico:',
                    text: 'Data Inizio è richiesta',
                    duration: 2000,
                    type: 'error'
                });
            }
            this.toogleLoading(true)
            this.editIncaricoFromAdmin(this.form).then(()=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Incarico:',
                    text: 'Riuscita',
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(()=>{this.toogleLoading(false)});
        },
        setupForm(){
            this.form._id = this.incarico._id;
            this.form.stato = this.incarico.stato;
            this.form.numero_perse = this.incarico.numero_perse;
            this.form.data_inizio = this.incarico.data_inizio;
            if(this.incarico.utente){
                this.form.utente.nome = this.incarico.utente.nome;
                this.form.utente.cognome = this.incarico.utente.cognome;   
                this.form.utente.email = this.incarico.utente.email; 
                this.form.utente.cellulare = this.incarico.utente.cellulare; 
            }
            if(this.incarico.costo_ricerca){
                this.form.costo_ricerca.prezzo = this.incarico.costo_ricerca.prezzo;
                this.form.costo_ricerca.pagato = this.incarico.costo_ricerca.pagato;
                this.form.costo_ricerca.dataPagamento = this.incarico.costo_ricerca.dataPagamento;
            }
            if(this.incarico.immobile){
                this.form.immobile.indirizzo = this.incarico.immobile.indirizzo;
                this.form.immobile.valore = this.incarico.immobile.valore;
            }
            this.form.importo_asta = this.incarico.importo_asta;
            if(this.incarico.provvigione){
                this.form.provvigione.perc = this.incarico.provvigione.perc;
                this.form.provvigione.euro_num = this.incarico.provvigione.euro_num;
                this.form.provvigione.euro_let = this.incarico.provvigione.euro_let;
                this.form.provvigione.pagato = this.incarico.provvigione.pagato;
                this.form.provvigione.dataPagamento = this.incarico.provvigione.dataPagamento;
            }
            this.form.data_asta = this.incarico.data_asta;
        }
    },mounted() {
        this.setupForm();
    },
}
</script>

<style lang="scss" scoped>
.flexxable{
    display: flex;
    max-width: 1200px;
    margin: 10px auto;
    column-gap: 10px;
    row-gap: 20px;
    @media screen and (max-width: 700px) {flex-flow: column;}
    .impostazioni{
        flex: 3;
        border-radius: 15px;
        overflow: hidden;
        padding: 20px;
    }
    .actions{
        flex: 1;
        border-radius: 15px;
        overflow: hidden;
        padding: 10px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        row-gap: 5px;
        height: 100px;
    }
}

.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.tryFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.v-input--selection-controls{
    margin-top: 3px;
}

.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}
</style>