import { render, staticRenderFns } from "./StoricoIncarichi.vue?vue&type=template&id=21afa4f8&scoped=true&"
import script from "./StoricoIncarichi.vue?vue&type=script&lang=js&"
export * from "./StoricoIncarichi.vue?vue&type=script&lang=js&"
import style0 from "./StoricoIncarichi.vue?vue&type=style&index=0&id=21afa4f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21afa4f8",
  null
  
)

export default component.exports