<template>
    <div id="generale">
        
        <div class="incarichi" v-if="incarichiCounter.total>0">
            <div class="secTitle notSelectableTxt">INCARICHI</div>
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize,
                    color:'inRicerca',
                    widthUnder380:'100'
                }" :percent="Math.floor((incarichiCounter.inRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.inRicerca" :name="'In Ricerca'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaSospesa',
                    widthUnder380:'100'
                }" :percent="Math.floor((incarichiCounter.ricercaSospesa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaSospesa" :name="'Ricerca Sospesa'"/>
 
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaConclusa',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.ricercaConclusa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaConclusa" :name="'Ricerca Conclusa'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize,
                    color:'primaryColor',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.inAsta*100)/incarichiCounter.total)" :count="incarichiCounter.inAsta" :name="'In Asta'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize,
                    color:'aggiudicata',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.aggiudicata*100)/incarichiCounter.total)" :count="incarichiCounter.aggiudicata" :name="'Aggiudicata'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize,
                    color:'persa',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.persa*100)/incarichiCounter.total)" :count="incarichiCounter.persa" :name="'Persa'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'rinunciaRicerca',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.rinunciaRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaRicerca" :name="'Rinuncia Ricerca'"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-1,
                    color:'rinunciaAsta',
                    widthUnder380:'100'
                    }" :percent="Math.floor((incarichiCounter.rinunciaAsta*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaAsta" :name="'Rinuncia Asta'"/>
            </div>
            <div class="incarichiStatistiche" v-if="incarichiCounter.total>0">
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaSospesa',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }" :percent="incarichiStats.ricercheDaPagare!=0?Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare)):0" :count="moneyFormat(incarichiStats.ricercheDaPagareVal)+'€'" :name="'Ricerche Da Pagare'" :subb="incarichiStats.ricercheDaPagare"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'aggiudicata',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="100-Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare))"  :count="moneyFormat(incarichiStats.ricerchePagateVal)+'€'" :name="'Ricerche Pagate'" :subb="incarichiStats.ricerchePagate"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaSospesa',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="incarichiStats.provvigioniDaPagare!=0?Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate)):0" :count="moneyFormat(incarichiStats.provvigioniDaPagareVal)+'€'" :name="'Provvigioni Da Pagare'" :subb="incarichiStats.provvigioniDaPagare"/>

                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'aggiudicata',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="incarichiStats.provvigioniPagate!=0?100-Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate)):0"  :count="moneyFormat(incarichiStats.provvigioniPagateVal)+'€'" :name="'Provvigioni Pagate'" :subb="incarichiStats.provvigioniPagate"/>
        </div>
        <v-divider v-if="incarichiCounter.total>0"></v-divider>

        <div class="utentiGestiti" v-if="user.usersMenaged && user.usersMenaged.length>0">
            <div class="secTitle notSelectableTxt">AGENTI GESTITI</div>
            <UserPreview :user="agente" v-for="agente in user.usersMenaged" :key="agente._id" />
        </div>

    </div>
</template>

<script>
import CircularChar from '../circularChar.vue'
import UserPreview from '../user_preview.vue'
export default {
    components:{CircularChar,UserPreview},
    props:['user'],
    data() {
        return {
            incarichiCounter:{
                total:0,
                inRicerca:0,
                ricercaSospesa:0,
                ricercaConclusa:0,
                inAsta:0,
                aggiudicata:0,
                persa:0,
                rinunciaRicerca:0,
                rinunciaAsta:0,
            },
            incarichiStats:{
                provvigioniPagate:0,
                provvigioniPagateVal:0,
                provvigioniDaPagare:0,
                provvigioniDaPagareVal:0,
                ricerchePagate:0,
                ricerchePagateVal:0,
                ricercheDaPagare:0,
                ricercheDaPagareVal:0,
            },
            incaricoTortaWidth:'150',
            incaricoTortaFontSize:'14',
        }
    },
    methods: {
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        storeData(){
            this.incarichiCounter = {
                total:0,
                inRicerca:0,
                ricercaSospesa:0,
                ricercaConclusa:0,
                inAsta:0,
                aggiudicata:0,
                persa:0,
                rinunciaRicerca:0,
                rinunciaAsta:0,
            };
            this.incarichiStats = {
                provvigioniPagate:0,
                provvigioniPagateVal:0,
                provvigioniDaPagare:0,
                provvigioniDaPagareVal:0,
                ricerchePagate:0,
                ricerchePagateVal:0,
                ricercheDaPagare:0,
                ricercheDaPagareVal:0,
            };
            this.incarichiCounter.total = this.user.incarichi.length;
            this.user.incarichi.forEach(incarico=>{
                switch(incarico.stato){
                    case 'In Ricerca': this.incarichiCounter.inRicerca++; break;
                    case 'Ricerca Sospesa': this.incarichiCounter.ricercaSospesa++; break;
                    case 'Ricerca Conclusa': this.incarichiCounter.ricercaConclusa++; break;
                    case 'In Asta': this.incarichiCounter.inAsta++; break;
                    case 'Aggiudicata': this.incarichiCounter.aggiudicata++; break;
                    case 'Persa': this.incarichiCounter.persa++; break;
                    case 'Rinuncia Ricerca': this.incarichiCounter.rinunciaRicerca++; break;
                    case 'Rinuncia Asta': this.incarichiCounter.rinunciaAsta++; break;
                }
                if(incarico.stato=='Aggiudicata'){
                    if(incarico.provvigione.pagato){
                        this.incarichiStats.provvigioniPagate++;
                        if(incarico.provvigione.euro_num){
                            this.incarichiStats.provvigioniPagateVal = this.incarichiStats.provvigioniPagateVal + Number.parseFloat(incarico.provvigione.euro_num);
                        }
                    }else{
                        this.incarichiStats.provvigioniDaPagare++;
                        if(incarico.provvigione.euro_num){
                            this.incarichiStats.provvigioniDaPagareVal = this.incarichiStats.provvigioniDaPagareVal + Number.parseFloat(incarico.provvigione.euro_num);
                        }
                    }
                }
                if(incarico.costo_ricerca.pagato){
                    this.incarichiStats.ricerchePagate++;
                    if(incarico.costo_ricerca.prezzo){
                        this.incarichiStats.ricerchePagateVal = this.incarichiStats.ricerchePagateVal + Number.parseFloat(incarico.costo_ricerca.prezzo)
                    }
                }else{
                    this.incarichiStats.ricercheDaPagare++;
                    if(incarico.costo_ricerca.prezzo){
                        this.incarichiStats.ricercheDaPagareVal = this.incarichiStats.ricercheDaPagareVal + Number.parseFloat(incarico.costo_ricerca.prezzo)
                    }
                }
            }) 
            console.log(this.incarichiStats);
        }
    },
    watch:{
        'user':'storeData'
    },
    mounted() {
        if(Object.keys(JSON.parse(JSON.stringify(this.user))).length != 0){
            this.storeData();
        }
        
    },
}
</script>

<style lang="scss" scoped>
#generale{
    padding: 0px 10px 30px 10px;
    max-width: 1400px;
    margin: 0 auto;
    .incarichiStatistiche{
        display: flex;
        flex-flow: row wrap;
        align-items:center;
        justify-content: left;
        @media screen and (max-width: 717px) {
            justify-content: center;
        }
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
    }
    .incarichi{
        display: flex;
        flex-flow: row wrap;
        align-items:center;
        justify-content: left;
        @media screen and (max-width: 1397px) {
            justify-content: center;
        }
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        margin-top: 65px;
        margin-bottom: 0px;
        .secTitle{
            font-weight: 800;
            font-size: 60pt;
            line-height: 0px;
            position: absolute;
            opacity: 0.2;
            top:-25px;
            left:20px;
            letter-spacing: -5px;
            @media screen and (max-width: 405px) {
                font-size: 50pt;
            }
            @media screen and (max-width: 340px) {
                font-size: 40pt;
            }
        }
    }
    .utentiGestiti{
        position: relative;
        margin-top: 90px;
        display: flex;
        flex-flow: row wrap;
        align-items:center;
        justify-content: center;
        max-width: max-content;
        @media screen and (max-width: 380px) {
            margin-top: 130px;
        }
        .secTitle{
            font-weight: 800;
            font-size: 60pt;
            line-height: 0px;
            position: absolute;
            opacity: 0.2;
            top:-25px;
            left:20px;
            display: flex;
            flex-wrap: nowrap;
            width: max-content;
            letter-spacing: -5px;
            @media screen and (max-width: 580px) {
                font-size: 50pt;
            }
            @media screen and (max-width: 480px) {
                font-size: 40pt;
            }
            @media screen and (max-width: 380px) {
                width: 100%;
                font-size: 50pt;
                line-height: 50px;
                flex-wrap: wrap;
                top:-100px;
            }
        }
    }
}


</style>