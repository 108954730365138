<template>
    <div id="nav">
      <div class="openMenu">
        <v-btn class="mx-2" fab dark small color="primaryBG" @click.stop="drawer = !drawer" >
          <v-icon dark> mdi-menu </v-icon>
        </v-btn>  
        
      </div>
      <v-navigation-drawer v-model="drawer" temporary fixed class="primaryBG accent-4 " dark>
        <v-list-item class="notSelectableTxt" >
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              AggiudicaRe
            </v-list-item-title>
            <v-list-item-subtitle>
              Area Riservata
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{me.nome}} {{me.cognome}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div class="immagine" v-if="me.foto" :style="'background-image: url(\''+me.foto+'\')'"></div>
        <div class="immagine" v-if="!me.foto" :style="'background-image: url(\''+require('../../assets/imgs/empty-profile.png')+'\')'"></div>
        
        
        <v-divider></v-divider>

        <v-list dense nav >

          <v-list-item link to="/area-riservata/home">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Home</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link :to="'/agente/'+me._id">
            <v-list-item-icon><v-icon>mdi-alpha-p-circle</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Pagina Pubblica</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">CALENDARIO</div>
          </div>

          <v-list-item link to="/area-riservata/calendario">
            <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Calendario</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/area-riservata/creaEvento">
            <v-list-item-icon><v-icon>mdi-calendar-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Evento</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">INCARICHI</div>
          </div>

          <v-list-item link to="/area-riservata/incarichi">
            <v-list-item-icon><v-icon>mdi-folder</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Incarichi</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/area-riservata/incarichi-crea">
            <v-list-item-icon><v-icon>mdi-folder-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Incarico</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">SISTEMA</div>
          </div>

          <v-list-item link to="/area-riservata/videoFormazione">
            <v-list-item-icon><v-icon>mdi-video-box</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Video Formazione</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/area-riservata/files">
            <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Files</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/area-riservata/aggiornamenti">
            <v-list-item-icon><v-icon>mdi-update</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Aggiornamenti</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/area-riservata/impostazioni">
            <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Impostazioni</v-list-item-title></v-list-item-content>
          </v-list-item>

        </v-list>


        <template v-slot:append>
          <div class="pa-2">
            <router-link to="/"><v-btn block color="primary" x-small style="margin-bottom: 5px"> Torna al sito </v-btn></router-link>
            <v-btn block color="error" @click="logout()">
              Esci
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
  data() {
    return {
      drawer:false,
    }
  },
  computed: mapGetters(['me']),
  methods:{
    ...mapMutations(['LogoutUser']),
    logout(){
      this.LogoutUser()
      this.$router.push('/area-riservata/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.openMenu{
  position: fixed;
  left: 0;top:5px;
  z-index: 6;
}
.sectionDivider{
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  .sectionTitle{
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 9pt;
    font-weight: 400;
    background-color: var(--primaryColor);
    line-height: 12px;
    padding: 2px 7px;
    color: rgba(255, 255, 255, 0.657);
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 5px;
  }
}

.immagine{
  background: white;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid rgb(189, 189, 189);
  background-size: cover;
  background-position: center center;
  position: absolute;
  top:15px;right: 15px;
}
</style>