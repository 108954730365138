import { render, staticRenderFns } from "./Accessi.vue?vue&type=template&id=bf077698&scoped=true&"
import script from "./Accessi.vue?vue&type=script&lang=js&"
export * from "./Accessi.vue?vue&type=script&lang=js&"
import style0 from "./Accessi.vue?vue&type=style&index=0&id=bf077698&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf077698",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VVirtualScroll})
