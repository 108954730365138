<template>
    <div id="videoformazione">
        <div class="mainTitle">VIDEO FORMAZIONE</div>
        <div class="container">
            <VideoFormazione :videoFormazione="video" v-for="video in filtredVideoFormazioni.slice(0,currentSeen)" :key="video._id" />   
        </div>
        <div class="btnMore">
            <v-btn color="primaryBG" dark block @click="loadMore()" v-if="this.filtredVideoFormazioni.length > this.currentSeen" >ALTRI {{this.filtredVideoFormazioni.length - this.currentSeen}}</v-btn>
        </div>

        <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="500px" overflow-y="visible">
            <v-card>
                <v-toolbar dark color="primaryBG" height="40px">
                    <v-btn icon dark @click="dialog = false" > <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>Filtri</v-toolbar-title>
                </v-toolbar>
                <div class="impostazioniDati">
                    <v-select :items="['Data Decrescente','Data Crescente']" v-model="orderSelected" label="Ordine" dense solo @change="sort()"></v-select>
                    <vue-tags-input
                        class="hashtagInput"
                        v-model="tagFilter"
                        :tags="tagsFilter"
                        :autocomplete-items="allTags"
                        @tags-changed="newTags => tagsFilter = newTags"
                        :add-only-from-autocomplete="true"
                        />
                    <v-btn elevation="4" color="error" block dark small @click="resetFiltri()" >RESETTA FILTRI</v-btn>
                </div>

            </v-card>
        </v-dialog>


        <v-btn class="filtringButton" elevation="4" color="primaryBG" dark fab small @click="dialog=true" ><v-icon dark>mdi-filter-variant</v-icon></v-btn>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';
import VideoFormazione from '../../components/admin/videoFormazione/videoFormazioneAdmin.vue'
export default {
    components:{VideoFormazione,VueTagsInput},
    data() {
        return {
            tagFilter:'',
            tagsFilter:[],
            allTags:[],
            currentSeen:10,
            dialog:false,
            orderSelected:'Data Decrescente',
            filtredVideoFormazioni:[],
        }
    },
    computed: mapGetters(['videoFormazione','retrivedVideoFormazione']),
    watch:{
        'tagsFilter':'setup'
    },
    methods: {
        ...mapActions(['fetchAllVideoFormazione']),
        setup(){
            this.filtredVideoFormazioni = this.videoFormazione;
            this.setupTags();
            this.filter();
            this.sort();
        },
        resetFiltri(){
            this.orderSelected='Data Decrescente';
            this.setup();
        },
        filtringByTags(){
            var good=[];
            this.filtredVideoFormazioni.forEach(video=>{
                var x=0;
                while(x<this.tagsFilter.length){
                    if(video.hashtags.includes(this.tagsFilter[x].text)){
                        good.push(video);
                        break;
                    }
                    x++;
                }
            })
            this.filtredVideoFormazioni = good;
        },
        filter(){
            if(this.tagsFilter.length>0){
                this.filtringByTags();
            }
        },
        setupTags(){
            this.filtredVideoFormazioni.forEach(video => {
                video.hashtags.forEach(hash=>{
                    if(this.allTags.findIndex(tag=> tag.text==hash)==-1){
                        this.allTags.push({text:hash})
                    }
                })
            });
        },
        loadMore(){
            if(this.videoFormazione.length - this.currentSeen > 10){
                this.currentSeen = this.currentSeen+10;
            }else{
                this.currentSeen = this.videoFormazione.length;
            }
        },
        sort(){
            this.toogleLoading(true);
            if(this.orderSelected=='Data Decrescente'){
                this.filtredVideoFormazioni = this.filtredVideoFormazioni.sort((a,b)=>{
                    if(a.data > b.data){return -1}
                    else if(b.data > a.data){return 1}
                    else{return 0}
                })
            }else if(this.orderSelected=='Data Crescente'){
                this.filtredVideoFormazioni = this.filtredVideoFormazioni.sort((a,b)=>{
                    if(a.data > b.data){return 1}
                    else if(b.data > a.data){return -1}
                    else{return 0}
                })
            }
            this.toogleLoading(false);
        }
    },
    inject: ['toogleLoading'],
    mounted() {
        if(!this.retrivedVideoFormazione){
            this.fetchAllVideoFormazione().then(()=>{
                this.setup()
            });
        }else{
            this.setup()
        }
    },
}
</script>

<style lang="scss" scoped>
.container{
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: center;
}
.btnMore{
    max-width: 800px;
    margin: 0 auto 80px auto;
    padding: 0px 6%;
}
.filtringButton{
    position: fixed;
    bottom: 10px; right:10px;
}

.impostazioniDati{
    padding: 20px;
}

.hashtagInput{
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;
}
</style>