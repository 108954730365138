<template>
    <div id="home">
        <div class="top elevation-9">
            <div class="immagine"></div>
            <div class="logo">
                <img :src="require('../../assets/logo/logo500.png')" alt="">
            </div>
        </div>
        <div class="searchBox elevation-9">
            <div class="titolo">CERCA IMMOBILE</div>
            <div class="filtri"> 
                <v-select :items="immobiliInfo.categorie" v-model="filterCategoria" label="Categoria" dense solo clearable item-value='nome' :disabled="loading">
                    <template slot="selection" slot-scope="data">
                        ({{data.item.quantita}}) {{ data.item.nome }}
                    </template>
                    <template slot="item" slot-scope="data">
                        ({{data.item.quantita}}) {{ data.item.nome }}
                    </template>
                </v-select>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select :items="immobiliInfo.comune" v-model="ricercaComune" label="Comune" dense solo clearable item-value='nome' :disabled="loading">
                            <template slot="selection" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                            <template slot="item" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" class="noMtOnLow">
                        <v-select :items="immobiliInfo.regioni" v-model="filterRegione" label="Regione" dense solo clearable item-value='nome' :disabled="loading">
                            <template slot="selection" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                            <template slot="item" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row class="rowMt">
                    <v-col cols="12" sm="6">
                        <div class="rangeSoldi">
                            <v-range-slider v-model="range" :max="rangeMax" :min="rangeMin" :step="rangeStep" hide-details class="align-center" :disabled="loading" ></v-range-slider>
                            <div class="rangeLeft">{{moneyFormat(range[0])}}€</div>
                            <div class="rangeCenter">PREZZO</div>
                            <div class="rangeRight">{{moneyFormat(range[1])}}€</div>
                        </div>  
                    </v-col>
                    <v-col cols="12" sm="6" class="noMtOnLow">
                        <div class="rangeSoldi">
                            <v-range-slider v-model="mq" :max="maxMq" :min="minMq" :step="stepMq" hide-details class="align-center" :disabled="loading"></v-range-slider>
                            <div class="rangeLeft">{{moneyFormat(mq[0])}} <small>M2</small> </div>
                            <div class="rangeCenter">METRIQUADRI</div>
                            <div class="rangeRight">{{moneyFormat(mq[1])}} <small>M2</small> </div>
                        </div>
                    </v-col>
                </v-row>
                <v-btn elevation="4" :class="loading?'searchBtn disabledBtn':'searchBtn'" dark small @click="search()" > <span v-if="!loading">CERCA</span> <span v-else>LOADING</span> </v-btn>
            </div>
        </div>

        <div class="offerteImperdibili" v-if="filterOfferte(immobili).length>0">
            <div class="mainTitle">OFFERTE IMPERDIBILI</div>
            <div class="immobili">
                <ImmobilePreview :immobile="immobile" v-for="immobile in filterOfferte(immobili).slice(0,4)" :key="immobile._id"/>
            </div>
            <v-btn v-if="filterOfferte(immobili).length-4 > 0" elevation="4" class="seeMoreBtn" dark small to="/immobili?filterOfferte=Si">ALTRE ({{filterOfferte(immobili).length-4}})</v-btn>
        </div>


        <div class="boxOfText">
            <div class="boxTitle">In cosa crediamo?</div>
            <div class="boxText">
                <p class="elevation-9">
                    <span class="icona">📖</span>
                    <span><b>Crediamo</b> che <b>ogni casa</b> abbia una <b>storia</b> da raccontare.</span>
                    
                </p>
                <p class="elevation-9">
                    <span class="icona">📈</span>
                    <span><b>Crediamo</b> nella volontà delle <b>agenzie</b> come la nostra che vogliono <b>differenziarsi, migliorarsi.</b> </span>
                     
                </p>
                <p class="elevation-9">
                    <span class="icona">💽</span>
                    <span><b>Crediamo</b> nell'<b>eccellenza</b> e, attraverso i nostri servizi, cerchiamo di renderla concreta.</span>
                    
                </p>
                <p class="elevation-9">
                    <span class="icona">🧰</span>
                    <span><b>Crediamo</b> nell'<b>innovazione</b> e nel <b>cambiamento</b>.</span>
                    
                </p>
                <p class="elevation-9">
                    <span class="icona">🌊</span>
                    <span><b>Crediamo</b> a chi riesce ad emozionarsi davanti al mare e a chi riesce ad emozionarsi allo stesso modo guardando un <b>lavoro ben fatto</b>.</span>
                    
                </p>
                <p class="elevation-9">
                    <span class="icona">😊</span>
                    <span><b>Crediamo</b> nei <b>sorrisi spontanei</b>.</span>
                    
                </p>
                <p class="bigText elevation-9">
                    <span class="agg">
                        <span class="aggTop"><b>Crediamo</b> in</span>
                        <b class="aggName">AggiudicaRe</b>
                    </span> 
                    <span> un'agenzia che nasce dall’<b>insieme di più passioni</b> che ci spingono, ogni giorno, a fare <b>sempre meglio</b>   : quella per i nostri clienti, per i loro sogni, e per il settore delle <b>Esecuzioni Immobiliari</b> .</span>
                    
                </p>
                
            </div>
        </div>





        <div class="contattaci">
            <ContactForm :emailTo="'segreteria@aggiudicare.casa'"/>    
        </div>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RegioniJson from '../../utils/regioni.json'
import ContactForm from '../../components/public/contactForm.vue'
import ImmobilePreview from '../../components/public/immobilePreview.vue'
export default {
    components:{ContactForm,ImmobilePreview},
    metaInfo:{ 
        title: "AggiudicaRe",
        meta: [
            { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
            { property: 'og:title', content: "AggiudicaRe"},
            { property: 'og:site_name', content: 'AggiudicaRe'},
        ]
    },
    data() {
        return {
            ricercaComune:'',
            rangeMin: 0,
            rangeMax: 1000000,
            rangeStep:5000,
            range: [],
            minMq: 0,
            maxMq: 2000,
            stepMq: 10,
            mq:[],
            loading:true,
            regioniOptions:[],
            filterRegione:undefined,
            filterCategoria:undefined,
        }
    },
    computed: mapGetters(['immobili','retrivedImmobili','immobiliInfo']),
    methods: {
        ...mapActions(['fetchAllImmobili']),
        loadRegioni(){
            RegioniJson.forEach(regione => {
                this.regioniOptions.push({
                    value: regione.nome,
                    text: regione.nome,
                })
            });
        },
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        search(){
            var query = '?';
            if(this.range[0]!=this.rangeMin || this.range[1]!=this.rangeMax){
                if(query!='?'){
                    query=query+'&range0='+this.range[0]+'&range1='+this.range[1];    
                }else{
                    query=query+'range0='+this.range[0]+'&range1='+this.range[1];    
                }
            }
            if(this.mq[0]!=this.minMq || this.mq[1]!=this.maxMq){
                if(query!='?'){
                    query=query+'&mq0='+this.mq[0]+'&mq1='+this.mq[1];    
                }else{
                    query=query+'mq0='+this.mq[0]+'&mq1='+this.mq[1];    
                }
            }
            if(this.ricercaComune){
                if(query!='?'){
                    query=query+'&ricercaComune='+this.ricercaComune;    
                }else{
                    query=query+'ricercaComune='+this.ricercaComune;  
                }  
            }
            if(this.filterRegione){
                if(query!='?'){
                    query=query+'&filterRegione='+this.filterRegione;    
                }else{
                    query=query+'filterRegione='+this.filterRegione;  
                }
                
            }
            if(this.filterCategoria){
                if(query!='?'){
                    query=query+'&filterCategoria='+this.filterCategoria;    
                }else{
                    query=query+'filterCategoria='+this.filterCategoria;  
                }
            }
            this.$router.push('/immobili'+query);
            console.log(query)
        },
        filterOfferte(immobili){
            var toRet = [];
            immobili.forEach(immobile=>{
                if(immobile.offertaImperdibile){
                    toRet.push(immobile);
                }
            })
            return toRet;
        }
    },
    mounted() {
        this.range = [this.rangeMin,this.rangeMax];
        this.mq = [this.minMq,this.maxMq];
        this.loadRegioni();
        if(!this.retrivedImmobili){
            this.fetchAllImmobili().then(()=>{
                this.loading=false;
            })
        }else{
            this.loading=false;
        }
    },
}
</script>

<style lang="scss" scoped>
.top{
    margin-top: -5px;
    height: 70vh;
    background-image: url('https://img.miogest.com/19261/imm_4215-1280-.jpg');
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 2;
    //PORTRAITS
    @media screen and (max-height: 450px) {height: calc(100vh - var(--navbarHeight) + 2px);margin-top: -2px;}
    .logo{
        background: rgba(255, 255, 255, 0.897);
        backdrop-filter: blur(10px);
        width:95%;
        img{
            max-width: 100%;
        }
        padding: 20px;
        max-width: max-content;
        border-radius: 15px;
        position: absolute;
        top: 40%;left:50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
.searchBox{
    background-color: var(--primaryColor);
    position: relative;
    margin-top: -100px;
    //PORTRAITS
    @media screen and (max-height: 450px) {margin-top: 20px;}
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    max-width: 800px;
    border-radius: 15px;
    z-index: 40;
    .titolo{
        color: white;
        font-size: 20pt;
        line-height: 24px;
        padding-top: 10px;
        margin-bottom: 10px;
        font-weight: 600;
        text-align: center;
    }
    .filtri{
        padding: 0px 20px 20px 20px;
        .searchBtn{
            background: white;
            color: var(--primaryColor);
            font-weight: 800;
            font-size: 11pt;
            left: 50%;
            transform: translateX(-50%);
            padding: 0px 30px;
        }
        .disabledBtn{
            background: rgb(221, 221, 221);
            color: gray;
        }
        .rangeSoldi{
            position: relative;
            background: white;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
            padding:25px 5px 5px 5px;
            border-radius: 5px;
            margin-bottom: 25px;
            .rangeLeft{
                position: absolute;
                top:5px;left:10px;
                font-size: 9pt;
                font-weight: 800;
                color: rgb(129, 129, 129);
            }
            .rangeCenter{
                position: absolute;
                top:5px;left:50%;
                transform: translateX(-50%);
                font-size: 10pt;
                font-weight: 600;
                color: rgb(65, 65, 65);
                text-align: center;
                line-height: 10px;
            }
            .rangeRight{
                position: absolute;
                top:5px;right:10px;
                font-size: 9pt;
                font-weight: 800;
                color: rgb(129, 129, 129);
            }
        }
    }
}
.contattaci{
    margin-top: 40px;
    margin-bottom: 100px;
}

.rowMt{
    margin-top: -22px;
    @media screen and (max-width: 600px) {margin-top: -28px;}
}
.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}
.boxOfText{
    background: var(--primaryColor);
    color: white;
    margin-top: 20px;
    padding: 30px 20px 30px 20px;
    .boxTitle{
        font-size: 40pt;
        line-height: 45px;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
    }
    .boxText{
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        position: relative;
        p{
            padding: 20px;
            border-radius: 20px;
            background-color: rgba(219, 219, 219, 0.986);
            color: black;
            max-width: 500px;
            width: 100%;
            margin: 10px auto;
            font-size: 14pt;
            line-height: 20px;
            display: flex;
            flex-flow: column;
            row-gap: 10px;
            justify-content: center;
            transition-duration: 0.2s;
            .icona{
                font-size: 20pt;
            }
            @media (hover: hover) {
                &:hover{
                    transform: translateY(-5%);
                    transition-duration: 0.2s;
                }
            }
        }
        .bigText{
            font-size: 16pt;
            line-height: 23px;
            max-width: 700px;
            
            .agg{
                position: relative;
                width: max-content;
                margin: 0 auto;
                margin-top: 20px;
                .aggTop{
                    position: absolute;
                    top:-23px;right:0;
                    font-size: 11pt;
                    text-transform: uppercase;
                }
                .aggName{
                    font-size: 26pt;
                }
            }
        }
    }
}
.offerteImperdibili{
    margin-top: 20px;
    .immobili{
        width: max-content;
        max-width: 100%;
        margin: 20px auto 15px auto;
        padding-left: 6%;
        padding-right: 6%;
        column-gap: 40px;
        row-gap: 40px;
        display: flex;
        flex-flow:row wrap;
        align-items: center;
        justify-content: center;
    }
}
.seeMoreBtn{
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px;
    background: white!important;
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 10pt;
    transition-duration: 0.2s;
    &:hover{
        background: var(--primaryColor)!important;
        color: white;
        transition-duration: 0.2s;
    }
}
</style>