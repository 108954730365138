<template>
    <div id="cookie" class="">
        <div class="cookie elevation-9" v-if="showCookie">
            <div class="title">Cookie Policy</div>
            <div class="text">
                We use cookies to enable the proper functioning and security of our websites, and help us offer you the best possible user experience. By clicking Accept, you consent to the use of these cookies for advertising and analytics. For more information, please read our <router-link to="/privacy-policy">Privacy Policy</router-link>.
            </div>
            <div class="btn elevation-9" @click="accept()">Accept</div>
            <div class="closeBtn" @click="close()"><v-icon>mdi-close</v-icon></div>
        </div>
        
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            showCookie:false,
        }
    },
    methods: {
        close(){
            this.showCookie = false;
        },
        accept(){
            this.showCookie = false;
            this.$cookies.set('aggiudicareCookies',true);
        }
    },
    mounted() {
        if(!this.$cookies.get('aggiudicareCookies')){
            this.showCookie = true;    
        }
        
    },
}
</script>
<style lang="scss" scoped>
#cookie{
    position: fixed;
    bottom: 0px;
    left:0px;
    z-index: 9999;
    padding: 20px;
    width: max-content;
    max-width: 100%;
    @media screen and (max-width: 450px) {
        padding: 10px;
        left:50%;
        transform: translateX(-50%);
    }
    font-family: "Roboto", sans-serif;
    .cookie{
        border-radius: 15px;
        background:white;
        padding: 20px;
        max-width: 350px;
        position: relative;
        .title{
            font-size: 18pt;
            font-weight: 700;
            margin-bottom: 5px;
            color: var(--primaryColorHover);
        }
        .text{
            font-size: 8pt;
            color: rgb(47, 47, 47);
            a{
                color: var(--primaryColor);
                text-decoration: none;
            }
        }
        .closeBtn{
            position: absolute;
            top:10px;
            right:10px;
            cursor: pointer;
            .v-icon{color: rgb(49, 49, 49);}
            background:rgb(235, 235, 235);
            border-radius: 50%;
            padding: 2px;
            transition-duration: 0.2s;
            &:hover{
                background:rgb(211, 211, 211);
                .v-icon{color: black;}
                transition-duration: 0.2s;
            }
        }
    }
    .btn{
        background: var(--primaryColor);
        width: max-content;
        padding: 5px 20px;
        border-radius: 5px;
        color: white;
        font-weight: 400;
        cursor: pointer;
        margin-top: 10px;
        margin: 10px auto 0px auto;
        text-transform: uppercase;
        font-size: 10pt;
        transition-duration: 0.2s;
        &:hover{
            background: var(--primaryColorHover);
            transition-duration: 0.2s;
        }
    }
}
</style>