<template>
    <div id="videoFormazioneCrea">
        <div class="mainTitle" style="font-size:20pt">CREA VIDEO FORMAZIONE</div>
        <div class="formContainer elevation-9">
            <v-form @submit.prevent="creaVideoFormazione" v-model="isFormValid">
                <v-text-field v-model="form.titolo" :rules="requiredRules" label="Titolo" clearable outlined dense></v-text-field>
                <v-textarea v-model="form.descrizione" name="input-7-1" label="Descrizione" clearable outlined dense></v-textarea>
                <v-text-field v-model="form.urlVideo" :rules="requiredRules" label="Url Video" clearable outlined dense></v-text-field>
                <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" class="hashtagInput">
                    <div slot="autocomplete-item" slot-scope="props" class="my-item" @click="props.performAdd(props.item)">
                        {{ props.item.text }}
                    </div>
                </vue-tags-input>

                <v-btn block color="mainBtn" type="submit" :disabled="!isFormValid"> CREA </v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import { mapActions } from 'vuex';
export default {
    components: {
        VueTagsInput,
    },
    inject: ['toogleLoading'],
    data() {
        return {
            isFormValid:false,
            form:{
                titolo:'',
                descrizione:'',
                urlVideo:'',
                hashtags:[],
            },
            tag:'',
            tags: [],
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
        }
    },methods: {
        ...mapActions(['createVideoFormazione']),
        creaVideoFormazione(){
            this.tags.forEach(tag => {
                this.form.hashtags.push(tag.text)
            });
            this.toogleLoading(true);
            this.createVideoFormazione(this.form).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Creazione Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$router.push('/admin/videoFormazione');
                this.toogleLoading(false);
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Creazione Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.formContainer{
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    padding: 30px;
    border-radius: 15px;
}
.hashtagInput{
    width: 100%;
    max-width: unset;
    margin-bottom: 27px;
}
</style>