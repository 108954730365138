<template>
    <div id="contattaci">
        <div class="top">
            <img :src="require('../../assets/imgs/contattaci.svg')" alt="">
            <div class="titolo">CONTATTACI</div>  
        </div>
        <div class="flexxed">
            <div class="left">
                <div class="textSubtitle">Hai bisogno di informazioni, per chiarimenti sulle nostre offerte o per una consulenza?</div>
                <div class="textSubSubtitle">Scrivici o Cerca l’agenzia più vicina a te un consulente AggiudicaRe ti darà tutte le risposte di cui hai bisogno</div>
                <div class="contatti">
                    <a href="tel:01119466412" class="buzz-out-on-hover">
                        <div class="folder elevation-9">
                            <v-icon>mdi-phone</v-icon>
                            <div class="testo">01119466412</div>
                        </div>
                    </a>
                    <a href="mailto:segreteria@aggiudicare.casa" class="buzz-out-on-hover">
                        <div class="folder elevation-9">
                            <v-icon>mdi-email</v-icon>
                            <div class="testo email">segreteria@aggiudicare.casa</div>
                        </div>
                    </a>
                </div>
                
            </div>
            <div class="right"><ContactForm :emailTo="'segreteria@aggiudicare.casa'"/></div>
        </div>
        <Mappa />
        
    </div>
</template>

<script>
import ContactForm from '../../components/public/contactForm.vue'
import Mappa from '../../components/public/mappa.vue'
export default {
    components:{ContactForm,Mappa},
    metaInfo() {
        return { 
            title: "AggiudicaRe - Contattaci",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Contattaci"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.top{
    position: relative;
    margin: 10px;
    img{
        width: 100%;
        max-height: 500px;
    }
    .titolo{
        font-size: 7vw;
        line-height: 0px;
        position: absolute;
        bottom:45px;
        left: 50%;
        transform: translateX(-95%);
        font-weight: 800;
        color: var(--primaryColor);
        @media screen and (max-width: 1500px) {font-size: 8vw;}
        @media screen and (max-width: 1200px) {font-size: 8.5vw;}
        @media screen and (max-width: 900px) {font-size: 80pt;transform: translateX(-50%);bottom:-40px;}
        @media screen and (max-width: 700px) {font-size: 70pt;}
        @media screen and (max-width: 600px) {font-size: 15vw;}
    }
}
.flexxed{
    display: flex;
    column-gap: 20px;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 100px;
    @media screen and (max-width: 900px) {margin-top: 80px; flex-flow: column;}
    @media screen and (max-width: 500px) {margin-top: 70px;}
    @media screen and (max-width: 400px) {margin-top: 65px;}
    @media screen and (max-width: 300px) {margin-top: 60px;}
    .left{
        width: 50%;
        @media screen and (max-width: 900px) {width: 100%;padding:0px 10px;}
        @media screen and (max-width: 300px) {padding:0px 5px;}
        .textSubtitle{
            font-size: 20pt;
            font-weight: 500;
            line-height: 25px;
            text-align: center;
            max-width: 810px;
            margin: 0 auto;
            @media screen and (max-width: 450px) {font-size: 16pt;line-height: 18px;}
            @media screen and (max-width: 300px) {font-size: 14pt;line-height: 16px;}
        }
        .textSubSubtitle{
            font-size: 12pt;
            line-height: 17px;
            text-align: center;
            @media screen and (max-width: 300px) {font-size: 10pt; line-height: 14px;}
        }
        .contatti{
            display: flex;
            flex-wrap: wrap;
            column-gap: 40px;
            row-gap: 40px;
            justify-content: center;
            align-items: center ;
            margin-top: 40px;
            .folder{
                background: var(--primaryColor);
                text-align: center;
                padding: 20px;
                border-radius: 15px;
                .testo{
                    color: white;
                    font-weight: 500;
                    font-size: 20pt;
                    @media screen and (max-width: 676px) {font-size:15pt}
                    @media screen and (max-width: 509px) {font-size:12pt}
                }
                .v-icon{
                    font-size: 60pt;
                    color: white;
                    @media screen and (max-width: 676px) {font-size:50pt}
                    @media screen and (max-width: 509px) {font-size:40pt}
                }
                .email{
                    @media screen and (max-width: 260px) {font-size:10pt}
                }
            }
        }
    }
    .right{
        width: 30%;
        margin-left: 90px;
        @media screen and (max-width: 900px) {width: 90%; margin: 40px auto 0px auto;}
    }
}

</style>