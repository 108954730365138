<template>
    <div id="agenziaCrea">
        <div class="mainTitle">CREA AGENZIA</div>
        <v-form class="form elevation-9" @submit.prevent="submit" v-model="isFormValid">
           <v-text-field  label="NOME *" :rules="requiredRules" v-model="form.nome" required clearable outlined dense></v-text-field>
           <v-text-field  label="INDIRIZZO *" :rules="requiredRules" v-model="form.indirizzo" required clearable outlined dense></v-text-field>
           <model-select  class="mb-20" :rules="requiredRules" :options="provincieOptions" v-model="form.provincia" placeholder="PROVINCIA *"> </model-select>
           <v-row style="margin-top: -12px">
               <v-col><v-text-field :rules="requiredRules"  label="CITTA *" v-model="form.citta" required clearable outlined dense></v-text-field></v-col>
               <v-col><v-text-field :rules="requiredRules"  label="CAP *" v-model="form.cap" required clearable outlined dense></v-text-field></v-col>
           </v-row>
           <v-row style="margin-top: -22px">
               <v-col>
                   <v-text-field :rules="requiredRules" v-model="form.lat" hint="ex.55.55"  label="LATITUDINE *" required clearable outlined dense></v-text-field>
                </v-col>
               <v-col>
                   <v-text-field :rules="requiredRules" v-model="form.lng" hint="ex.55.55"  label="LONGITUDINE *" required clearable outlined dense></v-text-field>
                </v-col>
           </v-row>
           <v-btn block color="mainBtn" type="submit" class="btnSubmit" :disabled="!isFormValid || form.provincia==undefined"> CREA </v-btn>
        </v-form>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
import { mapActions } from 'vuex'
import ProvincieJson from '../../../utils/provincie.json'
export default {
    components:{ModelSelect},
    data() {
        return {
            isFormValid:false,
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            form:{
                nome:'',
                indirizzo:'',
                citta:'',
                provincia:undefined,
                cap:'',
                lat:'',
                lng:'',
            },
            provincieOptions:[],
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['createAgenzia']),
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        formatterPos(value){
            var toRet = value.replace(/,/g,".");
            toRet = toRet.replace(/:/g,".");
            toRet = toRet.replace(/-/g,".");
            return toRet;
        },
        submit(){
            this.toogleLoading(true)
            this.createAgenzia(this.form).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Nuova Agenzia',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$router.push('/admin/agenzie');
            }).catch((error)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Nuova Agenzia',
                    text: error.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            });
        }
    },
    mounted() {
        this.loadProvincie();
    },
}
</script>

<style lang="scss" scoped>
.form{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    min-height: 300px;
    border-radius: 15px;
    padding: 20px;
}

.mb-20{
    margin-bottom: 25px;
}
</style>