<template>
    <div id="home">
        <div class="mainTitle">HOME</div>
        <div class="incarichi" v-if="incarichiCounter.total>0">
            <router-link to="/area-riservata/incarichi?filtringStato=In Ricerca" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'inRicerca'}" :percent="Math.floor((incarichiCounter.inRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.inRicerca" :name="'In Ricerca'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Ricerca Sospesa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'ricercaSospesa'}" :percent="Math.floor((incarichiCounter.ricercaSospesa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaSospesa" :name="'Ricerca Sospesa'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Ricerca Conclusa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'ricercaConclusa'}" :percent="Math.floor((incarichiCounter.ricercaConclusa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaConclusa" :name="'Ricerca Conclusa'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=In Asta" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'primaryColor'}" :percent="Math.floor((incarichiCounter.inAsta*100)/incarichiCounter.total)" :count="incarichiCounter.inAsta" :name="'In Asta'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Aggiudicata" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'aggiudicata'}" :percent="Math.floor((incarichiCounter.aggiudicata*100)/incarichiCounter.total)" :count="incarichiCounter.aggiudicata" :name="'Aggiudicata'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Persa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'persa'}" :percent="Math.floor((incarichiCounter.persa*100)/incarichiCounter.total)" :count="incarichiCounter.persa" :name="'Persa'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Rinuncia Ricerca" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'rinunciaRicerca'}" :percent="Math.floor((incarichiCounter.rinunciaRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaRicerca" :name="'Rinuncia Ricerca'"/>
            </router-link>
            <router-link to="/area-riservata/incarichi?filtringStato=Rinuncia Asta" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'rinunciaAsta'}" :percent="Math.floor((incarichiCounter.rinunciaAsta*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaAsta" :name="'Rinuncia Asta'"/>
            </router-link>
        </div>
        <div class="incarichiStatistiche" v-if="incarichiCounter.total>0">
            <router-link to="/area-riservata/incarichi?filtringRicerche=Non Pagate" class="buzz-out-on-hover">
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaSospesa',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }" :percent="Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare))" :count="moneyFormat(incarichiStats.ricercheDaPagareVal)+'€'" :name="'Ricerche Da Pagare'" :subb="incarichiStats.ricercheDaPagare"/>
            </router-link>

            <router-link to="/area-riservata/incarichi?filtringRicerche=Pagate" class="buzz-out-on-hover">
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'aggiudicata',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="100-Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare))"  :count="moneyFormat(incarichiStats.ricerchePagateVal)+'€'" :name="'Ricerche Pagate'" :subb="incarichiStats.ricerchePagate"/>
            </router-link>

            <router-link to="/area-riservata/incarichi?filtringProvvigioni=Non Pagate" class="buzz-out-on-hover">
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'ricercaSospesa',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate))" :count="moneyFormat(incarichiStats.provvigioniDaPagareVal)+'€'" :name="'Provvigioni Da Pagare'" :subb="incarichiStats.provvigioniDaPagare"/>
            </router-link>

            <router-link to="/area-riservata/incarichi?filtringProvvigioni=Pagate" class="buzz-out-on-hover">
                <CircularChar :options="{
                    width:incaricoTortaWidth,
                    font_size:incaricoTortaFontSize-2,
                    color:'aggiudicata',
                    widthUnder380:'100',
                    font_sizeUnder380:8,
                    }"
                    :percent="100-Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate))"  :count="moneyFormat(incarichiStats.provvigioniPagateVal)+'€'" :name="'Provvigioni Pagate'" :subb="incarichiStats.provvigioniPagate"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import CircularChar from '../../components/admin/circularChar.vue'
export default {
    components:{CircularChar},
    computed: mapGetters(['me','incarichiCounter','retrivedIncarichi','incarichiStats']),
    data() {
        return {
            incaricoTortaWidth:'150',
            incaricoTortaFontSize:'14',
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['fetchAllIncarichiFromUser']),
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
    },
    mounted() {
        if(!this.retrivedIncarichi){
            this.toogleLoading(true);
            this.fetchAllIncarichiFromUser().then(()=>{this.toogleLoading(false);}).catch(()=>{this.toogleLoading(false);});
        }
    },
}
</script>

<style lang="scss" scoped>
#home{
    text-align: center;
}
.incarichi{
    display: flex;
    flex-flow: row wrap;
    align-items:center;
    justify-content: center;
    max-width: max-content;
    margin: 0 auto;
}

.dati{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: max-content;
    margin: 10px auto;
    column-gap: 20px;
    row-gap: 20px;

    .cartella{
        border-radius: 15px;
        width: max-content;
        overflow: hidden;
        .cartellaTitle{
            background: rgb(230, 230, 230);
            padding: 5px 10px;
            font-size: 11pt;
            font-weight: 800;
            color: rgb(71, 71, 71);
        }
        .cartellaData{
            padding: 10px;
            font-weight: 500;
            color: rgb(59, 59, 59);
            .field{
            text-align: left; 
            }
        }
    }
}
.incarichiStatistiche{
    display: flex;
    flex-flow: row wrap;
    align-items:center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
}
</style>