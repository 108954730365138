<template>
    <div id="nav">
      <div class="openMenu">
        <v-btn class="mx-2" fab dark small color="primaryBG" @click.stop="drawer = !drawer" >
          <v-icon dark> mdi-menu </v-icon>
        </v-btn>  
        
      </div>
      <v-navigation-drawer v-model="drawer" temporary fixed class="primaryBG accent-4 menu" dark>
        <v-list-item class="notSelectableTxt">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              AggiudicaRe
            </v-list-item-title>
            <v-list-item-subtitle>
              ADMIN
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        
        

        <v-divider></v-divider>
 
        <v-list dense nav >

          <v-list-item link to="/admin/home">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Home</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/offerte-imperdibili">
            <v-list-item-icon><v-icon>mdi-home-alert</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Offerte Imperdibili</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">CALENDARIO</div>
          </div>

          <v-list-item link to="/admin/calendario">
            <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Calendario</v-list-item-title></v-list-item-content>
          </v-list-item>
           <v-list-item link to="/admin/creaEvento">
            <v-list-item-icon><v-icon>mdi-calendar-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Evento</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">AGENTI</div>
          </div>
          

          <v-list-item link to="/admin/agenti">
            <v-list-item-icon><v-icon>mdi-account-supervisor-circle</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Lista Agenti</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/agenti-crea">
            <v-list-item-icon><v-icon>mdi-account-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Agente</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">INCARICHI</div>
          </div>
          
          <v-list-item link to="/admin/incarichi">
            <v-list-item-icon><v-icon>mdi-folder</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Lista Incarichi</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/incarichi-crea">
            <v-list-item-icon><v-icon>mdi-folder-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Incarico</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/storicoIncarichi">
            <v-list-item-icon><v-icon>mdi-folder-multiple</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Storico Incarichi</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">VIDEO FORMAZIONE</div>
          </div>

          <v-list-item link to="/admin/videoFormazione-crea">
            <v-list-item-icon><v-icon>mdi-file-video</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Video Formazione</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/videoFormazione">
            <v-list-item-icon><v-icon>mdi-video-box</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Video Formazione</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">AGENZIE</div>
          </div>

          <v-list-item link to="/admin/agenzie">
            <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Lista Agenzie</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/agenzie-crea">
            <v-list-item-icon><v-icon>mdi-domain-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Crea Agenzia</v-list-item-title></v-list-item-content>
          </v-list-item>

          <div class="sectionDivider notSelectableTxt">
            <v-divider></v-divider>
            <div class="sectionTitle">SISTEMA</div>
          </div>

          <v-list-item link to="/admin/files">
            <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Files</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/aggiornamenti">
            <v-list-item-icon><v-icon>mdi-update</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Aggiornamenti</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/accessi">
            <v-list-item-icon><v-icon>mdi-page-next</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Accessi</v-list-item-title></v-list-item-content>
          </v-list-item>

        </v-list>

        <template v-slot:append>
          
          <div class="pa-2">
            <router-link to="/"><v-btn block color="primary" x-small style="margin-bottom: 5px"> Torna al sito </v-btn></router-link>
            <v-btn block color="error" @click="logout()"> Esci </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
</template>

<script>
export default {
  data() {
    return {
      drawer:false,
    }
  },
  methods:{
    logout(){
      this.$cookies.remove("adminToken");
      this.$router.push('/admin/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.openMenu{
  position: fixed;
  left: 0;top:5px;
  z-index: 6;
}
.sectionDivider{
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  .sectionTitle{
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 9pt;
    font-weight: 400;
    background-color: var(--primaryColor);
    line-height: 12px;
    padding: 2px 7px;
    color: rgba(255, 255, 255, 0.657);
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    white-space: nowrap;
  }
}

</style>