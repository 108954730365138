<template>
    <div id="usertabs">
        <template v-if="user">
            <v-card>
                <v-tabs v-model="tab" centered icons-and-text >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#generale"> Generale <v-icon>mdi-focus-field</v-icon> </v-tab>
                    
                    <v-tab href="#incarichi"> Incarichi <v-icon>mdi-folder</v-icon> </v-tab>
                    <v-tab href="#documenti"> Documenti <v-icon>mdi-file-document</v-icon> </v-tab>
                    <v-tab href="#impostazioni"> Impostazioni <v-icon>mdi-cog</v-icon> </v-tab>
                    <v-tab :to="'/agente/'+user._id"> Pagina Pubblica <v-icon>mdi-alpha-p-circle</v-icon> </v-tab>
                    
                </v-tabs>
                <v-divider></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="generale">
                        <UserGenerale :user="user" />
                    </v-tab-item>
                    <v-tab-item value="incarichi" class="pb-40" >
                        <UserIncarichi :user="user" />
                    </v-tab-item>
                    <v-tab-item value="documenti" >
                        <UserDocuments :user="user" :key="userDocKey" />
                    </v-tab-item>
                    <v-tab-item value="impostazioni" >
                        <UserSettings :user="user"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>
    </div>
</template>

<script>
import UserSettings from './user_settings.vue'
import UserDocuments from './user_documents.vue'
import UserIncarichi from './user_incarichi.vue'
import UserGenerale from './user_generale.vue'
export default {
    components:{UserSettings,UserDocuments,UserIncarichi,UserGenerale},
    props:['user'],
    data() {
        return {
            tab:null,
            userDocKey:0,
        }
    },
    watch:{
        tab(newV,oldV){
            if(oldV != null && newV!='generale'){
                if(this.tab != this.$route.query.tab){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { tab: this.tab }) });    
                }
            }
            if(oldV != null && newV=='generale'){
                this.$router.push(this.$route.path).catch(()=>{
                    
                })
            }
        },
        $route (){
            if(!this.$route.query.tab){
                this.tab='generale'
            }else{
                if(this.tab != this.$route.query.tab){
                    this.tab=this.$route.query.tab
                }
            }
        }
    },
    methods: {
        forceRerender() {
            this.userDocKey += 1;  
        },
    },
    mounted() {
        if(this.$route.query.tab){
            if(this.tab != this.$route.query.tab){
                this.tab = this.$route.query.tab;    
            } 
        }else{
            this.tab='generale'
        }
    },
}
</script>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined){
    box-shadow: none;
}
.pb-40{
    padding-bottom: 40px;
}


</style>