import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    agenzie:[],
    retrivedAgenzie:false,
}

const getters = {
    agenzie: state => state.agenzie,
    retrivedAgenzie: state => state.retrivedAgenzie,
}

const actions = {
    async fetchAllAgenzie({commit}){
        var response = await axios.get(Server+'/agenzie/fetchAll');
        commit("fetchAllAgenzieFun",response.data)
    },
    async createAgenzia({commit},form){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/agenzie/add',form,config)
            .then(function (response) {
                commit("createAgenziaFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async editAgenzia({commit},form){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/agenzie/edit',form,config)
            .then(function (response) {
                commit("editAgenziaFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async deleteAgenzia({commit},id){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/agenzie/delete',id,config)
            .then(function (response) {
                commit("deleteAgenziaFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    }
}

const mutations = {
    fetchAllAgenzieFun(state,response){
        state.agenzie = response.agenzie;
        state.retrivedAgenzie = true;
    },
    createAgenziaFun(state,response){
        state.agenzie.push(response.agenzia)
    },
    editAgenziaFun(state,response){
        const index = state.agenzie.findIndex(agenzia => agenzia._id == response.agenzia._id);
        if(index!=-1){
            state.agenzie[index]=response.agenzia;
        }
    },
    deleteAgenziaFun(state,response){
        const index = state.agenzie.findIndex(agenzia => agenzia._id == response.agenzia._id);
        if(index!=-1){
            state.agenzie.splice(index,1);
        }
    },
}



export default{
    state,
    getters,
    actions,
    mutations
}