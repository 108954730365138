<template>
    <div id="rinunciaAsta">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="rinunciaAsta" v-bind="attrs" v-on="on" > RINUNCIA ASTA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 rinunciaAsta lighten-2"> ASTA RINUNCIATA? </v-card-title>
                <v-card-text>
                    <b>Rinunciare all'asta</b>, porterà l'incarico nello stato di 'Rinuncia Asta'
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn depressed color="rinunciaAsta" @click="dialog = false;RinunciaAsta()" > RINUNCIA </v-btn>
                        <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
      data() {
        return {
            dialog:false,
            modal1:false,
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['rinunciaAstaIncaricoAction']),
        RinunciaAsta(){
            this.toogleLoading(true)
            this.rinunciaAstaIncaricoAction(this.incarico._id).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Rinuncia Asta:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Rinuncia Asta:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 20px;
}
</style>