import Vue from 'vue'
import VueRouter from 'vue-router'
import AreaRiservata from '../views/area-riservata/area-riservata.vue'
import AreaRiservataLogin from '../views/area-riservata/login.vue'
import AreaRiservataHome from '../views/area-riservata/home.vue'
import AreaRiservata404 from '../views/area-riservata/AreaRiservata404.vue'
import AreaRiservataIncarichiCrea from '../views/area-riservata/incarichi/IncarichiCrea.vue'
import AreaRiservataImpostazioni from '../views/area-riservata/Impostazioni.vue'
import AreaRiservataIncarichi from '../views/area-riservata/incarichi/Incarichi.vue'
import AreaRiservataIncarico from '../views/area-riservata/incarichi/Incarico.vue'
import AreaRiservataAggiornamenti from '../views/area-riservata/Aggiornamenti.vue'
import AreaRiservataFiles from '../views/area-riservata/Files.vue'
import AreaRiservataCalendario from '../views/area-riservata/Calendario.vue'
import AreaRiservataVideoFormazione from '../views/area-riservata/VideoFormazione.vue'
import AreaRiservataCreaEvento from '../views/area-riservata/CreaEvento.vue'
import AreaRiservataModificaEvento from '../views/area-riservata/ModificaEvento.vue'

import Public from '../views/public/Public.vue'
import PublicHome from '../views/public/Home.vue'
import PublicContattaci from '../views/public/Contattaci.vue'
import PublicImmobili from '../views/public/Immobili.vue'
import PublicImmobile from '../views/public/Immobile.vue'
import PublicLavoraConNoi from '../views/public/LavoraConNoi.vue'
import PublicAgente from '../views/public/Agente.vue'
import Public404 from '../views/public/404.vue'
import PublicBusiness from '../views/public/PossibilitaDiBusiness.vue'
import PublicAgencys from '../views/public/Agencys.vue'
import PublicAgency from '../views/public/Agency.vue'
import PublicPrivacyPolicy from '../views/public/PrivacyPolicy.vue'

import Admin from '../views/admin/admin.vue'
import AdminLogin from '../views/admin/login.vue'
import AdminHome from '../views/admin/home.vue'
import AdminAgenti from '../views/admin/Agenti.vue'
import AdminAgentiCrea from '../views/admin/AgentiCrea.vue'
import AdminUser from '../views/admin/user/User.vue'
import Admin404 from '../views/admin/Admin404.vue'
import AdminIncarichi from '../views/admin/Incarichi.vue'
import AdminIncarico from '../views/admin/Incarico.vue'
import AdminIncaricoCrea from '../views/admin/IncaricoCrea.vue'
import AdminAggiornamenti from '../views/admin/Aggiornamenti.vue'
import AdminAccessi from '../views/admin/Accessi.vue'
import AdminCalendario from '../views/admin/Calendario.vue'
import AdminFiles from '../views/admin/Files.vue'
import AdminVideoFormazioneCrea from '../views/admin/VideoFormazioneCrea.vue'
import AdminVideoFormazione from '../views/admin/VideoFormazione.vue'
import AdminVideoFormazioneModifica from '../views/admin/VideoFormazioneModifica.vue'
import AdminImmobili from '../views/admin/Immobili.vue'
import AdminAgenzie from '../views/admin/agenzie/Agenzie.vue'
import AdminAgenzieCrea from '../views/admin/agenzie/AgenziaCrea.vue'
import AdminAgenziaModifica from '../views/admin/agenzie/AgenziaModifica.vue'
import AdminCreaEvento from '../views/admin/CreaEvento.vue'
import AdminModificaEvento from '../views/admin/ModificaEvento.vue'
import AdminOfferteImperdibili from '../views/admin/OfferteImperdibili.vue'
import AdminStoricoIncarichi from '../views/admin/StoricoIncarichi.vue'
Vue.use(VueRouter)

const routes = [
  { path: '', component: Public,
    children:[
      {path: '/', name:'Home', component: PublicHome},
      {path: '/home', redirect: '/' },
      {path: '/contattaci', name:'Contattaci', component: PublicContattaci},
      {path: '/immobili', name:'Immobili',component: PublicImmobili},
      {path: '/immobili/:id', name:'Immobile',component: PublicImmobile},
      {path: '/agente/:id', name:'Agente',component: PublicAgente},
      {path: '/lavora-con-noi', name:'Lavora Con Noi',component:PublicLavoraConNoi},
      {path: '/business', name:'Possibilità Di Business',component:PublicBusiness},
      {path: '/404', name:'Page Not Found', component: Public404},
      {path: '/agency', name:'Agency',component: PublicAgencys},
      {path: '/agency/:id', name:'Agency',component: PublicAgency},
      {path: '/privacy-policy', name:'Privacy Policy',component: PublicPrivacyPolicy},
    ]
  },
  { path: '/area-riservata', name:'Area Riservata', component: AreaRiservata,
    children:[
      {path: 'login', name:'Area Riservata - Login', component: AreaRiservataLogin},
      {path: '/', name:'Area Riservata - Home', component: AreaRiservataHome},
      {path: 'home', redirect: '/area-riservata' },
      {path: 'incarichi-crea', name:'Area Riservata - Crea Incarico', component: AreaRiservataIncarichiCrea},
      {path: 'impostazioni', name:'Area Riservata - Impostazioni', component: AreaRiservataImpostazioni},
      {path: 'incarichi', name: 'Area Riservata - Incarichi', component: AreaRiservataIncarichi},
      {path: 'incarichi/:id', name:'Area Riservata - Incarico', component: AreaRiservataIncarico},
      {path: 'aggiornamenti', name: 'Area Riservata - Aggiornamenti', component: AreaRiservataAggiornamenti},
      {path: 'files', name: 'Area Riservata - Files', component: AreaRiservataFiles},
      {path: 'calendario', name: 'Area Riservata - Calendario',component:AreaRiservataCalendario},
      {path: 'creaEvento', name: 'Area Riservata - Crea Evento',component:AreaRiservataCreaEvento},
      {path: 'modificaEvento/:id', name: 'Area Riservata - Modifica Evento',component:AreaRiservataModificaEvento},
      {path: 'videoFormazione', name: 'Area Riservata - Video Formazione',component:AreaRiservataVideoFormazione},

      {path: '404', name:'Area Riservata - 404', component: AreaRiservata404},
      {path: '*', redirect:'/area-riservata/404'}
    ]
  },
  { path: '/admin', name:'Admin', component: Admin,
    children:[
      {path: 'login', name:'Admin - Login', component: AdminLogin},
      {path: '/', name:'Admin - Home', component: AdminHome},
      {path: 'home', redirect: '/admin' },
      {path: 'agenti', name:'Admin - Agenti', component: AdminAgenti},
      {path: 'agenti-crea', name:'Admin - Crea Agente', component: AdminAgentiCrea},
      {path: 'agenti/:id', name:'Admin - Agente', component: AdminUser},
      {path: 'incarichi', name:'Admin - Incarichi', component: AdminIncarichi},
      {path: 'incarichi/:id', name:'Admin - Incarico', component: AdminIncarico},
      {path: 'incarichi-crea', name: 'Admin - Crea Incarico', component: AdminIncaricoCrea},
      {path: 'aggiornamenti', name: 'Admin - Aggiornamenti', component: AdminAggiornamenti},
      {path: 'accessi', name: 'Admin - Accessi',component:AdminAccessi},
      {path: 'calendario', name: 'Admin - Calendario',component:AdminCalendario},
      {path: 'creaEvento', name: 'Admin - Crea Evento',component:AdminCreaEvento},
      {path: 'modificaEvento/:id', name: 'Admin - Modifica Evento',component:AdminModificaEvento},
      {path: 'files', name: 'Admin - Files',component:AdminFiles},
      {path: 'videoFormazione-crea', name: 'Admin - Crea Video Formazione',component:AdminVideoFormazioneCrea},
      {path: 'videoFormazione', name: 'Admin - Video Formazione',component:AdminVideoFormazione},
      {path: 'videoFormazione-modifica/:id', name: 'Admin - Video Formazione Modifica',component:AdminVideoFormazioneModifica},
      {path: 'immobili', name: 'Admin - Immobili',component:AdminImmobili},
      {path: 'agenzie', name: 'Admin - Agenzie',component:AdminAgenzie},
      {path: 'agenzie/:id', name: 'Admin - Agenzia Modifica',component:AdminAgenziaModifica},
      {path: 'agenzie-crea', name: 'Admin - Agenzie Crea',component:AdminAgenzieCrea},
      {path: 'offerte-imperdibili', name: 'Admin - Offerte Imperdibili',component:AdminOfferteImperdibili},
      {path: 'storicoIncarichi', name: 'Admin - Storico Incarichi',component:AdminStoricoIncarichi},

      {path: '404', name:'Admin - 404', component: Admin404},
      {path: '*', redirect:'/admin/404'}
    ]
  },
  { path: '*', redirect:'/404'}
  
]

const router = new VueRouter({
  scrollBehavior(to) {
    if (to.hash) {return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });}
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  mode: 'history',
  routes
})

export default router
