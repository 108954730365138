<template>
    <div id="incarico">
        <IncaricoTab :incarico="incarico" :key="rerender" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IncaricoTab from '../../../components/area-riservata/incarichi/incarico_tabs.vue'
export default {
    components:{IncaricoTab},
    data() {
        return {
            rerender:0,
            incarico:{},
            found:false,
        }
    },
    computed: mapGetters(['incarichi','retrivedIncarichi']),
    methods: {
        ...mapActions(['fetchAllIncarichiFromUser']),
        forceRerender(){
            this.setupIncarico();
            this.rerender++;
        },
        setupIncarico(){
            const index = this.incarichi.findIndex(incarico => incarico._id == this.$route.params.id);
            if(index!=-1){
                this.found = true;
                this.incarico = this.incarichi[index];
            }else{
                this.$router.replace('/area-riservata/404');
            }
        },
        setup(){
            if(!this.retrivedIncarichi){
                this.$parent.$parent.$parent.toogleLoading(true)
                this.fetchAllIncarichiFromUser().then(()=>{
                    this.setupIncarico();
                    this.$parent.$parent.$parent.toogleLoading(false)
                }).catch(()=>{this.$parent.$parent.$parent.toogleLoading(false)});
            }else{
                this.setupIncarico()
            }  
        }
    },
    mounted() {
        this.setup();
    },
}
</script>