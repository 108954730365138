<template>
    <div id="incarico">
        <IncaricoTab :incarico="incarico" :key="rerender" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IncaricoTab from '../../components/admin/incarichi/incarico_tabs.vue'
export default {
    components:{IncaricoTab},
    data() {
        return {
            rerender:0,
            incarico:{},
            found:false,
        }
    },
    computed: mapGetters(['incarichi','retrivedIncarichi']),
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['fetchAllIncarichiFromAdmin']),
        forceRerender(){
            this.setupIncarico();
            this.rerender++;
        },
        setupIncarico(){
            const index = this.incarichi.findIndex(incarico => incarico._id == this.$route.params.id);
            if(index!=-1){
                this.found = true;
                this.incarico = this.incarichi[index];
            }else{
                this.$router.replace('/admin/404');
            }
            this.toogleLoading(false);
        }
    },
    mounted() {
        if(!this.retrivedIncarichi){
            this.toogleLoading(true);
            this.fetchAllIncarichiFromAdmin().then(()=>{this.setupIncarico();});
            }else{
                this.toogleLoading(true);
                this.setupIncarico();
            }
    },
}
</script>