<template>
  <v-app>
    <Navbar/>
    <v-main class="generalContainer">
        <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Navbar from '../../components/public/navbar.vue'
import Footer from '../../components/public/footer.vue'
export default {
    name: 'Public',
    components:{Navbar,Footer},
}
</script>

<style lang="scss" scoped>
.generalContainer{
  padding-top: var(--navbarHeight)!important;
  min-height: 100vh;
}
</style>