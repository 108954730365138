<template>
    <div id="modificaVideo">
        <div class="mainTitle">MODIFICA VIDEO FORMAZIONE</div>
        <div class="formContainer elevation-9">
            <v-form @submit.prevent="modificaVideoFormazione" v-model="isFormValid">
                <v-text-field v-model="form.titolo" :rules="requiredRules" label="Titolo" clearable outlined dense></v-text-field>
                <v-textarea v-model="form.descrizione" name="input-7-1" label="Descrizione" clearable outlined dense></v-textarea>
                <v-text-field v-model="form.urlVideo" :rules="requiredRules" label="Url Video" clearable outlined dense></v-text-field>
                <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" class="hashtagInput">
                    <div slot="autocomplete-item" slot-scope="props" class="my-item" @click="props.performAdd(props.item)">
                        {{ props.item.text }}
                    </div>
                </vue-tags-input>

                <v-btn block color="orange" type="submit" :disabled="!isFormValid"> MODIFICA </v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: mapGetters(['videoFormazione','retrivedVideoFormazione']),
    components: {
        VueTagsInput,
    },
    inject: ['toogleLoading'],
    data() {
        return {
            isFormValid:false,
            form:{
                _id:'',
                titolo:'',
                descrizione:'',
                urlVideo:'',
                hashtags:[],
            },
            tag:'',
            tags: [],
            videoF:{},
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
        }
    },
    methods: {
        ...mapActions(['fetchAllVideoFormazione','editVideoFormazione']),
        setup(){
            const index = this.videoFormazione.findIndex(video => video._id == this.$route.params.id);
            if(index!=-1){
                this.videoF = this.videoFormazione[index];
                this.tags=[],
                this.tag='',
                this.form = {
                    _id: this.videoF._id,
                    titolo: this.videoF.titolo,
                    descrizione: this.videoF.descrizione,
                    urlVideo: this.videoF.urlVideo,
                    hashtags:[],
                }
                this.videoF.hashtags.forEach(hash => {
                    this.tags.push({
                        text:hash,
                        tiClasses:["ti-valid"]
                    })
                });

            }else{
                this.$router.replace('/admin/404');
            }
        },
        modificaVideoFormazione(){
            this.tags.forEach(tag => {
                this.form.hashtags.push(tag.text)
            });
            this.toogleLoading(true);
            this.editVideoFormazione(this.form).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$router.push('/admin/videoFormazione');
                this.toogleLoading(false);
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        }
    },
    mounted() {
        if(!this.retrivedVideoFormazione){
            this.fetchAllVideoFormazione().then(()=>{
                this.setup();
            });
        }else{
            this.setup();
        }
    },
}
</script>

<style lang="scss" scoped>
.formContainer{
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    padding: 30px;
    border-radius: 15px;
}
.hashtagInput{
    width: 100%;
    max-width: unset;
    margin-bottom: 27px;
}
</style>