import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    eventi:[],
    retrivedEventi:false,
}

const getters = {
    eventi: state => state.eventi,
    retrivedEventi: state => state.retrivedEventi,
}

const actions = {
    async createEvento({commit},form){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            else if(cookie.get('token')!=null){
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/eventi/add',form,config)
            .then(function (response) {
                commit("createEventoFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async editEvento({commit},form){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            else if(cookie.get('token')!=null){
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/eventi/edit',form,config)
            .then(function (response) {
                commit("editEventoFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async deleteEvento({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            else if(cookie.get('token')!=null){
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/eventi/delete',id,config)
            .then(function (response) {
                commit("deleteEventoFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async fecthAllEventi({commit}){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            else if(cookie.get('token')!=null){
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.get(Server+'/eventi/get',config)
            .then(function (response) {
                commit("fecthAllEventiFun",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
}

const mutations = {
    createEventoFun(state,response){
        state.eventi.push(response.evento);
    },
    fecthAllEventiFun(state,response){
        state.eventi = response.eventi;
        state.retrivedEventi = true;
    },
    editEventoFun(state,response){
        const index = state.eventi.findIndex(evento=> evento._id == response.evento._id);
        if(index!=-1){
            state.eventi[index] = response.evento;
        }
    },
    deleteEventoFun(state,response){
        const index = state.eventi.findIndex(evento=> evento._id == response.evento._id);
        if(index!=-1){
            state.eventi.splice(index,1);
        }
    },

}



export default{
    state,
    getters,
    actions,
    mutations
}