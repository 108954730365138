<template>
    <div id="mappa">
        <div class="mappa" v-if="retrivedAgenzie">
            <div class="agenziaView" id="agenziaView">
                <span class="close" v-on:click="closeAgenzia()">X</span>
                <p id="spanName"></p>
                <p>
                    <span id="spanIndirizzo"></span> <span> - </span>
                    <span id="spanCitta"></span><span> (</span>
                    <span id="spanCap"></span><span>)</span>
                </p>
                <a target="_blank" href="https://www.google.com/maps/dir//Via+Lucrezio+Caro,+62,+00193+Roma+RM" id="linkA"><div class="link">Portami Qua</div></a>
            </div>
            <div class="cercaAgenzia">
                <v-select v-model="selectedProvincia" :options="provincie" placeholder="Seleziona Provincia" class="vSelectAgenzia"></v-select>
                <div class="agenzieTrovate" v-if="cittaInProvincia.length!=0">
                    <div class="agenzia" @click="selectedAgenzia(agenzia)" v-for="(agenzia,index) in cittaInProvincia" :key="index">{{agenzia.citta}}</div>
                </div>
            </div>
            <GmapMap :center="center" :zoom="zoom" style="width:100%;  height: 600px;" >
            <GmapMarker :key="m._id" v-for="m in agenzie" :position="{lat: Number(m.lat) ,lng: Number(m.lng)}" :title="m.nome" :icon="require('../../assets/icona20x20.png')" @click="displayAgenzia(m)"></GmapMarker>
            </GmapMap>
        
        </div>  
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
export default {
    components:{vSelect},
    data() {
        return {
            center: { lat: 42.592477, lng: 12.909185 },
            zoom:6,
            places: [],
            currentPlace: null,
            provincie:[],
            selectedProvincia:'',
            cittaInProvincia:[],
        }
    },
    computed: mapGetters(['agenzie','retrivedAgenzie']),
    methods: {
        ...mapActions(['fetchAllAgenzie']),
        setPlace(place) {
            this.currentPlace = place;
        },
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
            });
        },
        setProvincie(){
            this.agenzie.forEach(agenzia=>{
                if(agenzia.provincia){
                    var index = this.provincie.findIndex(provincia => provincia.code == agenzia.provincia);
                    if(index==-1){
                        this.provincie.push({label:agenzia.provincia,code:agenzia.provincia,agenzie:[agenzia]})
                    }else{
                        this.provincie[index].agenzie.push(agenzia);
                    }
                }
            })
        },
        provinciaSelectedFUN(val){
            if(!val){
                this.cittaInProvincia = [];
                this.zoom = 6;
                this.center = { lat: 42.592477, lng: 12.909185 };
            }else{
                var index = this.provincie.findIndex(provincia => provincia.code == val.code);
                this.cittaInProvincia = this.provincie[index].agenzie;
            }
        },
        selectedAgenzia(agenzia){
            this.center.lat = Number(agenzia.lat);
            this.center.lng =Number( agenzia.lng);
            this.zoom = 13;
        },
        displayAgenzia(agenzia){
            var agenziaView = document.getElementById("agenziaView");

            if(agenziaView.style.visibility == "hidden"){
                change(agenzia);
                agenziaView.style.visibility = "visible";
                agenziaView.style.opacity = "1";
            }else{
                this.closeAgenzia();
                change(agenzia);
                agenziaView.style.visibility = "visible";
                agenziaView.style.opacity = "1";
            }
            function change(agenzia){
                var nameView = document.getElementById("spanName");
                var indirizzoView = document.getElementById('spanIndirizzo');
                var cittaView = document.getElementById('spanCitta');
                var capView = document.getElementById('spanCap');
                var linkA = document.getElementById('linkA');
                nameView.innerHTML = agenzia.nome;
                indirizzoView.innerHTML = agenzia.indirizzo;
                cittaView.innerHTML = agenzia.citta;
                capView.innerHTML = agenzia.cap;
                var ind_with_plus = agenzia.indirizzo.replace(/ /g,"+");
                ind_with_plus = ind_with_plus.replace(/,/g,"+");
                var link = "https://www.google.com/maps/dir//"+ind_with_plus+"+"+agenzia.citta.replace(/ /g,"+")+"+"+agenzia.cap;
                linkA.setAttribute("href",link);
            }
        },
        closeAgenzia(){
            var agenziaView = document.getElementById("agenziaView");
            agenziaView.style.visibility = "hidden";
            agenziaView.style.opacity = "0";
        }
    },
    watch:{
        selectedProvincia: function(val){this.provinciaSelectedFUN(val)},
    },
    mounted() {
        this.geolocate();
        if(!this.retrivedAgenzie){
            this.fetchAllAgenzie().then(()=>{
                this.setProvincie();
            });
        }else{
            this.setProvincie();
        }
    },
}
</script>

<style lang="scss" scoped>
.mappa{
    position: relative;
}

.cercaAgenzia{
    width: 190px;
    top:60px;
    left: 10px;
    background-color: #fff;
    padding: 2px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
.agenzieTrovate{
    max-height: 95px;
    margin: 5px 5px 0px 5px;
    overflow: auto;
    transition-duration: 0.2s;
}
.agenzia{
    background-color: rgb(221, 221, 221);
    margin-bottom: 5px;
    border-radius: 5px;
    padding-left: 5px;
    font-weight: 500;
    color: rgb(58, 58, 58);
    cursor: pointer;
    transition-duration: 0.2s;
    text-align: center;
}
.agenzia:hover{
    background-color: rgb(189, 189, 189);
}

.agenzieTrovate::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.agenzieTrovate::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.agenzieTrovate::-webkit-scrollbar-thumb {
  background: rgba(60,60,60,.5);
  border-radius: 5px;
}

/* Handle on hover */
.agenzieTrovate::-webkit-scrollbar-thumb:hover {
  background: rgba(60,60,60,.5);
}
.fancy{
    margin-bottom: 5px;
}
.subtext{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 3px;
    font-weight: 500;
}
.subtext > b{
    color: black;
}

.agenziaView{
    transition: opacity 0.5s ease-out;
    visibility: hidden;
    opacity: 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.979);
    position: absolute;
    top: 50%;left: 50%;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    max-width: 400px;
    width: 90%;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 5px;
}
.agenziaView > #spanName{
    font-weight: 700;
}
.agenziaView > p{
    margin-bottom: 0px;
}

.close{
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
}

</style>