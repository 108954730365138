<template>
    <div id="incarichi">
        <div class="incarichi" v-if="gotUser">
            <IncaricoPreview :incarico="incarico" v-for="incarico in user.incarichi" :key="incarico._id" />
        </div>
    </div>
</template>

<script>
import IncaricoPreview from '../incarichi/incarico_previewNA.vue'
export default {
    components:{IncaricoPreview},
    props:['user'],
    data() {
        return {
            gotUser:false
        }
    },
    watch:{
        user(){
            this.gotUser = true;
        }
    },
    mounted() {
        if(Object.keys(JSON.parse(JSON.stringify(this.user))).length != 0){
            this.gotUser = true;
        }
    },
}
</script>

<style lang="scss" scoped>
.incarichi{
    max-width: 1200px;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin: 10px auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
</style>