<template>
    <div>
        <div class="qoq">
            <div class="big">404</div>
            <div class="small">PAGINA NON TROVATA!</div>
        </div>
    </div>
</template>
<script>
export default {
    metaInfo() {
        return { 
            title: "AggiudicaRe - 404",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - 404"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
    .qoq{
        width: max-content;
        position: absolute;
        top:50%;left:50%;
        transform: translateX(-50%) translateY(-50%);
        user-select: none;
        .big{
            font-size: 200pt;
            font-weight: 800;
            line-height: 200px;
        }
        .small{
            font-size: 30pt;
            line-height: 30px;
            text-align: center;
            font-weight: 600;
            margin-top: 5px;
        }
    }
</style>