<template>
    <div id="lavoraConNoi">        
        <v-form class="form elevation-9" v-model="isFormValid" @submit.prevent="send">
            <div class="titolo">LAVORA CON NOI</div>
            <v-row >
                <v-col cols="12" sm="6">
                    <div class="topField">Nome *</div>
                    <v-text-field v-model="form.nome" label="Nome" placeholder="Nome" :rules="requiredRules" prepend-inner-icon="mdi-alpha-n-box" solo dense clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="noMtOnLow">
                    <div class="topField">Cognome *</div>
                    <v-text-field v-model="form.cognome" label="Cognome" placeholder="Cognome" :rules="requiredRules" prepend-inner-icon="mdi-alpha-c-box" solo dense clearable></v-text-field>
                </v-col>
            </v-row>
            <v-row style="margin-top:-30px">
                <v-col cols="12" sm="6">
                    <div class="topField">Email *</div>
                    <v-text-field v-model="form.email" label="Email" placeholder="Email" :rules="emailRules" prepend-inner-icon="mdi-email" solo dense clearable></v-text-field>    
                </v-col>
                <v-col cols="12" sm="6" class="noMtOnLow">
                    <div class="topField">Provincia di Operatività</div>
                    <model-select  class="mb-20" :options="provincieOptions" v-model="form.provincia" placeholder="Provincia di Operatività"> </model-select>    
                </v-col>
            </v-row>
            <v-row style="margin-top:-30px">
                <v-col cols="12" sm="6" class="aBitOfMT">
                    <div class="topField">Esperienza</div>
                    <v-select :items="['Si','No']" v-model="form.esperienza" label="Esperienza" solo dense prepend-inner-icon="mdi-file-document-multiple"></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="noMtOnLow">
                    <div class="topField">Azienda di Provenienza</div>
                    <v-text-field :disabled="form.esperienza=='No'" v-model="form.azienda" label="Azienda di Provenienza" placeholder="Azienda di Provenienza" prepend-inner-icon="mdi-office-building-outline" solo dense clearable></v-text-field> 
                </v-col>
            </v-row>
            <div class="topField" style="margin-top:-20px">Link Curriculum</div>
            <v-text-field v-model="form.curriculum" label="Link Curriculum" placeholder="Link Curriculum" prepend-inner-icon="mdi-file-document-outline" solo dense clearable></v-text-field>
            <v-checkbox style="margin-top:-10px;" class="checkPersonal" :rules="trueRules" v-model="form.checkbox1" color="white">
                <template v-slot:label>
                    <span class="checkbox">Ho preso visione dell’informativa sulla Protezione dei Dati Personali predisposta dalla società. 
                    <router-link to="/privacy-policy">Privacy Policy</router-link></span>
                </template>
            </v-checkbox>
            <v-checkbox class="checkPersonal" :rules="trueRules" v-model="form.checkbox2" color="white">
                <template v-slot:label>
                    <span class="checkbox">Acconsento al trattamento dei dati personali da me forniti, finalizzato a ricevere supporto per la prestazione della consulenza richiesta.</span>
                </template>
            </v-checkbox>

            <transition name="fade">
                    <div v-if="error" class="errore">{{error}}</div>
            </transition>
            
            <v-btn type="submit" block elevation="2" :loading="sending" :disabled="!isFormValid">
                <v-icon left> mdi-send </v-icon>
                INVIA
            </v-btn>
            <transition name="fade">
                <div class="emailInviata" v-if="sended">
                    <div class="centeredAbs">
                        <v-icon>mdi-check-circle</v-icon>
                        <div>EMAIL INVIATA</div>
                        <v-btn x-small  @click="reset()">invia di nuovo</v-btn>
                    </div>
                </div>
            </transition>
        </v-form>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
import ProvincieJson from '../../utils/provincie.json'
import axios from 'axios';
import { Server } from '../../utils/variabili';
export default {
    components:{ModelSelect},
    data() {
        return {
            form:{
                nome:'',
                cognome:'',
                email:'',
                provincia:'',
                esperienza:'No',
                azienda:'',
                curriculum:'',
                checkbox1:false,
                checkbox2:false,
            },
            error:'',
            sending:false,
            sended:false,
            isFormValid:false,
            provincieOptions:[],
            emailRules: [
                v => !!v || 'E-mail è necessaria',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail non valida',
            ],
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            trueRules:[
                v => !!v || 'Confermare presa visione'
            ]
        }
    },
    methods: {
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        reset(){
            this.sending=false;
            this.sended=false;
            this.error='';
            this.form = {
                nome:'',
                cognome:'',
                email:'',
                provincia:'',
                esperienza:'No',
                checkbox1:false,
                checkbox2:false,
            }
        },
        send(){
            this.sending=true;
            this.error='';
            axios.post(Server+'/email/lavoraConNoi',{
                form:this.form,
            }).then(()=>{
                this.sended=true;
                this.sending=false;
            }).catch((error)=>{
                console.log(error)
                this.error = 'Server momentaneamente offline riprova più tardi'
                this.sending=false;
            })
        }
    },
    mounted() {
        this.loadProvincie();
    },
}
</script>

<style lang="scss" scoped>
#lavoraConNoi{
    padding: 0px 10px;
}
.form{
    background: var(--primaryColor);
    width: 100%;
    max-width: 700px;
    padding: 10px 20px 20px 20px;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .titolo{
        font-size: 25pt;
        font-weight: 600;
        text-align: center;
        color: rgb(255, 255, 255);
    }
    .topField{
        color: rgba(255, 255, 255, 0.767);
        font-size: 9pt;
        padding-left: 10px;
        margin-top: 0px;
    }
    .errore{
        color: white;
        font-weight: 700;
        background-color: rgb(230, 72, 72);
        border-radius: 5px;
        padding:2px 10px;
        font-size: 8pt;
        width: max-content;
        margin: 0 auto;
        margin-bottom: 5px;
    }
    .emailInviata{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;left:0;
        background: var(--primaryColor);
        .centeredAbs{
            position: absolute;
            width: max-content;
            text-align: center;
            top:50%;left:50%;
            transform: translateX(-50%) translateY(-50%);
            .v-icon{
                color: white;
                font-size: 90pt;
            }
            div{
                font-size: 20pt;
                color: white;
                font-weight: 600;
            }
        }
    }
    .checkbox{
        font-size: 8pt;
        line-height: 10px;
        color: white;
        padding-left: 5px;
        a{
            color: whitesmoke;
            font-weight: 700;
        }
    }
}
.checkPersonal{
    margin-top: -0px;
}
.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}
.aBitOfMT{
    @media screen and (max-width: 600px) {margin-top: 20px;}
}
</style>