<template>
    <div id="agenzie">
        <div class="mainTitle">AGENZIE</div>
        <div class="agenzie">
            <div class="agenzia elevation-9" v-for="agenzia in agenzie.slice(0,currentSeen)" :key="agenzia._id">
                <div class="nome">{{agenzia.nome}}</div>
                <div class="indirizzo">{{agenzia.indirizzo}} - {{agenzia.citta}} ({{agenzia.cap}})</div>
                <div class="posizione">{{agenzia.lat}} , {{agenzia.lng}}</div>
                <div class="actions">
                    <v-btn small color="orange" :to="'/admin/agenzie/'+agenzia._id">MODIFICA</v-btn>
                    <v-dialog width="500" >    
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" small v-bind="attrs" v-on="on"> CANCELLA </v-btn>
                        </template>          
                        <v-card>
                            <v-card-title><span class="text-h5">Cancella Agenzia</span></v-card-title>
                            <v-card-text>
                                Sei sicuro di voler <b class="redText">cancellare</b> l'agenzia <br>( {{agenzia.nome}} )?
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="cancella(agenzia._id)" > CANCELLA </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </div>
        <div class="btnMore">
            <v-btn color="primaryBG" dark block @click="loadMore()" v-if="this.agenzie.length > this.currentSeen" >ALTRI {{this.agenzie.length - this.currentSeen}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            currentSeen:10,
        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['agenzie','retrivedAgenzie']),
    methods: {
        ...mapActions(['fetchAllAgenzie','deleteAgenzia']),
        loadMore(){
            if(this.agenzie.length - this.currentSeen > 10){
                this.currentSeen = this.currentSeen+10;
            }else{
                this.currentSeen = this.agenzie.length;
            }
        },
        cancella(id){
            this.toogleLoading(true)
            this.deleteAgenzia({id:id}).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Agenzia:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Agenzia:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            });
        }

    },
    mounted() {
        if(!this.retrivedAgenzie){
            this.toogleLoading(true);
            this.fetchAllAgenzie().then(()=>{this.toogleLoading(false);}).catch(()=>{this.toogleLoading(false);});
        }
    },
}
</script>

<style lang="scss" scoped>
.agenzie{
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
    flex-flow:row wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.agenzia{
    background-color: var(--primaryColor);
    padding: 10px 20px 20px 20px;
    width: max-content;
    border-radius: 15px;
    color: white;
    .nome{
        font-weight: 700;
        font-size: 18pt;
        text-align: left;
        text-transform: uppercase;
    }
    .indirizzo{
        font-size: 9pt;
        margin-top: -10px;
    }
    .posizione{
        text-align: center;
        margin-top: 10px;
        font-size: 20pt;
    }
    .actions{
        display: flex;
        margin-top: 10px;
        column-gap: 10px;
        width: max-content;
        margin: 0 auto;
    }
}

.btnMore{
    max-width: 800px;
    margin: 0 auto 80px auto;
    padding: 0px 6%;
}
</style>