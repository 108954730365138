<template>
  <div id="app">
    <notifications group="baseNotification" />
    <Cookies />
    <router-view/>
  </div>
</template>

<script>
import Cookies from './components/public/cookies.vue'
export default {
  components:{Cookies},
  name: 'App',
};
</script>

<style lang="scss" scoped>
  @import url('./assets/main.scss');
</style>
