<template>
    <div id="offerteImperdibili">
        <div class="mainTitle">OFFERTE IMPERDIBILI</div>
        <div class="tabella elevation-9">
            <div class="tableContent">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left"> CATEGORIA </th>
                                <th class="text-left"> POSIZIONE </th>
                                <th class="text-left"> DIMENSIONI </th>
                                <th class="text-left"> DESCRIZIONE </th>
                                <th class="text-left"> PREZZO </th>
                                <th class="text-left"> OFFERTA IMPERDIBILE </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="immobile in immobili" :key="immobile._id" class="immobileRiga">
                                <td class="categoriaColumn"><span v-for="(categoria,index) in immobile.categoria" :key="index">{{categoria.nome}}</span></td>
                                <td>{{immobile.posizione.comune}} ({{immobile.posizione.provincia}}) - {{immobile.posizione.regione}} ({{immobile.posizione.nazione}})</td>
                                <td><span v-if="immobile.camere">{{immobile.camere.totale}}<span class="unita">Locali</span> -</span>  {{immobile.metriQuadri}}<span class="unita">M2</span> </td>
                                <td class="descrizioneColumn"><div class="descrizione">{{immobile.descrizione}}</div></td>
                                <td class="prezzoColumn"><span>{{moneyFormat(immobile.prezzo)}}€</span></td>
                                <td><v-switch v-model="immobile.offertaImperdibile" inset></v-switch></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
            <v-btn color="orange" class="btnSubmit" @click="editFun()"> MODIFICA </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: mapGetters(['immobili','retrivedImmobili']),
    methods: {
        ...mapActions(['fetchAllImmobili','editImmobiliImperdibili']),
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        editFun(){
            this.toogleLoading(true);
            var toSend = [];
            this.immobili.forEach(immobile => {
                toSend.push({
                    _id: immobile._id,
                    offertaImperdibile: immobile.offertaImperdibile
                })
            });
            this.editImmobiliImperdibili(toSend).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Immobili Imperdibili:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false);
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Immobili Imperdibili:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        }
    },
    inject: ['toogleLoading'],
    mounted() {
        if(!this.retrivedImmobili){
            this.fetchAllImmobili();
        }
    },
}
</script>

<style lang="scss" scoped>
.categoriaColumn{
    span{&::before{content: ', ';}}
    span:first-child{
        &::before{content: '';}
    }
}


.descrizioneColumn{
    max-width: 500px;
    .descrizione{
        margin-top: 10px;
        margin-bottom: 10px;
        height: 50px;
        overflow: auto;
        font-size: 8pt;
    }
}
.tabella{
    max-width: 1200px;
    padding: 20px;
    border-radius: 15px;
    margin: 0 auto;
    .tableContent{
        max-height: 80vh; 
        overflow: auto;
        padding-right: 10px;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(117, 117, 117);
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.btnSubmit{
    margin-top: 15px;
    left: 50%;
    transform: translateX(-50%);
}
</style>