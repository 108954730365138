<template>
    <div id="incarico">
        <router-link :to="'/area-riservata/incarichi/'+incarico._id">
            <div class="incarico elevation-9 buzz-out-on-hover">
                <div class="flexxed">
                    <div class="stato"><IncaricoStato :stato="incarico.stato" /></div>
                </div>
                <div class="data">{{formatDate(incarico.data_inizio)}}</div>
                
                <div class="utente">
                    <div class="nome">{{incarico.utente.nome}} {{incarico.utente.cognome}}</div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import IncaricoStato from '../../incarichi/incaricoStatoSmall.vue'
export default {
    components:{IncaricoStato},
    props:['incarico'],
    methods: {
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        }
    },
}
</script>

<style lang="scss" scoped>
.incarico{
    width: max-content;
    border-radius: 15px;
    margin-bottom: 10px;
    position: relative;
    padding: 10px;
    min-height: 40px;
    color: rgb(47, 47, 47);
    .data{
        position: absolute;
        top:5px;left:25px;
        font-size: 8pt;
    }
    .flexxed{
        display: flex;
        .stato{
            flex:1;
            text-transform: uppercase;
            font-size: 8pt;
        }
        .agente{
            flex:1
        }
    }
    
    .utente{
        text-align: center;
    }
}
</style>