<template>
    <div id="lavoraconnoi">
        <div class="top">
            <img :src="require('../../assets/imgs/lavoraConNoi.svg')" alt="">
            <div class="titolo">LAVORA CON NOI</div>
        </div>
        <div class="mid">
            <div class="left">
                <a href="tel:01119466412" class="buzz-out-on-hover">
                    <div class="folder elevation-9">
                        <v-icon>mdi-phone</v-icon>
                        <div class="testo">01119466412</div>
                    </div>
                </a>
            </div>
            <div class="right">
                <a href="mailto:segreteria@aggiudicare.casa" class="buzz-out-on-hover">
                    <div class="folder elevation-9">
                        <v-icon>mdi-email</v-icon>
                        <div class="testo">segreteria@aggiudicare.casa</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="bottom">
            <LavoraConNoiForm />    
        </div>
        
    </div>
</template>

<script>
import LavoraConNoiForm from '../../components/public/lavoraConNoiForm.vue'
export default {
    components:{LavoraConNoiForm},
    metaInfo() {
        return { 
            title: "AggiudicaRe - Lavora Con Noi",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Lavora Con Noi"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.top{
    margin-top: -130px;
    @media screen and (max-width: 432px) {margin-top: -30%;}
    margin-bottom: 20px;
    img{
        width: 100%;
        max-height: 500px;
    }
    .titolo{
        font-size: 60pt;
        line-height: 60px;
        text-align: center;
        font-weight: 800;
        color: var(--primaryColor);
        @media screen and (max-width: 676px) {font-size:50pt;line-height: 50px;}
        @media screen and (max-width: 274px) {font-size:40pt;line-height: 40px;}
    }
}
.mid{
    display: flex;
    width: max-content;
    margin: 0 auto;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 509px) {flex-flow: column; align-items: center;}
    .folder{
        background: var(--primaryColor);
        text-align: center;
        padding: 20px;
        border-radius: 15px;
        
        .testo{
            color: white;
            font-weight: 500;
            font-size: 20pt;
            @media screen and (max-width: 676px) {font-size:15pt}
            @media screen and (max-width: 509px) {font-size:12pt}
        }
        .v-icon{
            font-size: 60pt;
            @media screen and (max-width: 676px) {font-size:50pt}
            @media screen and (max-width: 509px) {font-size:40pt}
            color: white;
        }
    }
    .left{

    }
    .right{
        .folder{
            .testo{
                @media screen and (max-width: 260px) {font-size:10pt}
            }
        }

    }
}
.bottom{
    margin-bottom: 20px;
}
</style>