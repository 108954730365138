<template>
    <span>
        <v-chip class="ma-2" color="inRicerca" small label text-color="black" v-if="stato=='In Ricerca'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="ricercaSospesa" small label text-color="black" v-if="stato=='Ricerca Sospesa'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="ricercaConclusa" small label text-color="black" v-if="stato=='Ricerca Conclusa'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="inAsta" small label v-if="stato=='In Asta'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="aggiudicata" small label v-if="stato=='Aggiudicata'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
            <v-chip class="ma-2" color="persa" small label v-if="stato=='Persa'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="rinunciaRicerca" small label v-if="stato=='Rinuncia Ricerca'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
        <v-chip class="ma-2" color="rinunciaAsta" small label v-if="stato=='Rinuncia Asta'" >
            <v-icon left> mdi-state-machine </v-icon>
            {{stato}}
        </v-chip>
    </span>
</template>

<script>
export default {
    props:['stato']
}
</script>