<template>
  <div>
    <v-app-bar
      color="primaryBG"
      dense
      dark fixed
      class="frontZIND"
    >

        <div class="logo">
          <router-link to="/home">
            <img :src="require('../../assets/logo/logo500nstWhite.png')" height="100%" alt="">
          </router-link>
        </div>


      <v-spacer></v-spacer>

      <div class="bigNav">
        <router-link :to="item.link" v-for="(item,index) in items" :key="index">
          <v-btn depressed small color="primaryBG">
            <v-icon left>{{item.icon}}</v-icon> {{item.name}}
          </v-btn>  
        </router-link>
      </div>

      <v-app-bar-nav-icon class="nav_btn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>    

    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary fixed class="primaryBG accent-4 frontZIND" dark>
      <v-list-item class="notSelectableTxt">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            AggiudicaRe
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        
        

      <v-divider></v-divider>
 
      <v-list dense nav >
        <v-list-item v-for="(item,index) in items" :key="index" link :to="item.link">
          <v-list-item-icon><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title class="linkTitle">{{item.name}}</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer:false,
      items:[
        {name:'HOME',link:'/home',icon:'mdi-home'},
        {name:'IMMOBILI',link:'/immobili',icon:'mdi-home-search'},
        {name:'AGENCY',link:'/agency',icon:'mdi-home-search-outline'},
        {name:'CONTATTACI',link:'/contattaci',icon:'mdi-information'},
        {name:'LAVORA CON NOI',link:'/lavora-con-noi',icon:'mdi-account-tie'},
        {name:'POSSIBILITà DI BUSINESS',link:'/business',icon:'mdi-office-building-outline'},
        {name:'AREA RISERVATA',link:'/area-riservata',icon:'mdi-lock'},
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
  @import url('../../assets/navbar.scss');
  .logo{
    height: 100%;
    padding: 5px 0px;
  }
  .nav_btn{
    display: none ;
  }
  @media only screen and (max-width: 1170px) {
    .nav_btn{
      display: block;
    }
    .bigNav{
      display: none;
    }
  }

.openMenu{
  position: fixed;
  left: 0;top:5px;
  z-index: 6;
}
.frontZIND{
  z-index: 999!important;
}
.linkTitle{
  text-transform: uppercase;
}
</style>