<template>
    <div id="login">
        <div class="loginForm elevation-9">
            <img src="../../assets/logo/logo500nst.png" alt="">
            <div class="subtext">ADMIN</div>
            
            <form @submit.prevent="submit">
                <v-text-field v-model="form.email" label="Email" required outlined clearable></v-text-field>

                <v-text-field
                    v-model="form.password"
                    
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="Insert your password"
                    @click:append="show1 = !show1"
                    required
                    outlined
                    clearable
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'">
                </v-text-field>
                <v-btn block color="mainBtn" type="submit">
                    Login
                </v-btn>
                <router-link to="/"><v-btn color="primary" x-small class="backToSite"> Torna al sito </v-btn></router-link>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Server } from '../../utils/variabili';
export default {
    inject: ['toogleLoading'],
    data() {
        return {
            form:{
                email:'',
                password:'',
            },
            show1:false,
        }
    },
    beforeCreate(){
        if(this.$cookies.get('adminToken')){
            this.$router.push('/admin/home');
        }
    },
    methods: {
        submit(){
            this.toogleLoading(true);
            axios.post(Server+'/admin/login',{
                username: this.form.email,
                password: this.form.password 
            }).then(response=>{
                if(this.$cookies.get('token')){this.$cookies.remove("token");}
                this.$cookies.set('adminToken',response.data.token);
                this.$router.push('/admin/home');
                this.$notify({
                    group: 'baseNotification',
                    title: 'Acesso Eseguito:',
                    text: 'Amministratore',
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false);
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Errore Login',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .loginForm{
        max-width: 500px;
        width: 100%;
        position: absolute;
        top:50%;left:50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 30px 30px 15px 30px;
        border-radius: 15px;
        img{
            width: 100%;
        }
        .subtext{
            text-align: center;
            color: red;
            font-weight: 700;
            font-size: 17pt;
            line-height: 17px;
            margin-top: -10px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }
.backToSite{
    width: max-content;
    margin-top: 5px;
    left: 50%;
    transform: translateX(-50%);
}
</style>