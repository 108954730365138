<template>
    <div id="user">
        
        <div class="top elevation-9">
            <div class="main">
                <div class="left" v-if="user.foto" :style="'background-image: url(\''+user.foto+'\')'"></div>
                <div class="left" v-if="!user.foto" :style="'background-image: url(\''+require('../../../assets/imgs/empty-profile.png')+'\')'"></div>
                <div class="center">
                    <div class="data">
                        <div class="figura">{{user.figura}}</div>
                        <div class="nome">{{user.nome}} {{user.cognome}}</div>
                        <div class="email">{{user.email}}</div>
                    </div>
                </div>
            </div>
            <template>
              <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Mostra di più
                        <template v-slot:actions>
                            <v-icon color="white">
                            $expand
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="data2">
                            <div class="field" v-if="user.cellulare"><span class="tt">Cellulare: </span> {{user.cellulare}}</div>
                            <div class="field" v-if="user.dataContr"><span class="tt">Contrattuazione: </span> {{formatDate(user.dataContr)}}</div>
                            
                            <div v-if="user.ufficio">
                                <div class="field" v-if="user.ufficio"> 
                                    <span class="tt" v-if="user.ufficio.via || user.ufficio.comune || user.ufficio.provincia || user.ufficio.regione || user.ufficio.cap">Ufficio: </span> 
                                    <span v-if="user.ufficio.via">{{user.ufficio.via}}</span> 
                                    <span v-if="user.ufficio.comune">, {{user.ufficio.comune}}</span>
                                    <span v-if="user.ufficio.provincia"> ({{user.ufficio.provincia}})</span>
                                    <span v-if="user.ufficio.regione"> - {{user.ufficio.regione}}</span>
                                    <span v-if="user.ufficio.cap"> ({{user.ufficio.cap}})</span>
                                </div>
                            </div>
                            <div class="field" v-if="user.topExecutive"> <span>Top Executive: </span> {{user.topExecutive.nome}} {{user.topExecutive.cognome}}</div>
                            <div class="field" v-if="user.executive"> <span>Executive: </span> {{user.executive.nome}} {{user.executive.cognome}}</div>
                            <div class="field" v-if="user.teamLeader"> <span>Team Leader: </span> {{user.teamLeader.nome}} {{user.teamLeader.cognome}}</div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </template>
        </div>

        <UserTabs :user="user" :key="rerender"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserTabs from '../../../components/admin/user/user_tabs.vue'
export default {
    components:{UserTabs},
    data() {
        return {
            user:{},
            rerender:0,
            found:null,
        }
    },
    watch:{
        $route (){
            this.setUser()
        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['users','retrivedUsers']),
    methods: {
        ...mapActions(['adminGetAllUsers']),
        forceRerender(){
            this.setUser();
            this.rerender++;
        },
        setUser(){
            const index = this.users.findIndex(user => user._id == this.$route.params.id);
            if(index!=-1){
                this.found = true;
                this.user = this.users[index];
            }else{
                this.$router.replace('/admin/404');
            }
            this.toogleLoading(false)
        },
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        },
    },
    created() {
        if(!this.retrivedUsers){
            this.toogleLoading(true)
            this.adminGetAllUsers().then(()=>{this.setUser();});
        }else{
            this.toogleLoading(true)
            this.setUser()
        }
    },
}
</script>

<style lang="scss" scoped>
.top{
    max-width: 400px!important;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: var(--primaryColor);
    color: rgb(243, 243, 243);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 400px) {
        margin-top: 0px;
        border-radius: 0px 0px 10px 10px;
    }
    .main{
        display: flex;
    }
    .left{
        width: 100px;
        height: 100px;
        background-size: cover;
        background-color: #fff;
        border-radius: 0px 0px 10px 0px;
    }
    .center{
        flex:1;
        display: flex;
        align-items: center;
        .data{    
            margin-left: 10px;
            margin-right: 10px;
            .nome{
                font-weight: 800;
                text-transform: uppercase;
                font-size: 20pt;
                line-height: 20px;
            }
            .figura{
                font-size: 11pt;
                font-weight: 600;
                line-height: 15px;
            }
            .email{
                font-size: 9pt;
            }
        }
    }
}
.data2{
    background-color: var(--primaryColor);
    color: rgb(243, 243, 243);
    font-size: 10pt;
    text-align: left;
    line-height: 14px;
    .field{
        margin-bottom: 5px;
        .tt{
            font-weight: 600;
        }
    }
}


.theme--light.v-expansion-panels .v-expansion-panel{
    background-color: var(--primaryColor);
}
.v-expansion-panel-header{
    height: 20px;
    min-height: 0px;
    padding: 5px;
    color: white!important;
    font-size: 6pt;
    font-weight: 800;
    text-transform: uppercase;
}
.v-expansion-panel-content{
    width: 400px!important;
}
.v-expansion-panel--active > .v-expansion-panel-header{
    min-height: 0px;
}

.v-expansion-panel::before{
    box-shadow: none;
}

</style>