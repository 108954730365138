import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    me:{},
    retrivedMe: false,
    retrivingMe:false,
    userToken: '',
    users: [],
    retrivedUsers: false,
    usersExecutive:[],
    usersTeamLeader:[],
    usersTopExecutive:[],
    usersAuctionManager:[],
}

const getters = {
    me: state => state.me,
    retrivedMe: state => state.retrivedMe,
    userToken: state => state.userToken,
    users: state => state.users,
    retrivedUsers: state => state.retrivedUsers,
    usersExecutive: state => state.usersExecutive,
    usersTeamLeader: state => state.usersTeamLeader,
    usersTopExecutive: state => state.usersTopExecutive,
    usersAuctionManager: state => state.usersAuctionManager,
}

const actions = {
    //async actions
    async login({commit,state},form){
        return new Promise((resolve,reject)=>{
            if(state.retrivingMe){
                return;
            }
            state.retrivingMe = true;
            axios.post(Server+'/user/login',{
                email: form.email,
                password: form.password 
            }).catch((error)=>{
                state.retrivingMe = false;
                reject(error.response.data);
            }).then(function (response){
                state.retrivingMe = false;
                commit("setMeFromLogin",response.data);
                resolve(response.data.user); 
            })
            
        })
    },
    async fetchUserFromToken({commit,state}){
        if(state.retrivingMe){
            return;
        }
        state.retrivingMe = true;
        const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
        var response = await axios.get(Server+'/user/getMeFromToken',config);
        commit("setMe",response.data)
    },
    async createUser({commit},data){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('form',JSON.stringify(data.form));
            if(data.files){
                data.files.forEach(file => {
                    toSend.append('file',file);
                });    
            }
            
            var config = {
                method: 'post',
                url: Server+'/user/addUser',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookie.get('adminToken')}`,
                    'email': data.form.email,
                },
                data : toSend
            };

            axios(config)
                .then(function (response) {
                    commit("addNewUser",response.data)
                    resolve(response.data.user);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        })
    },
    async adminGetAllUsers({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
        var response = await axios.get(Server+'/user/adminGetAllUsers',config);
        commit("adminGetAllUsersFun",response.data)
    },
    async adminEditUser({commit},user){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/user/adminEditUser',{
                user: user,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("adminEditUserFun",response.data);
                resolve(response.data.user); 
            })
        })
    },
    async adminDeleteUser({commit},id){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/user/adminDeleteUser',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("adminDeleteUserFun",response.data);
                resolve(response.data.deleted); 
            })
        })
    },
    async adminResetPassword({commit},id){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/user/adminResetPassword',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("adminResetPasswordFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async editDocumentUser({commit},data){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/documents/user/edit',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editDocumentUserFun",response.data);
                resolve(response.data); 
            })
        })
        
    },
    async addDocumentsUser({commit},documents){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('filesName',JSON.stringify(documents.filesName));
            toSend.append('id',documents.id);
            if(documents.files){
                    documents.files.forEach(file => {
                   toSend.append('file',file);
               });    
            }
            var config = {
                method: 'post',
                url: Server+'/documents/user/add',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookie.get('adminToken')}`,
                    'id': documents.id,
                },
                data : toSend
            };
            axios(config)
            .then(function (response) {
                commit('addDocumentsUserFun',response.data);
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
        
    },
    async removeDocumentUser({commit},data){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/documents/user/remove',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("removeDocumentUserFun",response.data);
                resolve(response.data); 
            })
        })
        
    },
    async userEditUser({commit},user){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
            axios.post(Server+'/user/userEditUser',{
                user: user,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("userEditUserFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async userEditPassword(context,data){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
            axios.post(Server+'/user/userEditPassword',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                resolve(response.data); 
            })
        })
    },
    async userChangePhoto({commit},photo){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('file',photo);
            var config = {
                method: 'post',
                url: Server+'/user/changePhoto',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookie.get('token')}`,
                },
                data : toSend
            };
            axios(config)
            .then(function (response) {
                commit('userChangePhotoFun',response.data);
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    }




}

const mutations = {
    setMeFromLogin(state,me){
        state.me = me.user;
        if(cookie.get('adminToken')){cookie.remove("adminToken");}
        cookie.set('token',me.token);
        state.userToken = me.token;
        state.retrivedMe = true;
        console.log('%cUser Fatched', 'color: #bada55');
    },
    LogoutUser(state){
        cookie.remove("token");
        state.me = {};
        state.userToken = '';
        state.retrivedMe = false;
        console.log('%cLogged Out', 'color: #bada55');
    },
    setMe(state,me){
        state.me = me.user;
        state.userToken = cookie.get('token');
        state.retrivedMe = true;
    },
    addNewUser(state,response){
        state.users = response.users;
        setFigureArrays(state);
    },
    adminGetAllUsersFun(state,users){
        state.users = users;
        state.retrivedUsers = true;
        setFigureArrays(state);
    },
    adminEditUserFun(state,response){
        state.users = response.users;
        setFigureArrays(state);
    },
    adminDeleteUserFun(state,response){
        state.users = response.users;
        setFigureArrays(state);
    },
    adminResetPasswordFun(state,response){
        console.log('Password Resettata: '+response.user.email);
    },
    editDocumentUserFun(state,response){
        const index = state.users.findIndex(user => user._id == response.user._id);
        if(index!=-1){
            state.users[index] = response.user
        }
    },
    addDocumentsUserFun(state,response){
        const index = state.users.findIndex(user=> user._id == response.user._id);
        if(index!=-1){
            state.users[index] = response.user;
        }
    },
    removeDocumentUserFun(state,response){
        const index = state.users.findIndex(user => user._id == response.user._id);
        if(index!=-1){
            state.users[index] = response.user
        }
    },
    userEditUserFun(state,response){
        const index = state.users.findIndex(user => user._id == response.user._id);
        if(index!=-1){
            state.users[index] = response.user;
        }
    },
    userChangePhotoFun(state,response){
        state.me.foto = response.photo;
    }
    
     
}

function setFigureArrays(state){
    state.usersExecutive = [];
    state.usersTeamLeader = [];
    state.usersTopExecutive = [];
    state.usersAuctionManager = [];
    state.users.forEach(user=>{
        if(user.figura == "Top Executive"){
            state.usersTopExecutive.push(user);
        }
        if(user.figura == "Executive"){
            state.usersExecutive.push(user);
        }
        if(user.figura == "Team Leader"){
            state.usersTeamLeader.push(user);
        }
        if(user.figura == "Auction Manager"){
            state.usersAuctionManager.push(user);
        }
    })
}



export default{
    state,
    getters,
    actions,
    mutations
}