<template>
    <div id="files">
        <div class="mainTitle">FILES</div>
        <div class="tabella elevation-9">
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left"> NOME </th>
                            <th class="text-left"> DATA </th>
                            <th class="text-left"> FORMATO </th>
                            <th class="text-left"> DIMENSIONI </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="documento in files" :key="documento._id">
                            <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ documento.nome }}</td>
                            <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ formatDateHour(documento.data) }}</td>
                            <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ getExt(documento.indirizzo) }}</td>
                            <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ bytesToSize(documento.size) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            
        }
    },
    computed: mapGetters(['files','retrivedfiles']),
    mounted() {
        if(!this.retrivedfiles){
            this.toogleLoading(true);
            this.fetchPublicFiles().then(()=>{this.toogleLoading(false);}).catch(()=>{this.toogleLoading(false);});
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['fetchPublicFiles']),
        removePoint(string){
            return string.split('.')[0]
        },
        bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        getExt(string){
            var str = string.split('.');
            return '.'+str[str.length-1];
        },
        linkto(link){
            window.open(link, '_blank');
        },
        formatDateHour(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            var ore = data.getHours()<10?'0'+data.getHours():data.getHours();
            var minuti = data.getMinutes()<10?'0'+data.getMinutes():data.getMinutes();
            return giorno+'/'+mese+'/'+data.getFullYear()+' - '+ore+':'+minuti;
        },
    }
}
</script>

<style lang="scss" scoped>
.tabella{
    max-width: 1000px;
    margin: 20px auto;
    border-radius: 15px;
    overflow: hidden;
}
</style>