<template>
    <div id="contactForm">
        <div class="contactForm elevation-9">
            <div class="titolo">{{testo?testo:'CONTATTACI'}}</div>
            <v-form @submit.prevent="send" v-model="isFormValid">
                <v-text-field v-model="form.email" label="Email *" :rules="emailRules" solo dense clearable hint="Email" prepend-inner-icon="mdi-email" ></v-text-field>
                <v-text-field v-model="form.nome" label="Nome" solo dense clearable hint="Nome" prepend-inner-icon="mdi-account-box" ></v-text-field>
                <v-text-field v-model="form.citta" label="Città di Provenienza" solo dense clearable hint="Città di Provenienza" prepend-inner-icon="mdi-city" ></v-text-field>
                <v-text-field v-model="form.cellulare" label="Telefono" solo dense clearable hint="Telefono" prepend-inner-icon="mdi-cellphone" ></v-text-field>


                <transition name="fade">
                    <div v-if="error" class="errore">{{error}}</div>
                </transition>
                <v-btn type="submit" block elevation="2" :loading="sending" :disabled="!isFormValid">
                    <v-icon left> mdi-send </v-icon>
                    INVIA
                </v-btn>
                <div class="privacyPolici"><router-link to="/privacy-policy">Privacy Policy</router-link> </div>
            </v-form>
            <transition name="fade">
            <div class="emailInviata" v-if="sended">
                <div class="centeredAbs">
                    <v-icon>mdi-check-circle</v-icon>
                    <div>EMAIL INVIATA</div>
                    <v-btn x-small  @click="reset()">invia di nuovo</v-btn>
                </div>
            </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Server } from '../../utils/variabili';
export default {
    props:['emailTo','testo'],
    data() {
        return {
            form:{
                email:'',
                nome:'',
                citta:'',
                cellulare:'',
            },
            error:'',
            sended:false,
            sending:false,
            isFormValid:false,
            emailRules: [
                v => !!v || 'E-mail è necessaria',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail non valida',
            ],
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
        }
    },
    methods: {
        send(){
            this.sending=true;
            this.error='';
            axios.post(Server+'/email/business',{
                form:this.form,
            }).then(()=>{
                this.sended=true;
                this.sending=false;
            }).catch((error)=>{
                console.log(error)
                this.error = 'Server momentaneamente offline riprova più tardi'
                this.sending=false;
            })
        },
        reset(){
            this.sending=false;
            this.sended=false;
            this.error='';
            this.form = {
                email:'',
                nome:'',
                testo:'',
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.contactForm{
    background: var(--primaryColor);
    width: 100%;
    max-width: 700px;
    padding: 10px 20px 20px 20px;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .titolo{
        font-size: 30pt;
        font-weight: 600;
        text-align: center;
        color: rgb(255, 255, 255);
    }
    .errore{
        color: white;
        font-weight: 700;
        background-color: rgb(230, 72, 72);
         border-radius: 5px;
        padding:2px 10px;
        font-size: 8pt;
        width: max-content;
        margin: 0 auto;
        margin-bottom: 5px;
    }
    .emailInviata{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;left:0;
        background: var(--primaryColor);
        .centeredAbs{
            position: absolute;
            width: max-content;
            text-align: center;
            top:50%;left:50%;
            transform: translateX(-50%) translateY(-50%);
            .v-icon{
                 color: white;
                font-size: 90pt;
            }
            div{
                font-size: 20pt;
                color: white;
                font-weight: 600;
            }
        }
    }
    .privacyPolici{
        font-size: 8pt;
        font-weight: 400;
        text-align: center;
        margin-top: 5px;
        a{
            color: rgba(255, 255, 255, 0.534);
        }
    }
}
.v-application--is-ltr .v-messages{
    color:white;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>