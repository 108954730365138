<template>
    <div id="adminhome">
        <div class="mainTitle">HOME</div>
        <div class="torte">
            <div class="ricerche">
                <div class="ricercaTitle">AGENTI</div>
                <div class="oggetti">
                    <router-link to="/admin/agenti?filtringFigura=Top Executive" class="buzz-out-on-hover">
                        <CircularChar :options="{width:userTortaWidth,font_size:userTortaFontSize,color:'primaryColor'}" :percent="Math.floor((usersTopExecutive.length*100)/users.length)" :count="usersTopExecutive.length" :name="'Top Executive'"/>
                    </router-link>
                    <router-link to="/admin/agenti?filtringFigura=Executive" class="buzz-out-on-hover">
                        <CircularChar :options="{width:userTortaWidth,font_size:userTortaFontSize,color:'primaryColor'}" :percent="Math.floor((usersExecutive.length*100)/users.length)" :count="usersExecutive.length" :name="'Executive'"/>
                    </router-link>
                    <router-link to="/admin/agenti?filtringFigura=Team Leader" class="buzz-out-on-hover">
                        <CircularChar :options="{width:userTortaWidth,font_size:userTortaFontSize,color:'primaryColor'}" :percent="Math.floor((usersTeamLeader.length*100)/users.length)" :count="usersTeamLeader.length" :name="'Team Leader'"/>
                    </router-link>
                    <router-link to="/admin/agenti?filtringFigura=Auction Manager" class="buzz-out-on-hover">
                        <CircularChar :options="{width:userTortaWidth,font_size:userTortaFontSize,color:'primaryColor'}" :percent="Math.floor((usersAuctionManager.length*100)/users.length)" :count="usersAuctionManager.length" :name="'Auction Manager'"/>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="incarichi" v-if="incarichiCounter.total>0">
            <div class="ricerche">
                <div class="ricercaTitle">INCARICHI</div>
                <div class="oggetti">
            <router-link to="/admin/incarichi?filtringStato=In Ricerca" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'inRicerca'}" :percent="Math.floor((incarichiCounter.inRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.inRicerca" :name="'In Ricerca'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Ricerca Sospesa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'ricercaSospesa'}" :percent="Math.floor((incarichiCounter.ricercaSospesa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaSospesa" :name="'Ricerca Sospesa'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Ricerca Conclusa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'ricercaConclusa'}" :percent="Math.floor((incarichiCounter.ricercaConclusa*100)/incarichiCounter.total)" :count="incarichiCounter.ricercaConclusa" :name="'Ricerca Conclusa'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=In Asta" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'primaryColor'}" :percent="Math.floor((incarichiCounter.inAsta*100)/incarichiCounter.total)" :count="incarichiCounter.inAsta" :name="'In Asta'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Aggiudicata" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'aggiudicata'}" :percent="Math.floor((incarichiCounter.aggiudicata*100)/incarichiCounter.total)" :count="incarichiCounter.aggiudicata" :name="'Aggiudicata'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Persa" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'persa'}" :percent="Math.floor((incarichiCounter.persa*100)/incarichiCounter.total)" :count="incarichiCounter.persa" :name="'Persa'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Rinuncia Ricerca" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-2,color:'rinunciaRicerca'}" :percent="Math.floor((incarichiCounter.rinunciaRicerca*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaRicerca" :name="'Rinuncia Ricerca'"/>
            </router-link>
            <router-link to="/admin/incarichi?filtringStato=Rinuncia Asta" class="buzz-out-on-hover">
                <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize-1,color:'rinunciaAsta'}" :percent="Math.floor((incarichiCounter.rinunciaAsta*100)/incarichiCounter.total)" :count="incarichiCounter.rinunciaAsta" :name="'Rinuncia Asta'"/>
            </router-link>
            </div>
            </div>
        </div>
        <div class="dati">
            <div class="ricerche">
                <div class="ricercaTitle">RICERCHE</div>
                <router-link to="/admin/incarichi?filtringRicerche=Non Pagate" class="buzz-out-on-hover">
                    <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'ricercaSospesa'}"
                    :percent="Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare))" :count="moneyFormat(incarichiStats.ricercheDaPagareVal)+'€'" :name="'Da Pagare'" :subb="incarichiStats.ricercheDaPagare"/>
                </router-link>

                <router-link to="/admin/incarichi?filtringRicerche=Pagate" class="buzz-out-on-hover">
                    <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'aggiudicata'}"
                    :percent="100-Math.floor((incarichiStats.ricercheDaPagare*100)/(incarichiStats.ricerchePagate+incarichiStats.ricercheDaPagare))"  :count="moneyFormat(incarichiStats.ricerchePagateVal)+'€'" :name="'Pagate'" :subb="incarichiStats.ricerchePagate"/>
                </router-link>

            </div>

            <div class="ricerche">
                <div class="ricercaTitle">PROVVIGIONI</div>
                <router-link to="/admin/incarichi?filtringProvvigioni=Non Pagate" class="buzz-out-on-hover">
                    <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'ricercaSospesa'}"
                    :percent="Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate))" :count="moneyFormat(incarichiStats.provvigioniDaPagareVal)+'€'" :name="'Da Pagare'" :subb="incarichiStats.provvigioniDaPagare"/>
                </router-link>

                <router-link to="/admin/incarichi?filtringProvvigioni=Pagate" class="buzz-out-on-hover">
                    <CircularChar :options="{width:incaricoTortaWidth,font_size:incaricoTortaFontSize,color:'aggiudicata'}"
                    :percent="100-Math.floor((incarichiStats.provvigioniDaPagare*100)/(incarichiStats.provvigioniDaPagare+incarichiStats.provvigioniPagate))"  :count="moneyFormat(incarichiStats.provvigioniPagateVal)+'€'" :name="'Pagate'" :subb="incarichiStats.provvigioniPagate"/>
                </router-link>

            </div>
            
        </div>
        
        <div class="grafico ricerche">
            <div class="ricercaTitle">IMMOBILI (14Giorni)</div>
            <router-link to="/admin/immobili">
            <v-sparkline
                v-if="loadGrafico"
                :value="value"
                color="rgb(0,102,153)"
                height="100"
                padding="5"
                stroke-linecap="round"
                smooth
                >
                <template v-slot:label="item">
                    {{item.value}}
                </template>
    
            </v-sparkline>
            </router-link>
        </div>
        <div class="ricerche immobili">
            <div class="ricercaTitle">IMMOBILI INFO</div>
            <div class="ricercaInfo">
                <div class="riceFlex">
                    <div class="flexxedItem elevation-9">
                        <div class="name">Dati</div>
                        <div class="fieldContent">
                            <div class="field">Quantità: {{immobili.length}}</div>
                            <router-link :to="'/immobili/'+immobiliInfo.minPrezzoRef">
                                <div class="field">Prezzo Minimo: {{moneyFormat(immobiliInfo.minPrezzo)}}€</div>
                            </router-link>
                            <router-link :to="'/immobili/'+immobiliInfo.maxPrezzoRef">
                                <div class="field">Prezzo Massimo: {{moneyFormat(immobiliInfo.maxPrezzo)}}€</div>    
                            </router-link>
                            <router-link :to="'/immobili/'+immobiliInfo.minMqRef">
                                <div class="field">Mq Minimo: {{moneyFormat(immobiliInfo.minMq)}}</div>
                            </router-link>
                            <router-link :to="'/immobili/'+immobiliInfo.maxMqRef">
                                <div class="field">Mq Massimi: {{moneyFormat(immobiliInfo.maxMq)}}</div>    
                            </router-link>
                            <router-link to="/immobili?filterOfferte=Si">
                                <div class="field">Offerte Imperdibili: {{immobiliInfo.offerte}}</div>
                            </router-link>
                              
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Regioni</div>
                        <div class="fieldContent">
                            <router-link :to="'/immobili?filterRegione='+regione.nome" v-for="(regione,index) in immobiliInfo.regioni" :key="index">
                                <div class="field">
                                    {{regione.nome}} ({{regione.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Comuni</div>
                        <div class="fieldContentScrollable">
                            <router-link :to="'/immobili?ricercaComune='+comune.nome" v-for="(comune,index) in immobiliInfo.comune" :key="index">
                                <div class="field">
                                    {{comune.nome}} ({{comune.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Categorie</div>
                        <div class="fieldContentScrollable">
                            <router-link :to="'/immobili?filterCategoria='+categoria.nome" v-for="(categoria,index) in immobiliInfo.categorie" :key="index">
                                <div class="field">
                                    {{categoria.nome}} ({{categoria.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ricerche immobili">
            <div class="ricercaTitle">AGENCY INFO</div>
            <div class="ricercaInfo">
                <div class="riceFlex">
                    <div class="flexxedItem elevation-9">
                        <div class="name">Dati</div>
                        <div class="fieldContent">
                            <div class="field">Quantità: {{agency.length}}</div>
                            <router-link :to="'/agency/'+agencyInfo.minPrezzoRef">
                                <div class="field">Prezzo Minimo: {{moneyFormat(agencyInfo.minPrezzo)}}€</div>    
                            </router-link>
                            <router-link :to="'/agency/'+agencyInfo.maxPrezzoRef">
                                <div class="field">Prezzo Massimo: {{moneyFormat(agencyInfo.maxPrezzo)}}€</div>
                            </router-link>
                            <router-link :to="'/agency/'+agencyInfo.minMqRef">
                                <div class="field">Mq Minimo: {{moneyFormat(agencyInfo.minMq)}}</div>
                            </router-link>
                            <router-link :to="'/agency/'+agencyInfo.maxMqRef">
                                <div class="field">Mq Massimi: {{moneyFormat(agencyInfo.maxMq)}}</div> 
                            </router-link>
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Regioni</div>
                        <div class="fieldContent">
                            <router-link :to="'/agency?filterRegione='+regione.nome" v-for="(regione,index) in agencyInfo.regioni" :key="index">
                                <div class="field">
                                    {{regione.nome}} ({{regione.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Comuni</div>
                        <div class="fieldContentScrollable">
                            <router-link :to="'/agency?ricercaComune='+comune.nome" v-for="(comune,index) in agencyInfo.comune" :key="index">
                                <div class="field">
                                    {{comune.nome}} ({{comune.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="flexxedItem flexxedItem elevation-9">
                        <div class="name">Categorie</div>
                        <div class="fieldContentScrollable">
                            <router-link :to="'/agency?filterCategoria='+categoria.nome" v-for="(categoria,index) in agencyInfo.categorie" :key="index">
                                <div class="field">
                                    {{categoria.nome}} ({{categoria.quantita}})
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]
import { mapActions, mapGetters } from 'vuex'
import CircularChar from '../../components/admin/circularChar.vue'
export default {
    components:{CircularChar},
    computed: mapGetters(['users','retrivedUsers','usersExecutive','usersTeamLeader','usersTopExecutive','usersAuctionManager','incarichiCounter','retrivedIncarichi','incarichiStats','immobili','agency','immobiliInfo','agencyInfo','retrivedImmobili','immobiliStorico','retrivedImmobiliStorico']),
    data() {
        return {
            userTortaWidth:'250',
            userTortaFontSize:'20',
            incaricoTortaWidth:'150',
            incaricoTortaFontSize:'14',

            loadGrafico:false,
            width: 2,
            radius: 10,
            padding: 5,
            lineCap: 'round',
            gradient: gradients[5],
            value: [],
            gradientDirection: 'top',
            gradients,
            fill: true,
            type: 'trend',
            autoLineWidth: true,
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['adminGetAllUsers','fetchAllIncarichiFromAdmin','fetchAllImmobili','fetchImmobiliStorico']),
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        setupGrafico(){
            var x=this.immobiliStorico.slice(0,14).length-1;
            while(x>=0){
                this.value.push(this.immobiliStorico[x].quantita);
                x--;
            }
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
        },
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        }
    },
    mounted() {
        if(!this.retrivedUsers){
            this.toogleLoading(true)
            this.adminGetAllUsers().then(()=>{this.toogleLoading(false)});
        }
        if(!this.retrivedIncarichi){
            this.toogleLoading(true)
            this.fetchAllIncarichiFromAdmin().then(()=>{this.toogleLoading(false);});
        }
        if(!this.retrivedImmobili){
            this.toogleLoading(true)
            this.fetchAllImmobili().then(()=>{this.toogleLoading(false);});
        }
        if(!this.retrivedImmobiliStorico){
            this.toogleLoading(true)
            this.fetchImmobiliStorico().then(()=>{
                this.setupGrafico();
                this.loadGrafico = true;
                this.toogleLoading(false);
            });
        }else{
            this.setupGrafico();
            this.loadGrafico = true;
        }
    },
}
</script>


<style lang="scss" scoped>
#adminhome{
    text-align: center;
    padding-bottom: 100px;
}
.torte{
    max-width: max-content;
    margin: 0 auto;
}
.incarichi{
    max-width: max-content;
    margin: 0 auto;
    margin-top: 10px;
}
.dati{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: max-content;
    margin: 10px auto;
    column-gap: 20px;
    row-gap: 20px;
    
}

.ricerche{

    border: 1px solid rgb(230, 230, 230);;
    padding: 15px 10px 10px 10px;
    background-color: rgb(230, 230, 230);
    border-radius: 20px;
    position: relative;
    .ricercaTitle{
        position: absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        font-weight: 700;
        color: rgb(117, 117, 117);
        letter-spacing: 1px;
    }
    .oggetti{
        display: flex;
        flex-flow: nowrap;
        overflow-x: auto;
        padding:10px;
        //-webkit-overflow-scrolling: touch;
        /* width */
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(117, 117, 117);
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.grafico{
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    transition-duration: 0.2s;
    &:hover{    
        box-shadow: 0 5px 6px -3px rgba(0,0,0,.2),0 9px 12px 1px rgba(0,0,0,.10),0 3px 16px 2px rgba(0,0,0,.08)!important;
        transition-duration: 0.2s;
    }
}

.immobili{
    margin: 20px auto;
    padding: 20px;
    max-width: max-content;
    .ricercaInfo{
        margin-top: 10px;
        .riceFlex{
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            .flexxedItem{
                background: white;
                padding:10px 20px 20px 20px;
                border-radius: 15px;
                flex:auto;
                .name{
                    font-weight: 700;
                    color:#666;
                }
                .fieldContentScrollable{
                    max-height: 200px;
                    overflow: auto;
                    padding-left: 20px;
                    padding-right: 10px;
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: rgb(117, 117, 117);
                        border-radius: 10px;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                    
                }
                a{
                    color: rgb(41, 41, 41);
                    font-weight: 500;
                    &:hover{
                        color: var(--primaryColor);
                    }
                }
                .field{
                    font-size: 10.5pt;
                }
            }
        }
    }    
}



</style>