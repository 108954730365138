var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"creaEvento"}},[_c('div',{staticClass:"mainTitle"},[_vm._v("MODIFICA EVENTO")]),_c('v-form',{staticClass:"form elevation-9",on:{"submit":function($event){$event.preventDefault();return _vm.modificaEvento.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"rules":_vm.requiredRules,"label":"Titolo","dense":"","solo":""},model:{value:(_vm.form.titolo),callback:function ($$v) {_vm.$set(_vm.form, "titolo", $$v)},expression:"form.titolo"}}),_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","label":"Descrizione","hint":"Descrizione","dense":"","solo":""},model:{value:(_vm.form.descrizione),callback:function ($$v) {_vm.$set(_vm.form, "descrizione", $$v)},expression:"form.descrizione"}}),_c('v-switch',{staticStyle:{"margin-top":"-5px"},attrs:{"inset":"","label":_vm.form.allDay?'Evento giornaliero':'Evento a tempo'},model:{value:(_vm.form.allDay),callback:function ($$v) {_vm.$set(_vm.form, "allDay", $$v)},expression:"form.allDay"}}),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.form.allDay?12:6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRules,"label":_vm.form.allDay?'Data':'Data Inizio',"prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","solo":"","clearable":""},model:{value:(_vm.form.dataInizio),callback:function ($$v) {_vm.$set(_vm.form, "dataInizio", $$v)},expression:"form.dataInizio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDataInizio),callback:function ($$v) {_vm.menuDataInizio=$$v},expression:"menuDataInizio"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDataInizio = false}},model:{value:(_vm.form.dataInizio),callback:function ($$v) {_vm.$set(_vm.form, "dataInizio", $$v)},expression:"form.dataInizio"}})],1)],1),(!_vm.form.allDay)?_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.timeOraInizio,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.timeOraInizio=$event},"update:return-value":function($event){_vm.timeOraInizio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRules,"label":"Ora Inizio","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","solo":"","dense":"","clearable":""},model:{value:(_vm.form.oraInizio),callback:function ($$v) {_vm.$set(_vm.form, "oraInizio", $$v)},expression:"form.oraInizio"}},'v-text-field',attrs,false),on))]}}],null,false,1640631417),model:{value:(_vm.menuOraInizio),callback:function ($$v) {_vm.menuOraInizio=$$v},expression:"menuOraInizio"}},[(_vm.menuOraInizio)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.timeOraInizio)}},model:{value:(_vm.form.oraInizio),callback:function ($$v) {_vm.$set(_vm.form, "oraInizio", $$v)},expression:"form.oraInizio"}}):_vm._e()],1)],1):_vm._e()],1),(!_vm.form.allDay)?_c('v-row',{staticStyle:{"margin-top":"-30px"}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRules,"label":'Data Fine',"prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","solo":"","clearable":""},model:{value:(_vm.form.dataFine),callback:function ($$v) {_vm.$set(_vm.form, "dataFine", $$v)},expression:"form.dataFine"}},'v-text-field',attrs,false),on))]}}],null,false,143482901),model:{value:(_vm.menuDataFine),callback:function ($$v) {_vm.menuDataFine=$$v},expression:"menuDataFine"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDataFine = false}},model:{value:(_vm.form.dataFine),callback:function ($$v) {_vm.$set(_vm.form, "dataFine", $$v)},expression:"form.dataFine"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.timeOraFine,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.timeOraFine=$event},"update:return-value":function($event){_vm.timeOraFine=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.requiredRules,"label":"Ora Fine","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","solo":"","dense":"","clearable":""},model:{value:(_vm.form.oraFine),callback:function ($$v) {_vm.$set(_vm.form, "oraFine", $$v)},expression:"form.oraFine"}},'v-text-field',attrs,false),on))]}}],null,false,3322742073),model:{value:(_vm.menuOraFine),callback:function ($$v) {_vm.menuOraFine=$$v},expression:"menuOraFine"}},[(_vm.menuOraFine)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.timeOraFine)}},model:{value:(_vm.form.oraFine),callback:function ($$v) {_vm.$set(_vm.form, "oraFine", $$v)},expression:"form.oraFine"}}):_vm._e()],1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"block":"","color":"orange","type":"submit","disabled":!_vm.isFormValid}},[_vm._v(" MODIFICA ")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" CANCELLA ")])]}}])},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Cancellare Evento")])]),_c('v-card-text',[_vm._v(" Sei sicuro di voler "),_c('b',{staticClass:"redText"},[_vm._v("cancellare")]),_vm._v(" il video formazione "),_c('br'),_vm._v("( "+_vm._s(_vm.eventoRetrived.titolo)+" )? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.cancella(_vm.eventoRetrived._id)}}},[_vm._v(" CANCELLA ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }