<template>
    <div id="settings">
        <div class="editForm elevation-9">
            <v-form @submit.prevent="submit" v-model="isFormValid">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.nome" :rules="requiredRules" label="Nome *" required clearable outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="noMtOnLow">
                        <v-text-field v-model="form.cognome" :rules="requiredRules" label="Cognome *" required clearable outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field v-model="form.email" :rules="emailRules" label="Email *" required clearable outlined dense></v-text-field>
                <v-select v-model="form.figura" :rules="requiredRules" :items="figure" label="Figura *" required outlined dense></v-select>
                <v-text-field v-model="form.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>
                
                <v-dialog ref="dialog" v-model="modal1" :return-value.sync="form.dataContr" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form.dataContr" label="Data Contratt." readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="form.dataContr" scrollable >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(form.dataContr)" > OK </v-btn>
                    </v-date-picker>
                </v-dialog>

                <div class="divisore">
                    <v-divider></v-divider>
                    <div class="divisoreTitolo">UFFICIO</div>
                </div>
                
                <v-text-field v-model="form.ufficio.via" label="Indirizzo"  clearable outlined dense></v-text-field>
                <v-row>   
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.ufficio.comune" label="Comune"  clearable outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="noMtOnLow">
                        <model-select  class="mb-20" :options="provincieOptions"
                        v-model="form.ufficio.provincia"
                        placeholder="Provincia">
                        </model-select>
                    </v-col>
                </v-row>
                <v-row style="margin-top: -22px">
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.ufficio.cap" label="CAP"  clearable outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="noMtOnLow">
                        <model-select class="mb-20" :options="regioniOptions" v-model="form.ufficio.regione" placeholder="Regione"></model-select>
                    </v-col>
                </v-row>
                <div class="divisore">
                    <v-divider></v-divider>
                    <div class="divisoreTitolo">SISTEMA</div>
                </div>
                <model-select class="mb-20" :options="topExecutiveOptions" v-model="form.topExecutive" placeholder="Top Executive"></model-select>
                <model-select class="mb-20" :options="executiveOptions" v-model="form.executive" placeholder="Executive"></model-select>
                <model-select class="mb-20" :options="teamLeaderOptions" v-model="form.teamLeader" placeholder="Team Leader"></model-select>
                <v-btn block color="orange" type="submit" :disabled="!isFormValid">
                    MODIFICA
                </v-btn>
            </v-form>
            
        </div>
        <div class="buttons">
            <v-dialog v-model="dialog_resettaP" width="500" >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" > Resetta Password </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-h5"> SEI SICURO? </v-card-title>
                    <v-card-text>
                        <b class="redText">Resettare la password</b> implica che l'agente avrà la possibilità di eseguire il login tramite la sua email e la password settata come <u>nome+cognome</u>  (altamente fragile)
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dialog_resettaP = false" > Annulla </v-btn>
                        <v-btn color="error" @click="resettaPassword" > RESETTA </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_cancella" width="500" >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" dark v-bind="attrs" v-on="on" > Cancella </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-h5"> SEI SICURO? </v-card-title>
                    <v-card-text>
                        <b class="redText">Cancellare</b> implica che non solo l'agente verrà cancellato, ma tutti i vari incarichi relativi saranno senza "titolare"
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dialog_cancella = false" > Annulla </v-btn>
                        <v-btn color="error" @click="cancella" > CANCELLA </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select';
import { mapActions,mapGetters } from 'vuex';
import ProvincieJson from '../../../utils/provincie.json'
import RegioniJson from '../../../utils/regioni.json'
export default {
    props:['user'],
    components:{ModelSelect},
    data() {
        return {
            modal1:false,
            figure:["Head Executive","Top Executive","Executive","Team Leader","Auction Manager"],
            isFormValid:false,
            provincieOptions:[],
            regioniOptions:[],
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            emailRules: [
                v => !!v || 'E-mail è necessaria',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail non valida',
            ],
            topExecutiveOptions:[],
            executiveOptions:[],
            teamLeaderOptions:[],
            dialog_resettaP:false,
            dialog_cancella:false,
            form:{
                _id:null,
                email:null,
                nome:null,
                cognome:null,
                cellulare:null,
                dataContr:null,
                ufficio:{
                    via:null,
                    comune:null,
                    provincia:null,
                    cap:null,
                    regione:null,
                },
                figura:null,
                topExecutive:undefined,
                executive:undefined,
                teamLeader:undefined,
            }

        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['usersExecutive','usersTeamLeader','usersTopExecutive']),
    watch:{
        user(){
            this.toogleLoading(true)
            this.loadProvincie();
            this.loadRegioni();
            this.loadTopExectuvie();
            this.loadExecutive();
            this.loadTeamLeader();
            this.setupForm();
            this.toogleLoading(false)
        }
    },
    methods: {
        ...mapActions(['adminEditUser','adminDeleteUser','adminResetPassword']),
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        loadRegioni(){
            RegioniJson.forEach(regione => {
                this.regioniOptions.push({
                    value: regione.nome,
                    text: regione.nome,
                })
            });
        },
        loadTopExectuvie(){
            this.usersTopExecutive.forEach(user=>{
                if(user.email != this.user.email){
                this.topExecutiveOptions.push({
                    value: user._id,
                    text: user.email,
                })}
            })
        },
        loadExecutive(){
            this.usersExecutive.forEach(user=>{
                if(user.email != this.user.email){
                this.executiveOptions.push({
                    value: user._id,
                    text: user.email,
                })}
            })
        },
        loadTeamLeader(){
            this.usersTeamLeader.forEach(user=>{
                if(user.email != this.user.email){
                this.teamLeaderOptions.push({
                    value: user._id,
                    text: user.email,
                })}
            })
        },
        submit(){
            this.toogleLoading(true)
            this.adminEditUser(this.form).then(()=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Utente:',
                    text: 'Riuscita',
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Utente:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        },
        resettaPassword(){
            this.dialog_resettaP = false;
            this.toogleLoading(true)
            this.adminResetPassword(this.user._id).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Reset Password:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(()=>{this.toogleLoading(false)});
        },
        cancella(){
            this.dialog_cancella = false;
            this.toogleLoading(true)
            this.adminDeleteUser(this.user._id).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Utente Cancellato:',
                    text: response.email,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$router.push('/admin/agenti');
            }).catch(()=>{this.toogleLoading(false)});
        },
        setupForm(){
            this.form._id = this.user._id;
            this.form.nome = this.user.nome;
            this.form.cognome = this.user.cognome;
            this.form.email = this.user.email;
            this.form.figura = this.user.figura;
            this.form.cellulare = this.user.cellulare;
            this.form.dataContr = this.user.dataContr;
            this.form.ufficio = {
                via: this.user.ufficio.via,
                comune: this.user.ufficio.comune,
                provincia: this.user.ufficio.provincia,
                cap: this.user.ufficio.cap,
                regione: this.user.ufficio.regione,
            };
            this.form.topExecutive = this.user.topExecutive?this.user.topExecutive._id:undefined;
            this.form.executive = this.user.executive?this.user.executive._id:undefined;
            this.form.teamLeader = this.user.teamLeader?this.user.teamLeader._id:undefined;
        }
    },
    mounted() {
        if(Object.keys(JSON.parse(JSON.stringify(this.user))).length != 0){
            this.loadProvincie();
            this.loadRegioni();
            this.loadTopExectuvie();
            this.loadExecutive();
            this.loadTeamLeader();
            this.setupForm();
        }
    }
}
</script>

<style lang="scss" scoped>
.editForm{
    margin-top: 10px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    border-radius: 15px;
}
.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.buttons{
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 140px;
    width: max-content;
    button{
        margin: 5px;
    }
}

.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}
</style>