import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    accessi:[],
    retrivedAccessi:false,
}

const getters = {
    accessi: state => state.accessi,
    retrivedAccessi: state => state.retrivedAccessi,
}

const actions = {
    async fetchAllAccessi({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
        var response = await axios.get(Server+'/accessi/fetchAll',config);
        commit("fetchAllAccessiFun",response.data)
    }
}

const mutations = {
    fetchAllAccessiFun(state,response){
        state.accessi = response.accessi;
        state.retrivedAccessi = true;
    }
}



export default{
    state,
    getters,
    actions,
    mutations
}