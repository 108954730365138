<template>
    <div id="provvigionePagata">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="inAsta" v-bind="attrs" v-on="on" > PROVVIGIONE PAGATA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 inAsta lighten-2"> PAGARE LA PROVVIGIONE? </v-card-title>
                <v-card-text>
                    <v-form ref="form" class="form">
                        <div class="fields">

                            <v-dialog ref="dialog" v-model="modal1" :return-value.sync="incarico.provvigione.dataPagamento" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="incarico.provvigione.dataPagamento" label="Data Pagamento *" readonly v-bind="attrs" v-on="on" clearable outlined dense required></v-text-field>
                                </template>
                                <v-date-picker v-model="incarico.provvigione.dataPagamento" scrollable >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog.save(incarico.provvigione.dataPagamento)" > OK </v-btn>
                                </v-date-picker>
                            </v-dialog>

                        </div>
                        <v-divider></v-divider>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn depressed color="inAsta" @click="ProvvigionePagata()" > PAGA </v-btn>
                            <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>

                

                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
    data() {
        return {
            dialog:false,
            modal1:false,
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['provvigionePagataIncaricoAction']),
        ProvvigionePagata(){
            if(!this.incarico.provvigione.dataPagamento){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Pagamento Provvigione:',
                    text: 'La data è necessaria',
                    duration: 2000,
                    type: 'error'
                });
            }
            this.toogleLoading(true)
            var data = {id:this.incarico._id,data:this.incarico.provvigione.dataPagamento};
            this.provvigionePagataIncaricoAction(data).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Pagamento Provvigione:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.dialog = false;
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Pagamento Provvigione:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.dialog = false;
                this.toogleLoading(false)
            })

        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 0px;
}
.form{

    .fields{
        padding: 20px;
    }
}
</style>