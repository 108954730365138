<template>
    <div id="documenti">
        <div class="input elevation-9">
            <v-form class="form" @submit.prevent="loadDocuments">
                <div class="flexed">
                    <v-file-input multiple label="Carica" v-model="inputFiles" @change="fileSelected" class="input" ></v-file-input>
                    <div class="bottone">
                        <v-btn class="mx-2" small fab dark color="green" type="submit" > <v-icon dark> mdi-plus </v-icon> </v-btn>    
                    </div>
                </div>
                <div class="files">
                    <div class="file" v-for="(file,index) in files" :key="index">

                        <div class="icon">
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-icon class="orangeColor" v-on="on"> mdi-cloud-upload </v-icon>
                                </template>
                                <span>Da Caricare</span>
                            </v-tooltip>
                        </div>
                        
                        <v-text-field dense v-model="file.nome"></v-text-field>
                        <div class="size">[{{bytesToSize(file.size)}}]</div>
                        
                    </div>
                </div>
                
            </v-form>
        </div>
        <div class="tabella elevation-9">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left"> NOME </th>
                        <th class="text-left"> FORMATO </th>
                        <th class="text-left"> DIMENSIONI </th>
                        <th class="text-left"> AZIONI </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="documento in documenti" :key="documento._id">
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ documento.nome }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ getExt(documento.indirizzo) }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ bytesToSize(documento.size) }}</td>
                        <td>
                            <v-dialog v-model="documento.modalEdit" width="500" >
                                
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" > mdi-pencil </v-icon>
                                    </template>
                                    
                                <v-card>
                                    <v-card-title><span class="text-h5">Modifica Documento</span></v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-form @submit.prevent="edit(documento)">
                                                <v-text-field style="display:none" dense v-model="documento._id" label="ID" required disabled></v-text-field>
                                                <v-text-field dense v-model="documento.newName" label="NOME" required></v-text-field>
                                                <v-btn block color="orange" type="submit"> Modifica </v-btn>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>


                            <v-dialog width="500" >
                                
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="error" dark v-bind="attrs" v-on="on" > mdi-delete </v-icon>
                                    </template>
                                    
                                <v-card>
                                    <v-card-title><span class="text-h5">Cancella Documento</span></v-card-title>
                                    <v-card-text>
                                        Sei sicuro di voler <b class="redText">cancellare</b> il documento <br>( {{documento.nome}} )?
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cancella(documento)" > CANCELLA </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
    data() {
        return {
            modalDelete:false,
            documenti:[],
            files: [],
            inputFiles: undefined,
        }
    },
    inject: ['toogleLoading'],
    watch:{
        incarico(){
            this.documenti = JSON.parse(JSON.stringify(this.incarico.documenti));
            this.addModal(this.documenti);
        }
    },
    methods: {
        ...mapActions(['editIncaricoDocument','addIncaricoDocument','deleteIncaricoDocument']),
        fileSelected(e){
            this.files = [];
            e.forEach(file => {
                this.files.push({
                    nome: this.removePoint(file.name),
                    size: file.size,
                })
            });
        },
        removePoint(string){
            return string.split('.')[0]
        },
        bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        getExt(string){
            var str = string.split('.');
            return '.'+str[str.length-1];
        },linkto(link){
            window.open(link, '_blank');
        },edit(documento){
            this.toogleLoading(true)
            var data = {
                incarico: this.incarico._id,
                document: documento._id,
                nome: documento.newName
            }
            this.editIncaricoDocument(data).then((response)=>{
                documento.nome = data.nome;
                const index = this.incarico.documenti.findIndex(doc => doc._id == data.document);
                this.incarico.documenti[index].nome = data.nome;
                this.$notify({
                    group: 'baseNotification',
                    title: 'Documenti',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$parent.$parent.$parent.$parent.forceRerender();
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Incarico:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
            documento.modalEdit = false;
        },
        cancella(documento){
            this.toogleLoading(true)
            var data = {
                incarico: this.incarico._id,
                document: documento._id,
                indirizzo: documento.indirizzo,
            }
            this.deleteIncaricoDocument(data).then((response)=>{
                this.documenti = JSON.parse(JSON.stringify(response.incarico.documenti));
                this.addModal(this.documenti);
                this.$notify({
                    group: 'baseNotification',
                    title: 'Documenti',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Documento:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
            documento.modalDelete = false;
        },
        addModal(documenti){
            documenti.forEach(documento => {
                documento.modalEdit = false;
                documento.modalDelete = false;
                documento.newName = documento.nome;
            });
        },
        loadDocuments(){
            if(this.files.length==0){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Errore Caricamento',
                    text: 'Nessun file selezionato',
                    duration: 2000,
                    type: 'error'
                });
            }
            this.toogleLoading(true)
            this.addIncaricoDocument({filesName:this.files,files:this.inputFiles,incarico:this.incarico}).then((response)=>{
                this.documenti = JSON.parse(JSON.stringify(response.incarico.documenti));
                this.addModal(this.documenti);
                this.inputFiles = undefined;
                this.files= [];
                this.$notify({
                    group: 'baseNotification',
                    title: 'Documenti',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Caricamento Documento:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        }
    },
}
</script>
<style lang="scss" scoped>
.tabella{
    max-width: 1000px;
    margin: 20px auto;
    border-radius: 15px;
    overflow: hidden;
}
.input{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 20px;
    padding: 10px;
}
.bottone{
    min-height: 100%;
    display: flex;
    align-items: center;
}
.flexed{
    display: flex;
}
.file{
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
}
.icon{
    width: 30px;
    margin-top: 5px;
}
.size{
    margin-top: 8px;
    margin-left: 5px;
    font-size: 10pt;
}

</style>