<template>
    <router-link :to="'/admin/agenti/'+incarico.agente._id" v-if="incarico.agente">
        <div class="agente">
            <div class="img" v-if="incarico.agente.foto" :style="'background-image: url(\''+incarico.agente.foto+'\')'"></div>
            <div class="img" v-if="!incarico.agente.foto" :style="'background-image: url(\''+require('../../assets/imgs/empty-profile.png')+'\')'"></div>
            <div class="nome">{{incarico.agente.nome}} {{incarico.agente.cognome}}</div>
        </div>
    </router-link>
</template>

<script>
export default {
    props:['incarico']
}
</script>

<style lang="scss" scoped>
.agente{
    border: 1px solid rgb(77, 77, 77);
    border-radius: 15px;
    color: rgb(77, 77, 77);
    background:white;
    transition-duration: 0.2s;
    display: flex;
    width: max-content;
    align-items: center;
    overflow: hidden;
    .img{
        width: 30px;
        height: 30px;
        background-size: contain;
        background-color: white;
    }
    .nome{
        font-size: 9pt;
        padding: 0px 5px 0px 3px;
    }
    &:hover{

        transition-duration: 0.2s;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12)!important
    }
}
</style>