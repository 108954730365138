<template>
    <div id="business">
        <div class="top">
            <img :src="require('../../assets/imgs/business.svg')" alt="">
            <div class="titolo">Possibilità di BUSINESS</div>
        </div>
        <div class="firstText">
            Grazie al nostro supporto avrai la possibilità di essere il primo <b>Point AggiudicaRe nella tua città</b>. Avrai un'esclusiva territoriale ogni 100.000 abitanti sul nostro marchio che ti permetterà di assicurarti una fetta di mercato e diventare un <b>punto di riferimento</b>  nel settore delle <b>esecuzioni immobiliari!</b> 
        </div>
        <div class="sectionText elevation-9">🎁 COSA OFFRIAMO?</div>
        <div v-if="loaded" class="cosaOffiramoContainer">
            <div class="leftArrow" @click="scrollLeft()"><v-icon large>mdi-arrow-left-drop-circle-outline</v-icon></div>
            <div class="rightArrow" @click="scrollRight()"><v-icon large>mdi-arrow-right-drop-circle-outline</v-icon></div>
            <div class="cosaOffriamo" id="cosaOffriamo" v-dragscroll="!isTouch">
                <div class="blocco">
                    <div class="element elevation-9">MARCHIO A LIVELLO NAZIONALE</div>
                    <div class="element elevation-9">MODULISTICA</div>
                    <div class="element elevation-9">GESTIONE A LIVELLO NAZIONALE SPECIFICO DELLE ASTE</div>    
                </div>
                <div class="blocco">
                    <div class="element elevation-9">FORMAZIONE COSTANTE</div>
                    <div class="element elevation-9">LEAD</div>
                    <div class="element elevation-9">KNOW-HOW</div>    
                </div>
                <div class="blocco">
                    <div class="element elevation-9">ASSISTENZA E SUPPORTO</div>
                    <div class="element elevation-9">RE-STAT</div>
                    <div class="element elevation-9">
                        SERVIZIO FINANZIARIO
                        <img :src="require('../../assets/loghiAziende/affida.png')" alt="affida_logo">
                    </div>    
                </div>
                <div class="blocco">
                    <div class="element elevation-9">
                        <img :src="require('../../assets/loghiAziende/vivoqui.png')" alt="vivoqui_logo">
                    </div>
                    <div class="element elevation-9">ACCORDI COMMERCIALI</div>
                    <div class="element elevation-9"><img :src="require('../../assets/loghiAziende/alleare.png')" alt="alleare_logo"></div>   
                </div>
                <div class="blocco">
                    <div class="element elevation-9">GESTIONE AZIENDA</div>
                    <div class="element elevation-9">COSTUMER CARE</div>    
                </div>
                
                
                
                
            </div>
        </div>
        <div class="sectionText elevation-9">⚙️ IL PROCESSO</div>
        <v-timeline :align-top="$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown" class="timeLine" >
            <v-timeline-item color="primaryBG" icon="mdi-numeric-1" fill-dot >
                <div class="contenuto">
                    <v-alert v-if="$vuetify.breakpoint.smAndDown" :value="true" color="primaryBG" class="white--text processoCard" >
                        <div class="tu dense">TU</div>
                        <b>Individui la location</b> e ci invii 5 foto.
                    </v-alert>
                    <v-alert :value="true" color="primaryBG" class="white--text processoCard" >
                        <div class="noi">NOI</div>
                        Ti forniamo una prima <b>valutazione grautita e senza impegno dell'ufficio</b> che hai individuato.
                    </v-alert>
                </div>
                <v-alert v-if="!$vuetify.breakpoint.smAndDown" :value="true" slot="opposite" color="primaryBG" class="white--text processoCard oppositeCard" >
                     <div class="tu">TU</div>
                    <b>Individui la location</b> e ci invii 5 foto.
                </v-alert>
                
            </v-timeline-item>

            <v-timeline-item color="primaryBG" icon="mdi-numeric-2" fill-dot >
                <v-alert v-if="$vuetify.breakpoint.smAndDown" :value="true" color="primaryBG" class="white--text processoCard" >
                    <div class="tu dense">TU</div>
                    Partecipi al nostro <b>corso di formazione</b>.
                </v-alert>
                <v-alert v-if="!$vuetify.breakpoint.smAndDown" :value="true" color="primaryBG" class="white--text processoCard oppositeCard" >
                    <div class="tu">TU</div>
                    Partecipi al nostro <b>corso di formazione</b>.
                </v-alert>

                <v-alert v-if="$vuetify.breakpoint.smAndDown" :value="true" color="primaryBG" class="white--text processoCard" >
                     <div class="noi">NOI</div>
                    <b>Elaboriamo il materiale pubblicitario</b> per l'innaugurazione dell'attività e un <b>nostro tutor ti seguirà</b> passo dopo passo.
                </v-alert>
                <v-alert v-if="!$vuetify.breakpoint.smAndDown" :value="true" slot="opposite" color="primaryBG" class="white--text processoCard" >
                     <div class="noi">NOI</div>
                    <b>Elaboriamo il materiale pubblicitario</b> per l'innaugurazione dell'attività e un <b>nostro tutor ti seguirà</b> passo dopo passo.
                </v-alert>
            </v-timeline-item>

            <v-timeline-item color="primaryBG" icon="mdi-numeric-3" fill-dot >
                <v-alert v-if="$vuetify.breakpoint.smAndDown" :value="true" color="primaryBG" class="white--text processoCard" >
                    <div class="tu dense">TU</div>
                    Innauguri e <b>avvii l'attività</b>.
                </v-alert>
                <v-alert :value="true" color="primaryBG" class="white--text processoCard" >
                    <div class="noi">NOI</div>
                    Per tutta la durata del contratto ti garantiamo <b>assistenza tecnica, commerciale e di marketing</b>. Potrai sempre contare sul nostro supporto.
                </v-alert>
                <v-alert v-if="!$vuetify.breakpoint.smAndDown" :value="true" slot="opposite" color="primaryBG" class="white--text processoCard oppositeCard" >
                    <div class="tu">TU</div>
                    Innauguri e <b>avvii l'attività</b>.
                </v-alert>
            </v-timeline-item>
        </v-timeline>
        <div class="secontText">
            <b>Noi crediamo in te</b> e ti supportiamo, offendoti tutti gli strumenti per diventare un <b>imprenditore di successo</b> in <b>tempi rapidi</b>
        </div>
        <BusinessForm />
    </div>
</template>

<script>
import BusinessForm from '../../components/public/businessForm.vue'
export default {
    components:{BusinessForm},
    metaInfo() {
        return { 
            title: "AggiudicaRe - Possibilità di Business",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Possibilità di Business"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
    data() {
        return {
            isTouch:undefined,
            loaded:false,
        }
    },
    methods: {
        isTouchDevice() {
            return (('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0));
        },
        scrollRight(){
            const content = document.querySelector('#cosaOffriamo');
            content.scrollBy({
                top: 0,
                left: +500,
                behavior: 'smooth'
            }) 
        },
        scrollLeft(){
            const content = document.querySelector('#cosaOffriamo');
            content.scrollBy({
                top: 0,
                left: -500, 
                behavior: 'smooth'
            }) 
        }
    },
    mounted() {
        this.isTouch = this.isTouchDevice();
        this.loaded = true;
    },
}
</script>

<style lang="scss" scoped>
#business{
    padding-bottom: 100px;
}
.firstText, .secontText{
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 25pt;
    line-height: 35px;
    margin-bottom: 100px;
    padding: 10px;
    border-left: 5px solid var(--primaryColor);
    border-right: 5px solid var(--primaryColor);
    border-radius: 10px;
    @media screen and (max-width: 730px) {font-size:20pt;line-height: 25px;}
    @media screen and (max-width: 510px) {font-size:17pt;line-height: 25px;}
    @media screen and (max-width: 430px) {font-size:15pt;line-height: 20px; margin-bottom: 50px;}
    @media screen and (max-width: 350px) {font-size:12pt;line-height: 17px; padding: 5px;}
}
.sectionText{
    width: max-content;
    color: var(--primaryColor);
    padding: 5px 20px;
    font-size: 25pt;
    font-weight: 800;
    border-radius: 20px;
    margin: 0 auto;
    @media screen and (max-width: 420px) {font-size:20pt;}
    @media screen and (max-width: 320px) {font-size:18pt;}
    @media screen and (max-width: 300px) {font-size:16pt;}
    @media screen and (max-width: 264px) {font-size:14pt;}
}
.cosaOffiramoContainer{
    position: relative;
    width: max-content;
    max-width: 100%;
    padding: 0px 40px;
    margin: 0 auto;
    .leftArrow{
        position: absolute;
        left: 0;top:50%;
        transform: translateY(-50%);

    }
    .rightArrow{
        position: absolute;
        right: 0;top:50%;
        transform: translateY(-50%);
    }
    .v-icon.v-icon{
        color: var(--primaryColor);
        cursor: pointer;
        &:hover{
            color: rgb(251, 192, 55);
        }
    }
}
.cosaOffriamo{
    display: flex;
    row-gap: 20px;
    column-gap: 30px;
    width: 100%;
    max-width: 1255px;
    overflow: auto;
    padding: 20px 20px;
    margin: 0 auto;
    margin-bottom: 100px;
    cursor: grab;
    @media screen and (max-width: 430px) {margin-bottom: 50px;}

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        background: var(--primaryColor); 
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--primaryColor);
    }
    .blocco{
        display: flex;
        column-gap: 30px;
        row-gap: 20px;
        @media screen and (max-width: 530px) {flex-flow: column;}
    }

    .element{
        padding: 20px 15px;
        background-color: var(--primaryColor);
        color: white;
        min-width: 220px;
        border-radius: 10px;
        text-align: center;
        font-size: 13pt;
        line-height: 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        img{
            width: 90%;
            margin: 0 auto;
        }
        @media screen and (max-width: 530px) {padding: 15px 10px;min-width: 180px;}
        @media screen and (max-width: 430px) {padding: 10px 5px;min-width: 140px;font-size: 12pt;}
        @media screen and (max-width: 250px) {padding: 10px 5px;min-width: 140px;font-size: 10pt;}
    }
}

.processoCard{
    padding: 5px 20px 10px 20px;
    @media screen and (max-width: 530px) {padding: 5px 10px 5px 10px;}
    @media screen and (max-width: 300px) {font-size: 9pt;}
    max-width: 300px;
    text-align: center;
}
.oppositeCard{
    margin-left: auto;
}
.tu{
    font-size: 18pt;
    text-align: right;
    font-weight: 800;
    opacity: 0.7;
    @media screen and (max-width: 530px) {font-size: 15pt;}
    @media screen and (max-width: 300px) {font-size: 12pt;}
}
.noi{
    text-align: left;
    font-size: 18pt;
    opacity: 0.7;
    font-weight: 800;
    @media screen and (max-width: 530px) {font-size: 15pt;}
    @media screen and (max-width: 300px) {font-size: 12pt;}
}
.dense{
    text-align: left;
}
.timeLine{
    width: 100%;
    max-width: max-content;
    padding: 10px 10px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.top{
    margin-bottom: 10px;
    img{
        width: 100%;
        max-height: 500px;
    }
    .titolo{
        font-size: 60pt;
        line-height: 60px;
        text-align: center;
        font-weight: 800;
        color: var(--primaryColor);
        text-transform: uppercase;
        @media screen and (max-width: 510px) {font-size:50pt;line-height: 50px;}
        @media screen and (max-width: 430px) {font-size:40pt;line-height: 40px;}
        @media screen and (max-width: 350px) {font-size:30pt;line-height: 30px;}
        @media screen and (max-width: 250px) {font-size:20pt;line-height: 20px;}
    }
}
</style>