<template>
    <div id="immobili">
        <div class="loading" v-if="loading">
            <div class="loader">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
        <div class="ifData" v-if="retrivedImmobili && !loading">
            <div class="mainTitle">IMMOBILI</div>
        <div class="immobili">
            <ImmobilePreview :immobile="immobile" v-for="immobile in immobiliFiltrati.slice(0,currentSeen)" :key="immobile._id"/>
        </div>
        <div class="btnMore">
            <v-btn color="primaryBG" dark block @click="loadMore()" v-if="showBtn" >ALTRI {{this.immobiliFiltrati.length - this.currentSeen}}</v-btn>
        </div>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="500px" v-if="retrivedImmobili">
            <v-card>
                <v-toolbar dark color="primaryBG" height="40px" style="overflow-y:hidden">
                    <v-btn icon dark @click="dialog = false" > <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>Filtri</v-toolbar-title>
                </v-toolbar>
                <div class="impostazioniDati">
                    <v-select :items="immobiliInfo.categorie" v-model="filterCategoria" label="Categoria" @change="filtring()" dense solo clearable item-value='nome'>
                        <template slot="selection" slot-scope="data">
                            ({{data.item.quantita}}) {{ data.item.nome }}
                        </template>
                        <template slot="item" slot-scope="data">
                            ({{data.item.quantita}}) {{ data.item.nome }}
                        </template>
                    </v-select>

                    <v-row>
                        <v-col>
                            <v-select :items="immobiliInfo.comune" v-model="ricercaComune" label="Comune" dense solo clearable item-value='nome' @change="filtring()">
                            <template slot="selection" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                            <template slot="item" slot-scope="data">
                                ({{data.item.quantita}}) {{ data.item.nome }}
                            </template>
                        </v-select>    
                        </v-col>
                        <v-col>
                            <v-select :items="immobiliInfo.regioni" v-model="filterRegione" label="Regione" @change="filtring()" dense solo clearable item-value='nome'>
                                <template slot="selection" slot-scope="data">
                                    ({{data.item.quantita}}) {{ data.item.nome }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    ({{data.item.quantita}}) {{ data.item.nome }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    
                    
                    


                    <div class="rangeSoldi">
                        <v-range-slider v-model="range" :max="rangeMax" :min="rangeMin" :step="rangeStep" hide-details class="align-center" @change="filtring()" ></v-range-slider>
                        <div class="rangeLeft">{{moneyFormat(range[0])}}€</div>
                        <div class="rangeCenter">PREZZO</div>
                        <div class="rangeRight">{{moneyFormat(range[1])}}€</div>
                    </div>

                    <div class="rangeSoldi">
                        <v-range-slider v-model="mq" :max="maxMq" :min="minMq" :step="stepMq" hide-details class="align-center" @change="filtring()" ></v-range-slider>
                        <div class="rangeLeft">{{moneyFormat(mq[0])}} <small>M2</small> </div>
                        <div class="rangeCenter">METRIQUADRI</div>
                        <div class="rangeRight">{{moneyFormat(mq[1])}} <small>M2</small> </div>
                    </div>

                    <v-select :items="offerteOptions" v-model="filterOfferte" label="Offerte Imperdibili" @change="filtring()" dense solo clearable></v-select>
                    
                    <v-btn elevation="4" color="primaryBG" block dark @click="dialog=false" >CERCA</v-btn>
                    <v-btn class="btnCentered" elevation="4" color="error" dark x-small @click="resetFiltri()" >RESETTA FILTRI</v-btn>
                </div>

            </v-card>
        </v-dialog>
        <v-btn class="filtringButton" elevation="4" color="primaryBG" dark fab small @click="dialog=true" ><v-icon dark>mdi-filter-variant</v-icon></v-btn>
        </div>
    </div>
</template>

<script>
import ImmobilePreview from '../../components/public/immobilePreview.vue'
import { mapActions, mapGetters } from 'vuex';
import RegioniJson from '../../utils/regioni.json'
export default {
    components: {
        ImmobilePreview
    },
    metaInfo() {
        return { 
            title: "AggiudicaRe - Immobili",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Immobili"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
    data() {
        return {
            immobiliFiltrati:[],
            showBtn:true,
            currentSeen: 10,
            dialog:false,
            ricercaComune:undefined,
            rangeMin: 0,
            rangeMax: 1000000,
            rangeStep:5000,
            range: [],
            minMq: 0,
            maxMq: 2000,
            stepMq: 10,
            mq:[],
            loading:true,
            regioniOptions:[],
            filterRegione:undefined,
            filterCategoria:undefined,
            offerteOptions:['Si','No'],
            filterOfferte:undefined,
        }
    },
    computed: mapGetters(['immobili','retrivedImmobili','immobiliInfo']),
    methods: {
        ...mapActions(['fetchAllImmobili']),
        loadRegioni(){
            RegioniJson.forEach(regione => {
                this.regioniOptions.push({
                    value: regione.nome,
                    text: regione.nome,
                })
            });
        },
        resetFiltri(){
            this.ricercaComune='';
            this.filterCategoria=undefined;
            this.filterRegione=undefined;
            this.range = [this.rangeMin,this.rangeMax];
            this.mq = [this.minMq,this.maxMq];
            this.$router.push('/immobili');
            this.filtring();
        },
        setup(){
            this.immobiliFiltrati = this.immobili;
            this.setupMinMax();
        },
        loadMore(){
            if(this.immobiliFiltrati.length - this.currentSeen > 10){
                this.currentSeen = this.currentSeen+10;
            }else{
                this.currentSeen = this.immobiliFiltrati.length;
                this.showBtn=false;
            }
        },
        categoriaFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                var x=0;
                while(x<immobile.categoria.length){
                    if(immobile.categoria[x].nome==this.filterCategoria){
                        good.push(immobile);
                        break;
                    }
                    x++;
                }             
            });
            this.immobiliFiltrati = good;
        },
        comuneFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                
                if(immobile.posizione.comune){
                    var comune = immobile.posizione.comune;
                    if(comune.toLowerCase().includes(this.ricercaComune.toLowerCase())){
                        good.push(immobile);
                    }    
                }
                
            });
            this.immobiliFiltrati = good;
        },
        prezzoFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                
                if(immobile.prezzo){
                    if(this.range[1] == this.rangeMax && immobile.prezzo >= this.range[0]){
                        good.push(immobile);
                    }else if(immobile.prezzo >= this.range[0] && immobile.prezzo <= this.range[1]){
                        good.push(immobile);
                    }
                }
                
            });
            this.immobiliFiltrati = good;
        },
        mqFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                
                if(immobile.metriQuadri){
                    if(this.mq[1] == this.maxMq && immobile.metriQuadri >= this.mq[0]){
                        good.push(immobile);
                    }else if(immobile.metriQuadri >= this.mq[0] && immobile.metriQuadri <= this.mq[1]){
                        good.push(immobile);
                    }
                }
                
            });
            this.immobiliFiltrati = good;

        },
        regioneFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                
                if(immobile.posizione.regione){
                    if(immobile.posizione.regione == this.filterRegione){
                        good.push(immobile)
                    }
                }
                
            });
            this.immobiliFiltrati = good;
        },
        offerteFilter(){
            var good = [];
            this.immobiliFiltrati.forEach(immobile => {
                if(this.filterOfferte=='Si' && immobile.offertaImperdibile){
                    good.push(immobile)
                }
                if(this.filterOfferte=='No' && !immobile.offertaImperdibile){
                    good.push(immobile)
                }
            });
            this.immobiliFiltrati = good;
        },
        filtring(){
            this.immobiliFiltrati = this.immobili;
            if(this.ricercaComune!=undefined){
                if(this.ricercaComune!=this.$route.query.ricercaComune){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { ricercaComune: this.ricercaComune }) });    
                }
                this.comuneFilter();
            }else{
                if(this.$route.query.ricercaComune){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { ricercaComune: null }) });  
                }
            }


            if(this.range[0]!=this.rangeMin || this.range[1]!=this.rangeMax){
                if(this.$route.query.range0!=this.range[0]){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { range0: this.range[0] }) });    
                }
                if(this.$route.query.range1!=this.range[1]){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { range1: this.range[1] }) });    
                }
                this.prezzoFilter();
            }else{
                if(this.range[0]==this.rangeMin){
                    if(this.$route.query.range0){
                        this.$router.push({ query: Object.assign({}, this.$route.query, { range0: null }) });      
                    }
                    
                }
                if(this.range[1]==this.rangeMax){
                    if(this.$route.query.range1){
                        this.$router.push({ query: Object.assign({}, this.$route.query, { range1: null }) });  
                    }
                }
            }




            if(this.mq[0]!=this.minMq || this.mq[1]!=this.maxMq){
                if(this.$route.query.mq0!=this.mq[0]){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { mq0: this.mq[0] }) });    
                }
                if(this.$route.query.mq1!=this.mq[1]){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { mq1: this.mq[1] }) });    
                }
                this.mqFilter();
            }else{
                if(this.mq[0]==this.minMq){
                    if(this.$route.query.mq0){
                        this.$router.push({ query: Object.assign({}, this.$route.query, { mq0: null }) });      
                    }
                    
                }
                if(this.mq[1]==this.maxMq){
                    if(this.$route.query.mq1){
                        this.$router.push({ query: Object.assign({}, this.$route.query, { mq1: null }) });  
                    }
                }
            }




            if(this.filterRegione!=undefined){
                if(this.filterRegione!=this.$route.query.filterRegione){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filterRegione: this.filterRegione }) });    
                }
                this.regioneFilter();
            }else{
                if(this.$route.query.filterRegione){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filterRegione: null }) });  
                }
            }

            if(this.filterCategoria!=undefined){
                if(this.filterCategoria!=this.$route.query.filterCategoria){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filterCategoria: this.filterCategoria }) });    
                }
                this.categoriaFilter();
            }else{
                if(this.$route.query.filterCategoria){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filterCategoria: null }) });  
                }
            }

            if(this.filterOfferte != undefined){
                if(this.filterOfferte!=this.$route.query.filterOfferte){
                    this.$router.push({query:Object.assign({},this.$route.query,{filterOfferte:this.filterOfferte})});    
                }
                this.offerteFilter();
            }else{
                if(this.$route.query.filterOfferte){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filterOfferte: null }) });  
                }
            }


            this.currentSeen = 10;
            if(this.immobiliFiltrati.length <= this.currentSeen){
                this.showBtn = false
            }else{
                this.showBtn = true
            }
        },
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        getQuerys(){
            if(this.$route.query.filterRegione){
                this.filterRegione = this.$route.query.filterRegione;
            }
            if(this.$route.query.ricercaComune){
                this.ricercaComune = this.$route.query.ricercaComune;
            }

            if(this.$route.query.range0){
                this.range[0] = this.$route.query.range0;
            }

            if(this.$route.query.range1){
                this.range[1] = this.$route.query.range1;
            }

            if(this.$route.query.mq0){
                this.mq[0] = this.$route.query.mq0;
            }

            if(this.$route.query.mq1){
                this.mq[1] = this.$route.query.mq1;
            }

            if(this.$route.query.filterCategoria){
                this.filterCategoria = this.$route.query.filterCategoria;
            }

            if(this.$route.query.filterOfferte){
                this.filterOfferte = this.$route.query.filterOfferte;
            }
        },
        setupMinMax(){
            this.rangeMax = this.getMaxPrice();
            this.rangeMin = this.getMinPrice();
            this.minMq = this.getMinMq();
            this.range = [this.rangeMin,this.rangeMax];
            this.mq = [this.minMq,this.maxMq];
            
        },
        getMaxPrice(){
            var division = (Math.round(this.immobiliInfo.maxPrezzo / this.rangeStep))+1;
            var max = this.rangeStep * division;
            return max;
        },
        getMinPrice(){
            var division = (Math.round(this.immobiliInfo.minPrezzo / this.rangeStep))-1;
            var min = this.rangeStep * division;
            return min;         
        },
        getMaxMq(){
            var division = (Math.round(this.immobiliInfo.maxMq / this.stepMq))+1;
            var max = this.stepMq * division;
            return max;
        },
        getMinMq(){
            var division = (Math.round(this.immobiliInfo.minMq / this.stepMq))-1;
            var min = this.stepMq * division;
            return min;
        }
    },
    mounted() {
        this.getQuerys();
        this.loadRegioni();
        if(!this.retrivedImmobili){
            this.fetchAllImmobili().then(()=>{
                this.setup();
                this.loading=false;
                this.filtring();
            })
        }else{
            this.setup();
            this.loading=false;
            this.filtring();
        }
    },
}
</script>

<style lang="scss" scoped>
.immobili{
    width: max-content;
    max-width: 100%;
    margin: 20px auto 40px auto;
    padding-left: 6%;
    padding-right: 6%;
    column-gap: 40px;
    row-gap: 40px;
    display: flex;
    flex-flow:row wrap;
    align-items: center;
    justify-content: center;
}
.filtringButton{
    position: fixed;
    bottom: 10px; right:10px;
}
.impostazioniDati{
    padding: 20px;
}
.rangeSoldi{
    position: relative;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
    padding:25px 5px 5px 5px;
    border-radius: 5px;
    margin-bottom: 25px;
    .rangeLeft{
        position: absolute;
        top:5px;left:10px;
        font-size: 9pt;
        font-weight: 800;
        color: rgb(129, 129, 129);
    }
    .rangeCenter{
        position: absolute;
        top:5px;left:50%;
        transform: translateX(-50%);
        font-size: 10pt;
        font-weight: 600;
        color: rgb(65, 65, 65);
        text-align: center;
        line-height: 10px;
    }
    .rangeRight{
        position: absolute;
        top:5px;right:10px;
        font-size: 9pt;
        font-weight: 800;
        color: rgb(129, 129, 129);
    }

}
.btnMore{
    max-width: 800px;
    margin: 0 auto 80px auto;
    padding: 0px 6%;
}
.loading{
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.541);
    backdrop-filter: blur(3px);
    position: relative;
}
.loader{
    position: absolute;
    top:50%;left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: max-content;
}
.btnCentered{
    left:50%;
    transform: translateX(-50%);
}
</style>