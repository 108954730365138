<template>
    <div id="immobili">
        <div class="mainTitle">IMMOBILI</div>
        <template>
            <v-timeline>
                <v-timeline-item v-for="immobile in immobiliStorico" :key="immobile._id" :id="immobile.data">
                    <template v-slot:icon>
                        <span class="valore">{{immobile.quantita}}</span>
                    </template>
                    <template v-slot:opposite>
                        <span class="data">{{formatDate(immobile.data)}}</span>
                    </template>
                </v-timeline-item>
            </v-timeline>
        </template>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: mapGetters(['immobiliStorico','retrivedImmobiliStorico']),
    methods: {
        ...mapActions(['fetchImmobiliStorico']),
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        }
    },
    inject: ['toogleLoading'],
    mounted() {
        if(!this.retrivedImmobiliStorico){
            this.toogleLoading(true)
            this.fetchImmobiliStorico().then(()=>{
                this.toogleLoading(false);
            }).catch(()=>{
                this.toogleLoading(false);
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.data{
    background: var(--primaryColor);
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
}
.valore{
    color: white;
    font-weight: 700;
}
</style>