import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vue-search-select/dist/VueSearchSelect.css'
import Notifications from 'vue-notification'
import VuetifyMoney from "vuetify-money";
import x2js from "x2js";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGtag from "vue-gtag";
import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)
import VueMeta from 'vue-meta'
Vue.use(VueMeta)


Vue.prototype.$x2js = new x2js();
Vue.use(VuetifyMoney);

Vue.use(require('vue-cookies'))
Vue.use(Notifications)
Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-HL8QT4TQJC" }
}); 
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD1ps_saTYa7-eTI1MBKkLBpNvgVOowPnQ",
    libraries: "places" // necessary for places input
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
