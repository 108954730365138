<template>
    <div id="accessi">
        <div class="mainTitle">ACCESSI</div>
        <div class="contenitore elevation-9">
            <v-virtual-scroll
                height="400"
                item-height="30"
                :items="accessi"
                class="virtualScroll"
            >
            <template v-slot:default="{ item }">
                <div class="accesso">
                    <div class="data">[ {{formatDateHour(item.data)}} ]</div>
                    <div class="mid"><div class="ip" v-if="item.ip">{{item.ip}}</div></div>
                    <div class="right">
                        <div class="admin" v-if="!item.isAgente">ADMIN</div>
                        <UserChipSmall :agente="item.agente" v-if="item.isAgente && item.agente"/>
                    </div> 
                </div>
                
            </template>
            </v-virtual-scroll></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserChipSmall from '../../components/users/UserChipSmall.vue'
export default {
    components:{UserChipSmall},
    computed: mapGetters(['accessi','retrivedAccessi']),
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['fetchAllAccessi']),
        formatDateHour(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            var ore = data.getHours()<10?'0'+data.getHours():data.getHours();
            var minuti = data.getMinutes()<10?'0'+data.getMinutes():data.getMinutes();
            return giorno+'/'+mese+'/'+data.getFullYear()+' - '+ore+':'+minuti;
        }
    },
    mounted() {
        if(!this.retrivedAccessi){
            this.toogleLoading(true);
            this.fetchAllAccessi().then(()=>{this.toogleLoading(false);}).catch(()=>{this.toogleLoading(false);})
        }
    },
}
</script>

<style lang="scss" scoped>
.accessi{
    padding: 20px;
    border-radius: 15px;
    margin: 10px auto 10px auto;
    background-color: white;
}
.contenitore{
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    margin: 10px auto 10px auto;
}
.accesso{
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        row-gap: 5px;
        align-items: center;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.431);
        margin-right: 10px;
        .data{
            font-size: 10pt;
            font-weight: 500;
            line-height: 13px;
        }
        .mid{
            text-align: center;
            .ip{
                font-size: 7pt;
                width: 100%;
            }
        }
        .right{
            .admin{
                color: red;
                font-weight: 600;
                font-size: 10pt;
            }
            margin-right: 10px;
        }
}
.virtualScroll{
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(117, 117, 117);
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>