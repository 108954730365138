<template>
    <div id="incarico_settings">
        <div class="flexxable">
            <div class="impostazioni elevation-4">
                <v-form ref="form" @submit.prevent="submit" v-model="isFormValid">
                    <div class="divisore">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">UTENTE</div>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="form.utente.nome" label="Nome" clearable outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field v-model="form.utente.cognome" label="Cognome" clearable outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="rowMt">
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="form.utente.email" :rules="emailRules" label="Email" clearable outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field v-model="form.utente.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="divisore">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">DATI RICERCA</div>
                    </div>
                    <vuetify-money v-model="form.costo_ricerca.prezzo" label="Costo Ricerca" :options="optionsCostoRicerca" clearable outlined dense />    


                    <v-btn block color="orange" type="submit" :disabled="!isFormValid"> MODIFICA </v-btn>
                </v-form>
            </div>
            <div class="actions elevation-4">
                
                <v-dialog v-model="dialog_resettaP" width="500" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" dark v-bind="attrs" v-on="on" > CANCELLA </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h5"> SEI SICURO? </v-card-title>
                        <v-card-text>
                            <b class="redText">Cancellare</b> implica non poter recuperare più nessuna informazione da questo incarico.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dialog_resettaP = false" > Annulla </v-btn>
                            <v-btn color="error" @click="cancella" > CANCELLA </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props:['incarico'],
    inject: ['toogleLoading'],
    data() {
        return {
            dialog_resettaP:false,
            modal1:false,
            modal2:false,
            modal3:false,
            modal4:false,
            form:{
                _id:'',
                utente:{
                    nome:undefined,
                    cognome:undefined,
                    email:undefined,
                    cellulare:undefined,
                },
                costo_ricerca:{
                    prezzo:undefined,
                },
            },
            isFormValid:false,
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
            optionsStato: ['In Ricerca','Ricerca Sospesa','Ricerca Conclusa','In Asta','Aggiudicata','Persa','Rinuncia Ricerca','Rinuncia Asta'],
            emailRules: [
                v => !v || /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  'E-mail non valida',
            ],
        }
    },
    watch:{
        incarico(){
            this.setupForm();
        }
    },
    methods: {
        ...mapActions(['deleteIncaricoFromUser','editIncaricoFromUser']),
        cancella(){
            this.toogleLoading(true)
            this.deleteIncaricoFromUser(this.incarico._id).then(()=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Incarico:',
                    text: 'Riuscita',
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$router.push('/area-riservata/incarichi');
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Incarico:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        },
        submit(){
            this.toogleLoading(true)
            this.editIncaricoFromUser(this.form).then(()=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Incarico:',
                    text: 'Riuscita',
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(()=>{this.toogleLoading(false)});
        },
        setupForm(){
            this.form._id = this.incarico._id;
            if(this.incarico.utente){
                this.form.utente.nome = this.incarico.utente.nome;
                this.form.utente.cognome = this.incarico.utente.cognome;   
                this.form.utente.email = this.incarico.utente.email; 
                this.form.utente.cellulare = this.incarico.utente.cellulare; 
            }
            if(this.incarico.costo_ricerca){
                this.form.costo_ricerca.prezzo = this.incarico.costo_ricerca.prezzo;
            }
        }
    },mounted() {
        this.setupForm();
    },
}
</script>

<style lang="scss" scoped>
.flexxable{
    display: flex;
    max-width: 1200px;
    margin: 10px auto;
    column-gap: 10px;
    row-gap: 20px;
    @media screen and (max-width: 700px) {flex-flow: column;}
    .impostazioni{
        flex: 3;
        border-radius: 15px;
        overflow: hidden;
        padding: 20px;
    }
    .actions{
        flex: 1;
        border-radius: 15px;
        overflow: hidden;
        padding: 10px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        row-gap: 5px;
        height: 100px;
    }
}

.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.tryFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.v-input--selection-controls{
    margin-top: 3px;
}
.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}

.rowMt{
    margin-top: -22px;
    @media screen and (max-width: 600px) {margin-top: -28px;}
}
</style>