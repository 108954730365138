<template>
    <div id="impostazioni">
        <div class="mainTitle">IMPOSTAZIONI</div>
        <div class="flexxed">
            <div class="editForm elevation-9" v-if="me">
                <v-form ref="form" @submit.prevent="edit" v-model="isFormValid">
                    <v-text-field v-model="me.email" label="Email *" required clearable outlined dense disabled></v-text-field>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="me.nome" :rules="requiredRules" label="Nome *" required clearable outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="noMtOnLow">
                            <v-text-field v-model="me.cognome" :rules="requiredRules" label="Cognome *" required clearable outlined dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field v-model="me.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>

                    <div class="divisore">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">UFFICIO</div>
                    </div>
                    
                    <div class="ufficio" v-if="me.ufficio">
                    <v-text-field v-model="me.ufficio.via" label="Via"  clearable outlined dense></v-text-field>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="me.ufficio.comune" label="Comune"  clearable outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="noMtOnLow">
                                <model-select  class="mb-20" :options="provincieOptions"
                                v-model="me.ufficio.provincia"
                                placeholder="Provincia">
                                </model-select>
                            </v-col>
                        </v-row>
                        <v-row class="rowMt">
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="me.ufficio.cap" label="CAP"  clearable outlined dense></v-text-field></v-col>
                            <v-col cols="12" sm="6" class="noMtOnLow">
                                <model-select class="mb-20" :options="regioniOptions" v-model="me.ufficio.regione" placeholder="Regione"></model-select>
                            </v-col>
                        </v-row>
                    </div>

                    <v-btn block color="primary" type="submit" :disabled="!isFormValid" class="modificaBtn">
                        MODIFICA
                    </v-btn>
                    
                </v-form>
            </div>
            <div class="rightSide">
                <div class="changePass elevation-9">
                    <div class="titleChangePass notSelectableTxt">CAMBIA PASSWORD</div>
                    <v-form @submit.prevent="cambiaPass">
                        <v-text-field v-model="changePass.vecchiaPass" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Vecchia Password *" hint="Inserisci la vecchia password" @click:append="show1 = !show1" required outlined clearable dense  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'">
                        </v-text-field>

                        <v-text-field v-model="changePass.nuovaPass" :type="show2 ? 'text' : 'password'" name="input-10-1" label="Nuova Password *" hint="Inserisci la nuova password" @click:append="show2 = !show2" required outlined clearable dense  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'">
                        </v-text-field>

                        <v-text-field v-model="changePass.nuovaPass2" :type="show3 ? 'text' : 'password'" name="input-10-1" label="Ripeti Password *" hint="Ripeti la nuova password" @click:append="show3 = !show3" required outlined clearable dense :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'">
                        </v-text-field>


                        <v-btn block color="orange" type="submit" :disabled="!isFormValid">
                            MODIFICA PASSWORD
                        </v-btn>
                    </v-form>
                </div>
                <div class="cambiaFoto elevation-9">
                    <div class="titleChangePass notSelectableTxt">CAMBIA FOTO</div>
                    <v-form @submit.prevent="cambiaPhoto" v-model="isFormValidPhoto">
                    <div class="fleexxed">
                        <div class="immagine" v-if="me.foto" :style="'background-image: url(\''+me.foto+'\')'"></div>
                        <div class="immagine" v-if="!me.foto" :style="'background-image: url(\''+require('../../assets/imgs/empty-profile.png')+'\')'"></div>
                        <div class="upload">
                            <v-file-input v-model="photoSelect" dense outlined required :rules="requiredRules" accept="image/png, image/jpeg"></v-file-input>       
                        </div>
                         
                    </div>
                    
                        <v-btn elevation="2" type="submit" color="primary" :disabled="!isFormValidPhoto">CARICA FOTO</v-btn>    
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ModelSelect } from 'vue-search-select'
import ProvincieJson from '../../utils/provincie.json'
import RegioniJson from '../../utils/regioni.json'
export default {
    data() {
        return {
            show1:false,
            show2:false,
            show3:false,
            provincieOptions:[],
            regioniOptions:[],
            isFormValid:false,
            isFormValidPhoto:false,
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            changePass:{
                vecchiaPass: '',
                nuovaPass:'',
                nuovaPass2:'',
            },
            photoSelect:null
        }
    },
    inject: ['toogleLoading'],
    components:{ModelSelect},
    computed: mapGetters(['me']),
    methods: {
        ...mapActions(['userEditUser','userEditPassword','userChangePhoto']),
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        loadRegioni(){
            RegioniJson.forEach(regione => {
                this.regioniOptions.push({
                    value: regione.nome,
                    text: regione.nome,
                })
            });
        },
        edit(){
            this.toogleLoading(true);
            this.userEditUser(this.me).then((response)=>{
                this.toogleLoading(false);
                this.$notify({
                    group: 'baseNotification',
                    title: response.message,
                    duration: 2000,
                    type: 'success'
                });
            }).catch(()=>{this.toogleLoading(false);})
        },
        cambiaPass(){
            if(this.changePass.nuovaPass != this.changePass.nuovaPass2){
                return this.$notify({ group: 'baseNotification', title: 'Cambio Password:', text: 'Nuova Password non combaciante',     duration: 2000, type: 'error' });
            }
            this.toogleLoading(true);
            this.userEditPassword(this.changePass).then((response)=>{
                this.$notify({ group: 'baseNotification', title: 'Cambio Password:', text: response.message, duration: 2000, type: 'success' });
                this.toogleLoading(false);
                return this.changePass = { vecchiaPass: '', nuovaPass:'', nuovaPass2:'', }
            }).catch((err)=>{
                this.toogleLoading(false);
                return this.$notify({ group: 'baseNotification', title: 'Cambio Password:', text: err.message, duration: 2000, type: 'error' });
            })
        },
        cambiaPhoto(){
            this.toogleLoading(true);
            this.userChangePhoto(this.photoSelect).then((response)=>{
                this.$notify({ group: 'baseNotification', title: 'Cambio Foto:', text: response.message, duration: 2000, type: 'success' });
                this.toogleLoading(false);
                return this.photoSelect = null;
            }).catch((err)=>{
                this.toogleLoading(false);
                return this.$notify({ group: 'baseNotification', title: 'Cambio Foto:', text: err.message, duration: 2000, type: 'error' });
            })
        }
    },
    mounted() {
        this.loadProvincie();
        this.loadRegioni();
    },
}
</script>

<style lang="scss" scoped>
.editForm{
    max-width: 800px;
    flex:2;
    width: 100%;
    margin: 0px auto;
    padding: 30px;
    border-radius: 15px;
}
.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.flexxed{
    padding: 0px 10px;
    display: flex;
    max-width: 1200px;
    column-gap: 20px;
    row-gap: 20px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 40px;
    flex-flow:row wrap;
    @media screen and (max-width: 800px) {flex-flow: column;}
    h1{
        text-align: center;
        margin-bottom: 10px;
        line-height: 25px;
    }
    .rightSide{
        flex: 1;
        margin: 0px auto;
        display: flex;
        flex-flow: column;
        row-gap: 20px;
        width: 100%;
        .changePass{
            padding: 20px 30px 30px 30px;
            border-radius: 15px;
            min-width: 200px;
            height: max-content;
            .titleChangePass{
                font-size: 20pt;
                line-height: 25px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 15px;
                color: var(--primaryColor);
            }
        }
        .cambiaFoto{
            padding: 20px 30px 30px 30px;
            border-radius: 15px;
            min-width: 200px;
            height: max-content;
            .titleChangePass{
                font-size: 20pt;
                line-height: 25px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 15px;
                color: var(--primaryColor);
            }
            .fleexxed{
                display: flex;
                align-items: flex-start;
                .immagine{
                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                    border: 1px solid rgb(189, 189, 189);
                    background-size: cover;
                    background-position: center center;
                }
                .upload{
                    flex-grow:1;   
                }
            }


            .v-btn{
                width: 100%;
                margin-top: 10px;
            }

        }
    }
}

.noMtOnLow{
    @media screen and (max-width: 600px) {margin-top: -30px;}
}

.aBitOfMT{
    @media screen and (max-width: 600px) {margin-top: 20px;}
}
.rowMt{
    margin-top: -22px;
    @media screen and (max-width: 600px) {margin-top: 5px;}
}
.modificaBtn{
    @media screen and (max-width: 600px) {margin-top: 25px;}
}
</style>