<template>
    <div id="storicoIncarichi">
        <div class="grafico elevation-9">
            <div class="grafTitle notSelectableTxt">INCARICHI</div>
            <LineChart :chartdata="chartdata" v-if="renderChar"/>    
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import LineChart from '../../components/admin/grafici/lineChart.vue'
export default {
    components:{LineChart},
    computed: mapGetters(['storicoIncarichi','retrivedStoricoIncarichi']),
    data() {
        return {
            renderChar:false,
            chartdata: {
                labels: [],
                datasets: [
                    {
                        label: 'In Ricerca',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--inRicerca'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--inRicerca'),
                    },
                    {
                        label: 'Ricerca Sospesa',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--ricercaSospesa'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--ricercaSospesa'),
                    },
                    {
                        label: 'Ricerca Conclusa',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--ricercaConclusa'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--ricercaConclusa'),
                    },
                    {
                        label: 'In Asta',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--inAsta'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--inAsta'),
                    },
                    {
                        label: 'Aggiudicata',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--aggiudicata'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--aggiudicata'),
                    },
                    {
                        label: 'Persa',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--persa'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--persa'),
                    },
                    {
                        label: 'Rinuncia Ricerca',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--rinunciaRicerca'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--rinunciaRicerca'),
                    },
                    {
                        label: 'Rinuncia Asta',
                        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--rinunciaAsta'),
                        data: [],
                        fill: false,
                        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--rinunciaAsta'),
                    },
                ]
            },
        }
    },
    methods: {
        ...mapActions(['fetchStoricoIncarichi']),
        setupData(){
            this.storicoIncarichi.forEach(storico => {
                this.chartdata.labels.push(this.formatDate(storico.data));
                this.chartdata.datasets[0].data.push(storico.inRicerca);
                this.chartdata.datasets[1].data.push(storico.ricercaSospesa);
                this.chartdata.datasets[2].data.push(storico.ricercaConclusa);
                this.chartdata.datasets[3].data.push(storico.inAsta);
                this.chartdata.datasets[4].data.push(storico.aggiudicata);
                this.chartdata.datasets[5].data.push(storico.persa);
                this.chartdata.datasets[6].data.push(storico.rinunciaRicerca);
                this.chartdata.datasets[7].data.push(storico.rinunciaAsta);
            });
            //this.insertRandom();
            this.renderChar = true;
        },
        insertRandom(){
            var rendoms = 10;
            for (let index = 0; index < rendoms; index++) {
                this.chartdata.labels.push('05/08/2021');
                var min = 1;
                var max = 30;
                var inRicerca = (Math.random() * max) + min;
                var ricercaSospesa = (Math.random() * max) + min;
                var ricercaConclusa = (Math.random() * max) + min;
                var inAsta = (Math.random() * max) + min;
                var aggiudicata = (Math.random() * max) + min;
                var persa = (Math.random() * max) + min;
                var rinunciaRicerca = (Math.random() * max) + min;
                var rinunciaAsta = (Math.random() * max) + min;
                this.chartdata.datasets[0].data.push(inRicerca);
                this.chartdata.datasets[1].data.push(ricercaSospesa);
                this.chartdata.datasets[2].data.push(ricercaConclusa);
                this.chartdata.datasets[3].data.push(inAsta);
                this.chartdata.datasets[4].data.push(aggiudicata);
                this.chartdata.datasets[5].data.push(persa);
                this.chartdata.datasets[6].data.push(rinunciaRicerca);
                this.chartdata.datasets[7].data.push(rinunciaAsta);
            }
        },
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        }
    },
    mounted() {
        if(!this.retrivedStoricoIncarichi){
            this.fetchStoricoIncarichi().then(()=>{this.setupData()});
        }else{this.setupData()}
    },
}
</script>

<style lang="scss" scoped>
.grafico{
    .grafTitle{
        text-align: center;
        font-weight: 800;
        font-size: 25pt;
        line-height: 25px;
        color: rgb(42, 42, 42);
    }
    max-width: 1200px;
    padding: 20px;
    border-radius: 15px;
    margin: 30px auto;
}
</style>