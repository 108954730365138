<template>
    <div id="aggiornamenti">
        <div class="mainTitle">AGGIORNAMENTI</div>
        <div class="elenco elevation-9">
            <div class="aggiornamento" v-for="(aggiornamento,index) in filterAggiornamenti(aggiornamenti.slice().reverse())" :key="index">

                <div class="important" v-if="aggiornamento.important">
                    <div class="version vImp">{{aggiornamento.version}}</div>
                    <div class="data primary">{{aggiornamento.data}}</div>
                    <div class="txt">{{aggiornamento.txt}}</div>
                </div>

                <div class="notImportant" v-if="!aggiornamento.important">
                    <div class="version">{{aggiornamento.version}}</div>
                    <div class="data">{{aggiornamento.data}}</div>
                    <div class="agg">
                        <ol>
                            <li v-for="(element,index) in filterInfoR(aggiornamento.info)" :key="index">
                                <span v-if="element.target=='r' || element.target=='ar'">[Area Riservata]</span>
                                <span v-if="element.target=='p'">[Public]</span>
                                {{element.txt}}
                            </li>
                        </ol>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import AggiornamentiJSON from '../../utils/aggiornamenti.json'
export default {
    data() {
        return {
            aggiornamenti:AggiornamentiJSON
        }
    },
    methods: {
        filterAggiornamenti(aggiornamenti){
            var toRet = [];
            aggiornamenti.forEach(aggiornamento => {
                var x = 0;
                if(aggiornamento.important){
                    toRet.push(aggiornamento);
                }else{
                    while(x<aggiornamento.info.length){
                        if(aggiornamento.info[x].target=='r' || aggiornamento.info[x].target=='ar' || aggiornamento.info[x].target=='p'){
                            toRet.push(aggiornamento);
                            break;
                        }
                        x++;
                    }
                }
            }); 
            return toRet;
        },
        filterInfoR(infos){
            var toRet = [];
            infos.forEach(info=>{
                if(info.target=='r' || info.target=='p' || info.target=='ar'){
                    toRet.push(info);
                }
            })
            return toRet
        }
    },
}
</script>

<style lang="scss" scoped>
.elenco{
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 15px 30px;
    margin-top: 10px;
    border-radius: 15px;
    .aggiornamento{
        border: 1px solid gray;
        border-radius: 15px;
        padding: 5px;
        font-size: 10pt;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        .important{
            .txt{
                padding:10px;
                color: var(--primaryColor)
            }
            .vImp{
                color: var(--primaryColor)
            }
        }
        .version{
            position: absolute;
            top:5px;right:10px;
            color: rgba(0, 0, 0, 0.26);
            font-weight: 600;
            letter-spacing: -1px;
            font-family: monospace;
        }
        .data{
            text-align: center;
            background: gray;
            width: max-content;
            margin: 0 auto;
            padding: 2px 10px;
            border-radius: 10px;
            color:white;
        }
        .agg{
            ol{
                display: flex;
                flex-direction: column-reverse;
                li{
                    margin-left: 20px;
                    span{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
</style>