<template>
<div class="charContainer elevation-9" :style="cssProps">
    <h1 class="titolo">{{name}}</h1>
    
    <div class="flexxiamola">
      <div class="single-chart">
          <svg viewBox="0 0 36 36" class="circular-chart baseColor">
          <path class="circle-bg"
              d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path class="circle" :style="'stroke: var(--'+options.color+');'"
              :stroke-dasharray="percent+', 100'"
              d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="19" class="percentage">{{percent}}%</text>
          <text x="18" y="25" class="count">{{count}}</text>
          <text x="18" y="30" class="subb">{{subb}}</text>
          </svg>
      </div>
    </div>
</div>
</template>

<script>
export default {
    props:['percent','name','count','options','subb'],
    computed:{
      cssProps(){
        return {
          '--font_size':this.options.font_size?this.options.font_size+'pt':'20pt',
          '--font_sizeUnder380px':this.options.font_sizeUnder380?this.options.font_sizeUnder380+'pt':'10pt',
          '--chartWidth':this.options.width+'px',
          '--chartWidthUnder380px':this.options.widthUnder380?this.options.widthUnder380+'px':'100px',
        }
      }
    }
}
</script>


<style lang="scss" scoped>



.charContainer{
    width: var(--chartWidth); //default
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    padding-top: 40px;
    text-align: center;
    position: relative;
    background-color: #fff;
    @media screen and (max-width: 380px) {
       width: var(--chartWidthUnder380px)
    }
    h1{
        color:#666;
        font-size: var(--font_size);
        line-height: initial;
        user-select: none;
        position:absolute;
        left: 50%;top:10px;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 20px;
        @media screen and (max-width: 380px) {
          font-size: var(--font_sizeUnder380px)
        }
    }
    *{
      box-sizing: border-box;
    }
}
.flexxiamola{
  height: 100%;
  display: flex;

}

.single-chart {
  width: 100%;
  justify-content: space-around ;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}


.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
  user-select: none;
}
.count{
  fill: #666;
  font-family: sans-serif;
  font-size: 0.3em;
  text-anchor: middle;
  user-select: none;
}
.subb{
  fill: #666;
  font-family: sans-serif;
  font-size: 0.25em;
  text-anchor: middle;
  user-select: none;
}
</style>