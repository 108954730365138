<template>
    <div id="inAsta">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="inAsta" v-bind="attrs" v-on="on" > IN ASTA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 inAsta lighten-2"> IN ASTA? </v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="RicercaConclusa" class="form">
                        <div class="fields">
                            <vuetify-money v-model="form.importo_asta" label="Importo Asta *" :options="optionsCostoRicerca" clearable outlined dense />
                            <v-dialog ref="dialog2" v-model="modal1" :return-value.sync="form.data_asta" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.data_asta" label="Data Asta *" readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="form.data_asta" scrollable >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog2.save(form.data_asta)" > OK </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            <div class="boxed">
                                <div class="boxedTitle">PROVVIGIONE</div>
                                
                                <span class="sliderTitle">Provvigione in Percentuale *</span> 
                                <v-slider v-model="form.provvigione.perc" :thumb-size="24" step="0.01" min="0" max="5" thumb-label="always" hint="Provvigione in Percentuale *" dense ></v-slider>
                          
            
                                <vuetify-money v-model="form.provvigione.euro_num" label="Provvigione in Euro *" :options="optionsCostoRicerca" clearable outlined dense />
                            
                                <v-text-field v-model="form.provvigione.euro_let" label="Provvigione in Lettere *" clearable outlined dense></v-text-field>
                                    
                            </div>
                            <v-divider></v-divider>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn depressed color="inAsta" @click="InAsta()" > IN ASTA </v-btn>
                                <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                            </v-card-actions>

                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
    data() {
        return {
            dialog:false,
            modal1:false,
            form:{
                _id:null,
                importo_asta:null,
                data_asta:null,
                provvigione:{
                    perc:0,
                    euro_num:null,
                    euro_let:'',
                }
            },
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['inAstaIncaricoAction']),
        InAsta(){
            if(!this.form.importo_asta){
                return this.$notify({ group: 'baseNotification', title: 'In Asta', text: 'Importo Asta Necessario', duration: 2000,type: 'error'});
            }
            if(!this.form.data_asta){
                return this.$notify({ group: 'baseNotification', title: 'In Asta', text: 'Data Asta Necessario', duration: 2000,type: 'error'});
            }
            if(!this.form.provvigione.perc){
                return this.$notify({ group: 'baseNotification', title: 'In Asta', text: 'Percentuale Provvigione Necessaria', duration: 2000,type: 'error'});
            }
            if(!this.form.provvigione.euro_num){
                return this.$notify({ group: 'baseNotification', title: 'In Asta', text: 'Euro Provvigione Necessaria', duration: 2000,type: 'error'});
            }
            if(!this.form.provvigione.euro_let){
                return this.$notify({ group: 'baseNotification', title: 'In Asta', text: 'Euro in Lettere Provvigione Necessaria', duration: 2000,type: 'error'});
            }

            this.toogleLoading(true)
            this.inAstaIncaricoAction(this.form).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'In Asta:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.dialog = false;
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'In Asta:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.dialog = false;
                this.toogleLoading(false)
            })
            
        }
    },
    mounted() {
        this.form._id = this.incarico._id;
        this.form.importo_asta = this.incarico.importo_asta;
        this.form.data_asta = this.incarico.data_asta;
        if(this.incarico.provvigione){
            this.form.provvigione = this.incarico.provvigione;
        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 0px;
}
.form{

    .fields{
        padding: 20px;
    }
}
.boxed{
    box-sizing: border-box;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    .boxedTitle{
        position: absolute;
        color: rgb(194, 194, 194);
        background: white;
        padding: 0px 5px;
        font-size: 9pt;
        line-height: 9px;
        top: -4px;
    }
}

.sliderTitle{
    font-size: 9pt;
    margin-left: 15px;
}
</style>