<template>
    <div id="videoFormazione">
        <div class="videoFormazione elevation-9">
            <span class="data">{{formatDate(videoFormazione.data)}}</span>
            <div class="titolo"> {{videoFormazione.titolo}} </div>
            <iframe :src="'https://player.vimeo.com/video/'+videoFormazione.urlVideo" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            <div class="description">
                <p class="descrizioneTesto">{{videoFormazione.descrizione}}</p>
            </div>
            <div class="description">
                <span class="hashtag" v-for="(hashtag,index) in videoFormazione.hashtags" :key="index">#{{hashtag}}</span>
            </div>
            <div style="clear:both;"></div>
            <div class="actions">
                <v-btn color="orange" small :to="'/admin/videoFormazione-modifica/'+videoFormazione._id"> MODIFICA </v-btn>
    
                <v-dialog width="500" >    
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" small v-bind="attrs" v-on="on"> CANCELLA </v-btn>
                    </template>          
                    <v-card>
                        <v-card-title><span class="text-h5">Cancella Video Formazione</span></v-card-title>
                        <v-card-text>
                            Sei sicuro di voler <b class="redText">cancellare</b> il video formazione <br>( {{videoFormazione.titolo}} )?
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="cancella(videoFormazione._id)" > CANCELLA </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['videoFormazione'],
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['deleteVideoFormazione']),
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        },
        cancella(id){
            this.toogleLoading(true)
            this.deleteVideoFormazione(id).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Video Formazione:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.videoFormazione{
    border-radius: 15px;
    padding-top: 10px;
    max-width: 300px;
    height: 100%;
}

.titolo{
    font-size: 15pt;
    line-height: 20px;
    font-weight: 600;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    color: rgba(0, 0, 0, 0.705);
}
.description{
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
}
.descrizioneTesto{
    font-size: 10pt;
    line-height: 14px;
    margin-bottom: 0px;
}

.data{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 8pt;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
    padding: 1px 3px 1px 3px;
    border-radius: 5px;
    width: max-content;
    margin-left: 10px;
}
.hashtag{
    float: left;
    margin: 5px 2px 0px 2px;
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
    padding: 1px 3px 1px 3px;
    font-size: 9pt;
    color: white;
    border-radius: 5px;
}
.actions{
    display: flex;
    padding: 10px;
    column-gap: 5px;
    .v-btn{
        flex:1;
    }
}
</style>