<template>
    <div id="calendario">
        <div class="mainTitle">CALENDARIO</div>
        <CalendarioIncarichi :incarichi="incarichi" />
    </div>
</template>

<script>
import CalendarioIncarichi from '../../components/incarichi/calendarioIncarichi.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    components:{CalendarioIncarichi},
    computed: mapGetters(['incarichi','retrivedIncarichi']),
    methods: {
        ...mapActions(['fetchAllIncarichiFromUser']),
    },
    inject: ['toogleLoading'],
    mounted() {
        if(!this.retrivedIncarichi){
            this.toogleLoading(true)
            this.fetchAllIncarichiFromUser().then(()=>{
                this.toogleLoading(false);
            }).catch(()=>{this.toogleLoading(false)});
        }
    },
    
}
</script>