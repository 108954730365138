<template>
    <div id="creaIncarico">
        <div class="mainTitle">CREA INCARICO</div>
        <div class="createForm elevation-9">
            <v-form ref="form" @submit.prevent="createIncarico" v-model="isFormValid">
                <v-row>
                    <v-col cols="12" sm="6">
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">AGENTE</div>
                        </div>
                        <model-select class="mb-20" :options="agentiOptions" v-model="form.agente" placeholder="Agente *"></model-select>
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">UTENTE</div>
                        </div>
                        <v-row>
                            <v-col><v-text-field v-model="form.utente.nome" label="Nome" clearable outlined dense></v-text-field></v-col>
                            <v-col><v-text-field v-model="form.utente.cognome" label="Cognome" clearable outlined dense></v-text-field></v-col>
                        </v-row>
                        <v-text-field v-model="form.utente.email" :rules="emailRules" label="Email" clearable outlined dense></v-text-field>
                        <v-text-field v-model="form.utente.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">DATI RICERCA</div>
                        </div>
                            <vuetify-money v-model="form.costo_ricerca.prezzo" label="Costo Ricerca" :options="optionsCostoRicerca" clearable outlined dense />
                            <v-dialog ref="dialog" v-model="modal1" :return-value.sync="form.data_inizio" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.data_inizio" label="Data Inizio." readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                            </template>
                            <v-date-picker v-model="form.data_inizio" scrollable >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(form.data_inizio)" > OK </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <div class="divisore">
                                <v-divider></v-divider>
                                <div class="divisoreTitolo">DOCUMENTI</div>
                            </div>
                        <fileUploader ref="fileUploader"/>
                    </v-col>
                </v-row>
                <v-btn block color="mainBtn" type="submit" :disabled="!isFormValid">
                    CREA
                </v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import fileUploader from '../../components/admin/documentsUploader.vue'
import { ModelSelect } from 'vue-search-select'
import { mapActions,mapGetters } from 'vuex'
export default {
    components:{fileUploader,ModelSelect},
    inject: ['toogleLoading'],
    data() {
        return {
            modal1:false,
            isFormValid:false,
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
            agentiOptions:[],
            form:{
                agente:'',
                utente:{
                    nome:'',
                    cognome:'',
                    email:'',
                    cellulare:'',
                },
                costo_ricerca:{
                    prezzo:''
                },
                data_inizio:this.today,
                documenti:[],
            },
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            emailRules: [
                v => !v || /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  'E-mail non valida',
            ],
        }
    },
    computed: mapGetters(['retrivedUsers','users']),
    methods: {
        ...mapActions(['adminGetAllUsers','createIncaricoFromAdmin']),
        setupAgenti(){
            this.users.forEach(user => {
                this.agentiOptions.push({
                    value: user._id,
                    text: user.email,
                })
            });
            this.toogleLoading(false);
        },
        createIncarico(){
            if(!this.form.agente){return this.$notify({
                    group: 'baseNotification',
                    title: 'Creazione Incarico',
                    text: 'Seleziona un agente',
                    duration: 2000,
                    type: 'error'
            });}
            this.toogleLoading(true);
            this.form.documenti = this.$refs.fileUploader.files?this.$refs.fileUploader.files:[];
            this.createIncaricoFromAdmin({form: this.form, files:this.$refs.fileUploader.inputFiles}).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Creazione Incarico',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                })
                this.toogleLoading(false);
                this.$router.push('/admin/incarichi/'+response.incarico_id);
            }).catch(()=>{
                this.toogleLoading(false);
            })
        }
    },
    mounted() {
        if(!this.retrivedUsers){
            this.toogleLoading(true);
            this.adminGetAllUsers().then(this.setupAgenti)
        }else{
            this.toogleLoading(true);
            this.setupAgenti()
        }
        var today = new Date();
        var mese,giorno;
        if(today.getMonth()+1<10){mese = '0'+(today.getMonth()+1)}else{mese = (today.getMonth()+1)}
        if(today.getDate()<10){giorno = '0'+(today.getDate())}else{giorno = (today.getDate())}
        this.form.data_inizio = today.getFullYear()+'-'+mese+'-'+giorno;
    },
}
</script>

<style lang="scss" scoped>
.createForm{
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    padding: 30px;
    border-radius: 15px;
}
.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.mb-20{
    margin-bottom: 20px;
} 
</style>