import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'

const state = {
    immobili:[],
    agency:[],
    retrivedImmobili:false,
    immobiliInfo:{
        minPrezzo:null,
        minPrezzoRef:null,
        maxPrezzo:null,
        maxPrezzoRef:null,
        minMq:null,
        minMqRef:null,
        maxMq:null,
        maxMqRef:null,
        regioni:[],
        comune:[],
        categorie:[],
        offerte:0,
    },
    agencyInfo:{
        minPrezzo:null,
        minPrezzoRef:null,
        maxPrezzo:null,
        maxPrezzoRef:null,
        minMq:null,
        minMqRef:null,
        maxMq:null,
        maxMqRef:null,
        regioni:[],
        comune:[],
        categorie:[],
    },
    immobiliStorico:[],
    retrivedImmobiliStorico:false,
}

const getters = {
    immobili: state => state.immobili,
    agency: state => state.agency,
    retrivedImmobili: state => state.retrivedImmobili,
    immobiliInfo: state => state.immobiliInfo,
    agencyInfo: state => state.agencyInfo,
    immobiliStorico: state => state.immobiliStorico,
    retrivedImmobiliStorico: state => state.retrivedImmobiliStorico,
}

const actions = {
    async fetchAllImmobili({commit}){
        var response = await axios.get(Server+'/immobili/fetchAll');
        commit("fetchAllImmobiliFun",response.data)
    },
    async fetchImmobiliStorico({commit}){
        var token;
        if(cookie.get('adminToken')!=null){
            token = cookie.get('adminToken');
        }
        const config = {headers: { Authorization: `Bearer ${token}` }};
        var response = await axios.get(Server+'/immobili/storico',config);
        commit("fetchImmobiliStoricoFun",response.data)
    },
    async editImmobiliImperdibili({commit},data){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/immobili/editImmobiliImperdibili',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editImmobiliImperdibiliFun",response.data);
                resolve(response.data); 
            })
        })
    }
}

const mutations = {
    fetchAllImmobiliFun(state,response){
        response.immobili.forEach(immobile=>{
            if(immobile.asta){
                state.immobili.push(immobile);
            }else{
                state.agency.push(immobile);
            }
        })
        getImmobiliInfo(state);
        getAgencyInfo(state);
        state.retrivedImmobili = true;

    },
    fetchImmobiliStoricoFun(state,response){
        state.immobiliStorico = response.storico;
        state.retrivedImmobiliStorico = true;
    },
    editImmobiliImperdibiliFun(state,response){
        state.immobili = response.immobili;
        state.retrivedImmobili = true;
    }
}

function getImmobiliInfo(state){
    state.immobili.forEach(immobile => {
        if(immobile.offertaImperdibile){
            state.immobiliInfo.offerte++;
        }
        if(immobile.categoria){
            immobile.categoria.forEach(categoria=>{
                var index = state.immobiliInfo.categorie.findIndex(ele => ele.nome == categoria.nome);
                if(index==-1){
                    state.immobiliInfo.categorie.push({nome:categoria.nome,quantita:1});
                }else{
                    state.immobiliInfo.categorie[index].quantita++;
                }
            })
        }
        if(immobile.prezzo){
            if(immobile.prezzo < state.immobiliInfo.minPrezzo || state.immobiliInfo.minPrezzo==null){
                state.immobiliInfo.minPrezzo = immobile.prezzo;
                state.immobiliInfo.minPrezzoRef = immobile._id;
            }
            if(immobile.prezzo > state.immobiliInfo.maxPrezzo || state.immobiliInfo.maxPrezzo==null){
                state.immobiliInfo.maxPrezzo = immobile.prezzo;
                state.immobiliInfo.maxPrezzoRef = immobile._id;
            }
        }
        if(immobile.metriQuadri){
            var floattedMq = Number.parseFloat(immobile.metriQuadri) 
            if(floattedMq < state.immobiliInfo.minMq || state.immobiliInfo.minMq==null){
                state.immobiliInfo.minMq = floattedMq;
                state.immobiliInfo.minMqRef = immobile._id;
            }
            if(floattedMq > state.immobiliInfo.maxMq || state.immobiliInfo.maxMq==null){
                state.immobiliInfo.maxMq = floattedMq;
                state.immobiliInfo.maxMqRef = immobile._id;
            }
        }
        if(immobile.posizione.regione){
            const index = state.immobiliInfo.regioni.findIndex(regione => regione.nome==immobile.posizione.regione)
            if(index==-1){
                state.immobiliInfo.regioni.push({nome:immobile.posizione.regione,quantita:1})
            }else{
                state.immobiliInfo.regioni[index].quantita++;
            }
        }
        if(immobile.posizione.comune){
            const index = state.immobiliInfo.comune.findIndex(comune => comune.nome==immobile.posizione.comune)
            if(index==-1){
                state.immobiliInfo.comune.push({nome:immobile.posizione.comune,quantita:1})
            }else{
                state.immobiliInfo.comune[index].quantita++;
            }
        }
    });
    state.immobiliInfo.categorie.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
    state.immobiliInfo.regioni.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
    state.immobiliInfo.comune.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
}

function getAgencyInfo(state){
    state.agency.forEach(immobile => {
        if(immobile.categoria){
            immobile.categoria.forEach(categoria=>{
                var index = state.agencyInfo.categorie.findIndex(ele => ele.nome == categoria.nome);
                if(index==-1){
                    state.agencyInfo.categorie.push({nome:categoria.nome,quantita:1});
                }else{
                    state.agencyInfo.categorie[index].quantita++;
                }
            })
        }
        if(immobile.prezzo){
            if(immobile.prezzo < state.agencyInfo.minPrezzo || state.agencyInfo.minPrezzo==null){
                state.agencyInfo.minPrezzo = immobile.prezzo;
                state.agencyInfo.minPrezzoRef = immobile._id;
            }
            if(immobile.prezzo > state.agencyInfo.maxPrezzo || state.agencyInfo.maxPrezzo==null){
                state.agencyInfo.maxPrezzo = immobile.prezzo;
                state.agencyInfo.maxPrezzoRef = immobile._id;
            }
        }
        if(immobile.metriQuadri){
            var floattedMq = Number.parseFloat(immobile.metriQuadri) 
            if(floattedMq < state.agencyInfo.minMq || state.agencyInfo.minMq==null){
                state.agencyInfo.minMq = floattedMq;
                state.agencyInfo.minMqRef = immobile._id;
            }
            if(floattedMq > state.agencyInfo.maxMq || state.agencyInfo.maxMq==null){
                state.agencyInfo.maxMq = floattedMq;
                state.agencyInfo.maxMqRef = immobile._id;
            }
        }
        if(immobile.posizione.regione){
            const index = state.agencyInfo.regioni.findIndex(regione => regione.nome==immobile.posizione.regione)
            if(index==-1){
                state.agencyInfo.regioni.push({nome:immobile.posizione.regione,quantita:1})
            }else{
                state.agencyInfo.regioni[index].quantita++;
            }
        }
        if(immobile.posizione.comune){
            const index = state.agencyInfo.comune.findIndex(comune => comune.nome==immobile.posizione.comune)
            if(index==-1){
                state.agencyInfo.comune.push({nome:immobile.posizione.comune,quantita:1})
            }else{
                state.agencyInfo.comune[index].quantita++;
            }
        }
    });
    state.agencyInfo.categorie.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
    state.agencyInfo.regioni.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
    state.agencyInfo.comune.sort(function(a, b) {
        if(a.quantita > b.quantita){return -1}else{return 1}
    });
}


export default{
    state,
    getters,
    actions,
    mutations
}