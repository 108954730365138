<template>
    <div id="incarichi">
        <div class="mainTitle">INCARICHI</div>
        <div class="incarichi">
            <IncaricoPreviewAreaRiservata :incarico="incarico" v-for="incarico in incarichiFiltrati.slice(0,currentSeen)" :key="incarico._id" />    
        </div>

        <div class="btnMore">
            <v-btn color="primaryBG" dark block @click="loadMore()" v-if="this.incarichiFiltrati.length > this.currentSeen" >ALTRI {{this.incarichiFiltrati.length - this.currentSeen}}</v-btn>
        </div>

        <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="500px">
            <v-card>
                <v-toolbar dark color="primaryBG" height="40px">
                    <v-btn icon dark @click="dialog = false" > <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>Filtri</v-toolbar-title>
                </v-toolbar>
                <div class="impostazioniDati">

                    <div class="sopraField">Stato</div>
                    <v-select :items="['Tutti','In Ricerca','Ricerca Sospesa','Ricerca Conclusa','In Asta','Aggiudicata','Persa','Rinuncia Ricerca','Rinuncia Asta']" v-model="selectedFiltri" label="Stato" @change="filtringFromModalStato()" dense solo></v-select>

                    <div class="sopraField">Testo</div>
                    <v-text-field v-model="ricercaText" label="Ricerca Testo"  @change="filtringFromModalText()" dense solo></v-text-field>
                    
                    <div class="sopraField">Ordine</div>
                    <v-select :items="['Data Decrescente','Data Crescente','Ultima Modifica']" v-model="orderSelected" label="Ordine" dense solo @change="sort()"></v-select>

                    <v-row>
                        <v-col>
                            <div class="sopraField">Provvigioni</div>
                            <v-select :items="['Tutti','Pagate','Non Pagate']" v-model="provvigioniSelected" label="Provvigioni" dense solo @change="filtringFromProvvigioni()"></v-select>
                        </v-col>
                        <v-col>
                            <div class="sopraField">Ricerche</div>
                            <v-select :items="['Tutti','Pagate','Non Pagate']" v-model="ricercheSelected" label="Ricerche" dense solo @change="filtringFromRicerche()"></v-select>
                        </v-col>
                    </v-row>
                </div>

            </v-card>
        </v-dialog>


        <v-btn class="filtringButton" elevation="4" color="primaryBG" dark fab small @click="dialog=true" ><v-icon dark>mdi-filter-variant</v-icon></v-btn>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IncaricoPreviewAreaRiservata from '../../../components/area-riservata/incarichi/incarichiPreview.vue'
export default {
    data() {
        return {
            incarichiFiltrati:[],
            dialog:false,
            selectedFiltri:'Tutti',
            ricercaText:'',
            orderSelected:'Data Decrescente',
            provvigioniSelected:'Tutti',
            ricercheSelected:'Tutti',
            currentSeen:20,
        }
    },
    components:{IncaricoPreviewAreaRiservata},
    computed: mapGetters(['incarichi','retrivedIncarichi']),
    watch:{
        $route (){
            this.setup();
        },
    },
    methods: {
        ...mapActions(['fetchAllIncarichiFromUser']),
                filtringFromRicerche(){
            if(this.ricercheSelected=='Tutti'){
                if(this.$route.query.filtringRicerche){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringRicerche: null }) }); 
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringRicerche: this.ricercheSelected }) });   
            }
        },
        filtringRicerche(ricerca){
            var buoni = []
            this.incarichiFiltrati.forEach(incarico => {
                if(ricerca=='Pagate'){
                    if(incarico.costo_ricerca.pagato){
                        buoni.push(incarico);
                    }
                }

                if(ricerca=='Non Pagate'){
                    if(!incarico.costo_ricerca.pagato){
                        buoni.push(incarico);
                    }
                }
                
            });
            this.ricercheSelected = ricerca;
            this.incarichiFiltrati = buoni;
        },
        filtringFromProvvigioni(){
            if(this.provvigioniSelected=='Tutti'){
                if(this.$route.query.filtringProvvigioni){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringProvvigioni: null }) }); 
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringProvvigioni: this.provvigioniSelected }) });   
            }
        },
        filtringProvvigioni(provvigione){
            var buoni = []
            this.incarichiFiltrati.forEach(incarico => {
                if(provvigione=='Pagate'){
                    if(incarico.provvigione.pagato){
                        buoni.push(incarico);
                    }
                }

                if(provvigione=='Non Pagate'){
                    if(!incarico.provvigione.pagato){
                        buoni.push(incarico);
                    }
                }
                
            });
            this.provvigioniSelected = provvigione;
            this.incarichiFiltrati = buoni;
        },
        filtringStato(stato){
            var buoni = []
            this.incarichiFiltrati.forEach(incarico => {
                if(incarico.stato == stato){
                    buoni.push(incarico);
                }
            });
            this.selectedFiltri = stato;
            this.incarichiFiltrati = buoni;
        },
        filtringText(name){
            var buoni = [];
            this.incarichiFiltrati.forEach(incarico => {
                if(String(incarico.agente.nome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }else if(String(incarico.agente.cognome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }else if(String(incarico.agente.email).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }else if(String(incarico.utente.nome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }else if(String(incarico.utente.cognome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }else if(String(incarico.utente.email).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(incarico)
                }
            });
            this.incarichiFiltrati = buoni;
            this.ricercaText = name;
        },
        filtring(){
            this.$parent.$parent.$parent.toogleLoading(true)
            this.incarichiFiltrati = this.incarichi;
            if(this.$route.query.filtringStato){
                this.filtringStato(this.$route.query.filtringStato);
            }
            if(this.$route.query.filtringText){
                this.filtringText(this.$route.query.filtringText);
            }
            if(this.$route.query.filtringProvvigioni){
                this.filtringProvvigioni(this.$route.query.filtringProvvigioni);
            }

            if(this.$route.query.filtringRicerche){
                this.filtringRicerche(this.$route.query.filtringRicerche);
            }
            this.$parent.$parent.$parent.toogleLoading(false)
        },
        filtringFromModalStato(){
            if(this.selectedFiltri=='Tutti'){
                if(this.$route.query.filtringStato){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringStato: null }) }); 
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringStato: this.selectedFiltri }) });   
            }
        },
        filtringFromModalText(){
            if(this.ricercaText==''){
                if(this.$route.query.filtringText){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringText: null }) });  
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringText: this.ricercaText }) });   
            }
        },
        sort(){
            this.$parent.$parent.$parent.toogleLoading(true)
            if(this.orderSelected=='Data Decrescente'){
                this.incarichiFiltrati = this.incarichiFiltrati.sort((a,b)=>{
                    if(a.data_inizio > b.data_inizio){return -1}
                    else if(b.data_inizio > a.data_inizio){return 1}
                    else{return 0}
                })
            }else if(this.orderSelected=='Data Crescente'){
                this.incarichiFiltrati = this.incarichiFiltrati.sort((a,b)=>{
                    if(a.data_inizio > b.data_inizio){return 1}
                    else if(b.data_inizio > a.data_inizio){return -1}
                    else{return 0}
                })
            }else if(this.orderSelected=='Ultima Modifica'){
                this.incarichiFiltrati = this.incarichiFiltrati.sort((a,b)=>{
                    if(a.ultima_modifica > b.ultima_modifica){return -1}
                    else if(b.ultima_modifica > a.ultima_modifica){return 1}
                    else{return 0}
                })
            }
            this.$parent.$parent.$parent.toogleLoading(false)
        },
        setup(){
            if(!this.retrivedIncarichi){
                this.$parent.$parent.$parent.toogleLoading(true)
                this.fetchAllIncarichiFromUser().then(()=>{
                    this.filtring();
                    this.sort();
                    this.$parent.$parent.$parent.toogleLoading(false)
                }).catch(()=>{this.$parent.$parent.$parent.toogleLoading(false)});
            }else{
                this.filtring();
                this.sort();
            }  
        },
        loadMore(){
            if(this.incarichiFiltrati.length - this.currentSeen > 10){
                this.currentSeen = this.currentSeen+10;
            }else{
                this.currentSeen = this.incarichiFiltrati.length;
            }
        },
    },
    mounted() {
        this.setup();
    },
}
</script>

<style lang="scss" scoped>
.incarichi{
    max-width: 1200px;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin: 30px auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.filtringButton{
    position: fixed;
    bottom: 10px; right:10px;
}

.impostazioniDati{
    padding: 20px;
}

.sopraField{
    font-size: 10pt;
    color: rgb(82, 82, 82);
    font-weight: 500;
}
.btnMore{
    max-width: 800px;
    margin: 0 auto 80px auto;
    padding: 0px 6%;
}
</style>