<template>
    <div id="immobilePreview">
            <div class="immobile elevation-9">
                <div class="offertaImperdibile" v-if="immobile.offertaImperdibile">OFFERTA<br>IMPERDIBILE</div>
                <div class="fotos">
                    <carousel :perPage="1" :paginationEnabled="false" v-if="immobile.foto.length>0">
                        <slide v-for="(item,i) in immobile.foto" :key="i" :src="item" class="slideItem">
                            <div class="carousellImg" :style="'background-image: url(\''+item+'\')'"></div>
                            <div class="position">{{i+1}}/{{immobile.foto.length}}</div>
                        </slide>
                    </carousel>
                    <div class="noPhoto notSelectableTxt" v-else>
                        <v-icon>mdi-camera-off</v-icon>
                        <div>NESSUNA FOTO</div>
                    </div>
                </div>
                <router-link :to="immobile.asta?'/immobili/'+immobile._id:'/agency/'+immobile._id">
                <div class="dati notSelectableTxt">
                    <div class="categoria">
                        <div class="content">
                            <span v-for="(categoria,index) in immobile.categoria" :key="index">{{categoria.nome}}</span>
                        </div>
                    </div>
                    <div class="posizione">{{immobile.posizione.comune}} ({{immobile.posizione.provincia}}) - {{immobile.posizione.regione}} ({{immobile.posizione.nazione}})</div>
                    <div class="localiMq"><span v-if="immobile.camere">{{immobile.camere.totale}}<span class="unita">Locali</span> -</span>  {{immobile.metriQuadri}}<span class="unita">M2</span> </div>
                    <div class="descrizione"><div class="content">{{immobile.descrizione}}</div></div>
                    <div class="prezzo" v-if="immobile.asta">Da <span>{{moneyFormat(immobile.prezzo)}}€</span></div>
                    <div class="prezzo" v-if="!immobile.asta"><span>{{moneyFormat(immobile.prezzo)}}€</span></div>
                </div>
                </router-link>
            </div>
        
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    components: {
        Carousel,
        Slide
    },
    props:['immobile'],
    methods: {
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        }
    },
}
</script>

<style lang="scss" scoped>
.immobile{
    height: 226px;
    width: max-content;
    display: flex;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 0px;

    transition-duration: 0.2s;
    position: relative;
    .offertaImperdibile{
        position: absolute;
        top:10px;left:10px;
        background-color: rgb(78, 195, 78);
        color: white;
        font-weight: 800;
        font-size: 8pt;
        padding: 3px 5px;
        border-radius: 10px;
        text-align: center;
        line-height: 9px;
        z-index: 5;
    }
    @media screen and (max-width: 740px) {
        height: max-content;
        flex-flow: column;
    }
    @media screen and (max-width: 455px) {
        width: 100%;
    }
    @media (hover: hover) {
        &:hover{
            transition-duration: 0.2s;
            transform: scale(1.1);
        }
    }
    .open{
        position: absolute;
        top:5px;right:5px;
    }
    .fotos{
        width: 300px;
        height: 100%;
        min-height: 226px;
        position: relative;
        @media screen and (max-width: 740px) {
            width: 100%;
        }
        .carousellImg{
            height: 226px;
            background-size: cover;
            background-position: center center;
        }
        .noPhoto{
            position: absolute;
            top:50%;left:50%;
            transform: translateX(-50%) translateY(-50%);
            color: rgb(194, 194, 194);
            font-weight: 600;
            text-align: center;
            line-height: 15px;
            .v-icon{
                font-size: 40pt;
                color: rgb(194, 194, 194);
            }
        }
    }
    .dati{
        max-width: 400px;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-flow: column;
        color: rgb(54, 54, 54);
        .categoria{
            .content{
                color: var(--primaryColor);
                font-size: 15pt;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;  
                span{&::before{content: ', ';}}
                span:first-child{
                    &::before{content: '';}
            }

            }

        }
        .posizione{
            font-size: 10pt;
        }
        .localiMq{
            .unita{
                font-size: 9pt;
            }
        }
        .descrizione{
            .content{
                color: rgba(128, 128, 128, 0.685);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;    
            }

            flex-grow: 1;
        }
        .prezzo{
            text-align: right;
            align-self: flex-end;
            span{
                color: var(--primaryColor);
                font-weight: 700;
                font-size: 20pt;
            }
        }
    }
}

.slideItem{
    position: relative;
    .position{
        background-color: rgba(0, 0, 0, 0.123);
        font-size: 9pt;
        backdrop-filter: blur(5px);
        position: absolute;
        color: white;
        z-index: 6;
        bottom:5px;
        padding: 3px 5px;
        border-radius: 5px;
        font-weight: 600;
        right:5px;
    }
}
</style>