<template>
    <div id="calendarioIncarichi" v-if="incarichiRetrived">
        <div class="fullCalendar">
          <v-row class="fill-height">
            <v-col>
            <v-sheet height="64" class="topBar ">
                <v-toolbar flat >
                <v-btn outlined class="mr-4" color="white darken-2" @click="setToday" > Oggi </v-btn>
                <v-btn fab text small color="white darken-2" @click="prev" >
                    <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="white darken-2" @click="next" >
                    <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="white darken-2" v-bind="attrs" v-on="on" >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                    </template>
                    <v-list :v-model="type">
                    <v-list-item @click="type = 'day'" :class="type == 'day'?'active':''">
                        <v-list-item-title>Giorno</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'" :class="type == 'week'?'active':''">
                        <v-list-item-title>Settimana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'" :class="type == 'month'?'active':''">
                        <v-list-item-title>Mese</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'" :class="type == '4day'?'active':''">
                        <v-list-item-title>4 Giorni</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <div class="calendarioContainer elevation-9">
                <div class="calendario">
            <v-sheet height="600">
                <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay" :weekday-format="myDayFormat"
                :weekdays="weekday"></v-calendar>
                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x >
                    <v-card class="openedCard" color="white" flat >
                        <v-toolbar :color="selectedEvent.color" dark >
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div v-if="selectedEvent.tipo=='evento'">
                                <v-btn v-if="isAdmin || selectedEvent.evento.isAgente"  icon :to="isAdmin?'/admin/modificaEvento/'+selectedEvent.id:'/area-riservata/modificaEvento/'+selectedEvent.id">   
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                            <v-btn v-if="selectedEvent.tipo=='asta'" icon :to="isAdmin?'/admin/incarichi/'+selectedEvent.id:'/area-riservata/incarichi/'+selectedEvent.id">   
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text v-if="selectedEvent.tipo=='asta' && selectedEvent.incarico">
                            <div class="userChip"><UserChipSmall :agente="selectedEvent.incarico.agente" v-if="isAdmin" /></div>
                            <div class="boxed" v-if="selectedEvent.incarico.utente">
                                <div class="boxedTitle">UTENTE</div>
                                <div class="field"><span>Nome:</span> {{selectedEvent.incarico.utente.nome}} {{selectedEvent.incarico.utente.cognome}}</div>
                                <div class="field" v-if="selectedEvent.incarico.utente.email"><span>Email:</span> {{selectedEvent.incarico.utente.email}}</div>
                                <div class="field" v-if="selectedEvent.incarico.utente.cellulare"><span>Cellulare:</span> {{selectedEvent.incarico.utente.cellulare}}</div>
                            </div>
                            <div class="boxed" v-if="selectedEvent.incarico.immobile">
                                <div class="boxedTitle">IMMOBILE</div>
                                <div class="field"><span>Indirizzo:</span> {{selectedEvent.incarico.immobile.indirizzo}}</div>
                                <div class="field"><span>Valore:</span> {{moneyFormat(selectedEvent.incarico.immobile.valore)}}€</div>
                            </div>

                        </v-card-text>
                        <v-card-text v-if="selectedEvent.tipo=='evento'">
                            <div v-if="isAdmin" class="userChip userChipEvento"><UserChipSmall :agente="selectedEvent.evento.agente" /></div>
                            <div class="eventoDescrizione">{{selectedEvent.evento.descrizione}}</div>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-sheet>
            </div>
            </div>
            </v-col>
        </v-row>
        
        <div class="legenda">
            <div class="elemento">
                <div class="box primaryBG"></div>
                <div class="testo">ASTA</div>
            </div>
            <div class="elemento">
                <div class="box orange"></div>
                <div class="testo">EVENTO AMMINISTRAZIONE</div>
            </div>
            <div class="elemento">
                <div class="box grey"></div>
                <div class="testo">EVENTO {{isAdmin?'UTENTE':'PERSONALE'}}</div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserChipSmall from '../users/UserChipSmall.vue'
  export default {
    components:{
        UserChipSmall
    },
    props:['incarichi'],
    data: () => ({
        incarichiRetrived:false,
        isAdmin:false,
        focus: '',
        type: 'month',
        weekday: [1,2,3,4,5,6,0],
        typeToLabel: {
            month: 'Mesi',
            week: 'Settimane',
            day: 'Giorni',
            '4day': '4 Giorni',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
    }),
    watch:{
        incarichi(){
            this.setupEvents();
        },
        type(){
            if(this.type == 'month'){
                if(!this.$route.query.type){
                    return;
                }
                if(this.isAdmin){
                    return this.$router.push('/admin/calendario')    
                }else{
                    return this.$router.push('/area-riservata/calendario') 
                }
            }

            if(this.type!=this.$route.query.type){
                this.$router.push({ query: Object.assign({}, this.$route.query, { type: this.type }) });     
            }          
        },
        $route (){
            if(!this.$route.query.type){
                return this.type = 'month'
            }

            if(this.$route.query.type == 'month'){
                return this.type = 'month'
            }
            this.type = this.$route.query.type;
        }
    },
    computed: mapGetters(['eventi','retrivedEventi']),
    mounted () {
        // :weekdays="[1,2,3,4,5,6,0]"
      //this.$refs.calendar.checkChange();
      if(this.$cookies.get('adminToken')){this.isAdmin=true}
      if(Object.keys(JSON.parse(JSON.stringify(this.incarichi))).length != 0){
          this.setupEvents();
      }
      if(!this.retrivedEventi){
          this.fecthAllEventi().then(()=>{this.setupEventi()});
      }else{
          this.setupEventi();
      }
    },
    methods: {
        ...mapActions(['fecthAllEventi']),
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        },
        myDayFormat(d){
            var settimana = ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']
            return settimana[d.weekday];
        },
        setupEvents(){
            this.incarichiRetrived = true;
            this.incarichi.forEach(incarico => {
                if(incarico.stato=='In Asta'){  
                    this.events.push({
                        name: 'ASTA: '+incarico.utente.nome+' '+incarico.utente.cognome,
                        tipo:'asta',
                        id: incarico._id,
                        incarico: incarico,
                        start: incarico.data_asta,
                        color: 'primaryBG',
                        timed: true,
                    })
                }
                
            });
        },
        setupEventi(){
            this.eventi.forEach(evento=>{
                if(evento.allDay){
                    this.events.push({
                        name: evento.titolo,
                        tipo:'evento',
                        id:evento._id,
                        evento:evento,
                        start: evento.dataInizio,
                        color: evento.isAgente?'grey':'orange',
                        timed: evento.allDay,
                    })    
                }else{
                    this.events.push({
                        name: evento.titolo,
                        tipo:'evento',
                        id:evento._id,
                        evento:evento,
                        start: evento.dataInizio,
                        end: evento.dataFine,
                        color: evento.isAgente?'grey':'orange',
                        timed: evento.allDay,
                    }) 
                }
                
            })
        },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>

<style lang="scss" scoped>
.fullCalendar{
    max-width: 1200px;
    margin: 0 auto;
}
.calendarioContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.calendarioContainer::-webkit-scrollbar-track {
  background: transparent; 
}
.calendarioContainer::-webkit-scrollbar-thumb {
  background: var(--primaryColor); 
  border-radius: 10px;
}
.calendarioContainer::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor); 
}

.topBar{
    .theme--light.v-toolbar.v-sheet{
        background-color: var(--primaryColor);
        color: white;
        border-radius: 15px 15px 0px 0px; 
    }
    
}
.calendarioContainer{
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    border-radius: 0px 0px 15px 15px;
    padding:10px;
    overflow: hidden;
    overflow-x: auto;
    .calendario{
        min-width: 550px;
        padding: 5px;

    }
}
.userChip{
    width: max-content;
    margin: 0px auto;
}
.boxed{
    border: 1px solid rgb(148, 148, 148);
    margin-top: 20px;
    border-radius: 5px;
    position: relative;
    padding: 5px;
    padding-top: 15px;
    .boxedTitle{
        position: absolute;
        top:-10px;left:50%;
        background: white !important;
        padding: 0px 5px;
        font-size: 9pt;
        font-weight: 700;
        transform: translateX(-50%);
    }
    .field{
        font-size: 9pt;
        line-height: 15px;
        span{font-weight: 600;}
    }
}
.active{
    background: var(--primaryColor);
    transition-duration: 0.2s;
    .v-list-item__title{
        transition-duration: 0.2s;
        color:white!important;    
    }
    
}
.v-menu__content{
    border-radius: 15px;
    overflow: hidden;
    margin-left: 5px;
    margin-top: -22px;
}

.userChipEvento{
    margin-bottom: 10px;
}

.legenda{
    margin-top: 30px;
    .elemento{
        display: flex;
        margin-bottom: 5px;
        .box{
            width: 50px;
            height: 20px;
            border-radius: 5px;
            font-weight: 700;
            color: white;
            padding-left: 2px;
            .v-icon{
                font-size: 15pt;
                margin-top: -4px;
            }
        }
        .testo{
            margin-left: 10px;
            font-size: 9pt;
            line-height: 20px;
        }
    }
}
</style>