<template>
    <div id="generale">
        <div class="generale">
            <div class="informazioni elevation-4">
                <IncaricoStato :stato="incarico.stato" />

                <span class="dataInizio">{{formatDate(incarico.data_inizio)}}</span>

                <v-chip class="ma-2" small color="red" text-color="white" >
                    Perse
                    <v-avatar right class="red darken-4" > {{incarico.numero_perse}} </v-avatar>
                    
                </v-chip>

                <div class="agenteChip"><IncaricoUserChip :incarico="incarico" /></div>
                
                
                <IncaricoInfo :incarico="incarico" />
            </div>
            <div class="actions elevation-4">
                <SospendiRicerca :incarico="incarico" v-if="incarico.stato=='In Ricerca'" />
                <RiprendiRicerca :incarico="incarico" v-if="incarico.stato=='Ricerca Sospesa'"/>
                <RicercaConclusa :incarico="incarico" v-if="incarico.stato=='In Ricerca'" />
                <RinunciaRicerca :incarico="incarico" v-if="incarico.stato=='In Ricerca'" />
                <InAsta :incarico="incarico" v-if="incarico.stato=='Ricerca Conclusa'"/>
                <AggiudicataAsta :incarico="incarico" v-if="incarico.stato=='In Asta'" />
                <RinunciaAsta :incarico="incarico" v-if="incarico.stato=='In Asta'" />
                <PersaAsta :incarico="incarico" v-if="incarico.stato=='In Asta'" />
                <DesertaAsta :incarico="incarico" v-if="incarico.stato=='In Asta'" />      
                <div v-if="incarico.costo_ricerca || incarico.provvigione">       
                    <div class="divisore" v-if="!incarico.costo_ricerca.pagato || (incarico.stato=='Aggiudicata' && !incarico.provvigione.pagato)">
                        <v-divider></v-divider>
                        <div class="divisoreTitolo">AZIONI ADMIN</div>
                    </div>
                    <div class="adminActions">
                        <RicercaPagata :incarico="incarico" v-if="!incarico.costo_ricerca.pagato" />
                        <ProvvigionePagata :incarico="incarico" v-if="incarico.stato=='Aggiudicata' && !incarico.provvigione.pagato" />
                    </div>
                    <div v-if="(incarico.stato == 'Aggiudicata' || incarico.stato == 'Persa' || incarico.stato == 'Rinuncia Ricerca' || incarico.stato == 'Rinuncia Asta') && incarico.costo_ricerca.pagato && incarico.provvigione.pagato" class="nessunaAzione notSelectableTxt">
                        <v-icon>mdi-check-circle</v-icon>
                        <div>NESSUNA AZIONE </div>
                        
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
</template>

<script>
import SospendiRicerca from '../../incarichi_actions/sospendiRicerca.vue'
import RiprendiRicerca from '../../incarichi_actions/riprendiRicerca.vue'
import RicercaConclusa from '../../incarichi_actions/ricercaConclusa.vue'
import RinunciaRicerca from '../../incarichi_actions/rinunciaRicerca.vue'
import InAsta from '../../incarichi_actions/inAsta.vue'
import AggiudicataAsta from '../../incarichi_actions/aggiudicataAsta.vue'
import RinunciaAsta from '../../incarichi_actions/rinunciaAsta.vue'
import PersaAsta from '../../incarichi_actions/persaAsta.vue'
import DesertaAsta from '../../incarichi_actions/desertaAsta.vue'
import RicercaPagata from '../../incarichi_actions/ricercaPagata.vue'
import IncaricoInfo from '../../incarichi/informazioniIncarico.vue'
import IncaricoStato from '../../incarichi/incaricoStato.vue'
import ProvvigionePagata from '../../incarichi_actions/provvigionePagata.vue'
import IncaricoUserChip from '../../incarichi/incaricoUserChip.vue'
export default {
    components:{SospendiRicerca,RiprendiRicerca,RicercaConclusa,RinunciaRicerca,InAsta,AggiudicataAsta,RinunciaAsta,PersaAsta,DesertaAsta,RicercaPagata,IncaricoInfo,IncaricoStato,ProvvigionePagata,IncaricoUserChip},
    props:['incarico'],
    methods: {
        formatDate(date){
            if(!date){return''}
            var data = date.split('-')
            return data[2]+'/'+data[1]+'/'+data[0];
        },
        formatDateHour(date){
            var data = new Date(date);
            var ore = data.getHours()<10?'0'+data.getHours():data.getHours();
            var minuti = data.getMinutes()<10?'0'+data.getMinutes():data.getMinutes();
            return data.getDate()+'/'+(data.getMonth()+1)+'/'+data.getFullYear()+' - '+ore+':'+minuti;
        }
    },
}
</script>

<style lang="scss" scoped>
.generale{
    display: flex;
    max-width: 1200px;
    margin: 10px auto;
    column-gap: 10px;
    row-gap: 10px;
    @media screen and (max-width: 660px) {flex-flow: column-reverse;}
}
.informazioni{
    flex: 3;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    .dataInizio{
        font-size: 10pt;
        font-weight: 500;
    }
    .ricerca{
        margin-top: 10px;
        .field{
            font-size: 11pt;
            span{
                font-size: 10pt;
                font-weight: 600;
            }
        }
    }
}
.actions{
    flex: 1;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    row-gap: 5px;
    height: max-content;
    min-height: 100px;
    position: relative;
    .nessunaAzione{
        position: absolute;
        width: 90%;
        top:50%;left:50%;
        transform: translateX(-50%) translateY(-50%);
        color: rgb(194, 194, 194);
        font-weight: 600;
        text-align: center;
        line-height: 15px;
        .v-icon{
            font-size: 40pt;
            color: rgb(194, 194, 194);
        }
    }
}

.divisore{
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}


.v-chip{
    padding: 0px 6px 0px 12px;
}

.adminActions{
    display: flex;
    flex-flow: column;
    row-gap: 5px;
}

.agenteChip{
    position: absolute;
    top:25px;right: 20px;
    @media screen and (max-width: 660px) {position: relative;top:0;right:0;}
}

</style>