<template>
    <div id="ricercaConclusa">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="ricercaConclusa" v-bind="attrs" v-on="on" > RICERCA CONCLUSA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 ricercaConclusa lighten-2"> RICERCA CONCLUSA? </v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="RicercaConclusa" class="form">
                        <div class="fields">
                            <v-text-field v-model="form.immobile.indirizzo" label="Indirizzo Immobile *" clearable outlined dense></v-text-field>
                            <vuetify-money v-model="form.immobile.valore" label="Valore Immobile *" :options="optionsCostoRicerca" clearable outlined dense /> 
                            <v-divider></v-divider>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn depressed color="ricercaConclusa" @click="RicercaConclusa()" > CONCLUDI </v-btn>
                                <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                            </v-card-actions>

                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props:['incarico'],
    data() {
        return {
            dialog:false,
            form:{
                _id:null,
                immobile:{
                    indirizzo:'',
                    valore:null,
                },
            },
            optionsCostoRicerca:{
                precision: 2,
                suffix: "€",
            },
        }
    },
    inject: ['toogleLoading'],
    mounted() {
        if(this.incarico.immobile){
            this.form.immobile = this.incarico.immobile    
        }
        this.form._id = this.incarico._id;
    },
    methods: {
        ...mapActions(['ricercaConclusaIncaricoAction']),
        RicercaConclusa(){
            if(!this.form.immobile.indirizzo){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Conclusione Ricerca',
                    text: 'Indirizzo Necessario',
                    duration: 2000,
                    type: 'error'
                });
            }
            if(!this.form.immobile.valore){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Conclusione Ricerca',
                    text: 'Valore Necessario',
                    duration: 2000,
                    type: 'error'
                });
            }
            this.toogleLoading(true)
            this.ricercaConclusaIncaricoAction(this.form).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Conclusione Ricerca:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Conclusione Ricerca:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 0px;
}
.form{

    .fields{
        padding: 20px;
    }
}
</style>