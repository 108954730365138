var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"documenti"}},[_c('div',{staticClass:"input elevation-9"},[_c('v-form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.loadDocuments.apply(null, arguments)}}},[_c('div',{staticClass:"flexed"},[_c('v-file-input',{staticClass:"input",attrs:{"multiple":"","label":"Carica"},on:{"change":_vm.fileSelected},model:{value:(_vm.inputFiles),callback:function ($$v) {_vm.inputFiles=$$v},expression:"inputFiles"}}),_c('div',{staticClass:"bottone"},[_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","fab":"","dark":"","color":"green","type":"submit"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('div',{staticClass:"files"},_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"file"},[_c('div',{staticClass:"icon"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"orangeColor"},on),[_vm._v(" mdi-cloud-upload ")])]}}],null,true)},[_c('span',[_vm._v("Da Caricare")])])],1),_c('v-text-field',{attrs:{"dense":""},model:{value:(file.nome),callback:function ($$v) {_vm.$set(file, "nome", $$v)},expression:"file.nome"}}),_c('div',{staticClass:"size"},[_vm._v("["+_vm._s(_vm.bytesToSize(file.size))+"]")])],1)}),0)])],1),_c('div',{staticClass:"tabella elevation-9"},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" NOME ")]),_c('th',{staticClass:"text-left"},[_vm._v(" FORMATO ")]),_c('th',{staticClass:"text-left"},[_vm._v(" DIMENSIONI ")]),_c('th',{staticClass:"text-left"},[_vm._v(" AZIONI ")])])]),_c('tbody',_vm._l((_vm.documenti),function(documento){return _c('tr',{key:documento._id},[_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.linkto(documento.indirizzo)}}},[_vm._v(_vm._s(documento.nome))]),_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.linkto(documento.indirizzo)}}},[_vm._v(_vm._s(_vm.getExt(documento.indirizzo)))]),_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.linkto(documento.indirizzo)}}},[_vm._v(_vm._s(_vm.bytesToSize(documento.size)))]),_c('td',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true),model:{value:(documento.modalEdit),callback:function ($$v) {_vm.$set(documento, "modalEdit", $$v)},expression:"documento.modalEdit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Modifica Documento")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.edit(documento)}}},[_c('v-text-field',{staticStyle:{"display":"none"},attrs:{"dense":"","label":"ID","required":"","disabled":""},model:{value:(documento._id),callback:function ($$v) {_vm.$set(documento, "_id", $$v)},expression:"documento._id"}}),_c('v-text-field',{attrs:{"dense":"","label":"NOME","required":""},model:{value:(documento.newName),callback:function ($$v) {_vm.$set(documento, "newName", $$v)},expression:"documento.newName"}}),_c('v-btn',{attrs:{"block":"","color":"orange","type":"submit"}},[_vm._v(" Modifica ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Cancella Documento")])]),_c('v-card-text',[_vm._v(" Sei sicuro di voler "),_c('b',{staticClass:"redText"},[_vm._v("cancellare")]),_vm._v(" il documento "),_c('br'),_vm._v("( "+_vm._s(documento.nome)+" )? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.cancella(documento)}}},[_vm._v(" CANCELLA ")])],1)],1)],1)],1)])}),0)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }