<template>
    <div id="agenziaCrea">
        <div class="mainTitle">MODIFICA AGENZIA</div>
        <v-form class="form elevation-9" @submit.prevent="submit" v-model="isFormValid">
           <v-text-field  label="NOME *" :rules="requiredRules" v-model="form.nome" required clearable outlined dense></v-text-field>
           <v-text-field  label="INDIRIZZO *" :rules="requiredRules" v-model="form.indirizzo" required clearable outlined dense></v-text-field>
           <model-select  class="mb-20" :rules="requiredRules" :options="provincieOptions" v-model="form.provincia" placeholder="PROVINCIA *"> </model-select>
           <v-row style="margin-top: -12px">
               <v-col><v-text-field :rules="requiredRules"  label="CITTA *" v-model="form.citta" required clearable outlined dense></v-text-field></v-col>
               <v-col><v-text-field :rules="requiredRules"  label="CAP *" v-model="form.cap" required clearable outlined dense></v-text-field></v-col>
           </v-row>
           <v-row style="margin-top: -22px">
               <v-col>
                   <v-text-field :rules="requiredRules" v-model="form.lat" hint="ex.55.55"  label="LATITUDINE *" required clearable outlined dense></v-text-field>
                </v-col>
               <v-col>
                   <v-text-field :rules="requiredRules" v-model="form.lng" hint="ex.55.55"  label="LONGITUDINE *" required clearable outlined dense></v-text-field>
                </v-col>
           </v-row>
           <v-btn block color="orange" type="submit" class="btnSubmit" :disabled="!isFormValid || form.provincia==undefined"> MODIFICA </v-btn>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProvincieJson from '../../../utils/provincie.json'
import { ModelSelect } from 'vue-search-select'
export default {
    components:{ModelSelect},
    computed: mapGetters(['agenzie','retrivedAgenzie']),
    data() {
        return {
            form:{
                nome:'',
                indirizzo:'',
                citta:'',
                provincia:undefined,
                cap:'',
                lat:'',
                lng:'',
            },
            provincieOptions:[],
            isFormValid:false,
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['fetchAllAgenzie','editAgenzia']),
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        setupForm(){
            const index = this.agenzie.findIndex(agenzia => agenzia._id == this.$route.params.id)
            if(index != -1){
                var agenzia = this.agenzie[index];
                this.form = {
                    _id: agenzia._id,
                    nome: agenzia.nome,
                    indirizzo: agenzia.indirizzo,
                    citta: agenzia.citta,
                    provincia: agenzia.provincia,
                    cap: agenzia.cap,
                    lat: agenzia.lat,
                    lng: agenzia.lng,
                }
                this.loadProvincie();
                this.toogleLoading(false);
            }else{
                this.toogleLoading(false);
                this.$router.push('/admin/404');
            }
        },
        submit(){
            this.toogleLoading(true);
            this.editAgenzia(this.form).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Agenzia',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false);
            }).catch((error)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Agenzia',
                    text: error.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        }
    },
    mounted() {
        if(!this.retrivedAgenzie){
            this.toogleLoading(true);
            this.fetchAllAgenzie().then(()=>{this.setupForm()});
        }else{
            this.setupForm()
        }
    },
}
</script>

<style lang="scss" scoped>
.form{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    min-height: 300px;
    border-radius: 15px;
    padding: 20px;
}

.mb-20{
    margin-bottom: 25px;
}
</style>