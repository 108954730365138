<template>
    <div id="creaEvento">
        <div class="mainTitle">MODIFICA EVENTO</div>
        <v-form class="form elevation-9" @submit.prevent="modificaEvento" v-model="isFormValid">
            <v-text-field v-model="form.titolo" :rules="requiredRules" label="Titolo" dense solo></v-text-field>
            <v-textarea v-model="form.descrizione" rows="1" auto-grow label="Descrizione" hint="Descrizione" dense solo></v-textarea>
            <v-switch v-model="form.allDay" inset :label="form.allDay?'Evento giornaliero':'Evento a tempo'" style="margin-top:-5px"></v-switch>
            <v-row>
                <v-col :cols="form.allDay?12:6">
                    <v-menu v-model="menuDataInizio" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="requiredRules" v-model="form.dataInizio" :label="form.allDay?'Data':'Data Inizio'" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense solo clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="form.dataInizio" @input="menuDataInizio = false" ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col v-if="!form.allDay">
                    <v-menu ref="menu" v-model="menuOraInizio" :close-on-content-click="false" :nudge-right="40" :return-value.sync="timeOraInizio" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="requiredRules" v-model="form.oraInizio" label="Ora Inizio" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" solo dense clearable></v-text-field>
                        </template>
                        <v-time-picker format="24hr" v-if="menuOraInizio" v-model="form.oraInizio" full-width @click:minute="$refs.menu.save(timeOraInizio)" ></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row v-if="!form.allDay" style="margin-top: -30px">
                <v-col>
                    <v-menu v-model="menuDataFine" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="requiredRules" v-model="form.dataFine" :label="'Data Fine'" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense solo clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="form.dataFine" @input="menuDataFine = false" ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-menu ref="menu" v-model="menuOraFine" :close-on-content-click="false" :nudge-right="40" :return-value.sync="timeOraFine" transition="scale-transition" offset-y max-width="290px" min-width="290px" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="requiredRules" v-model="form.oraFine" label="Ora Fine" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" solo dense clearable></v-text-field>
                        </template>
                        <v-time-picker format="24hr" v-if="menuOraFine" v-model="form.oraFine" full-width @click:minute="$refs.menu.save(timeOraFine)" ></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-btn block color="orange" type="submit" :disabled="!isFormValid"> MODIFICA </v-btn>
            <div style="text-align:center">
            <v-dialog width="500" >    
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" x-small v-bind="attrs" v-on="on"> CANCELLA </v-btn>
                </template>          
                <v-card>
                    <v-card-title><span class="text-h5">Cancellare Evento</span></v-card-title>
                    <v-card-text>
                        Sei sicuro di voler <b class="redText">cancellare</b> il video formazione <br>( {{eventoRetrived.titolo}} )?
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cancella(eventoRetrived._id)" > CANCELLA </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
export default {
    data() {
        return {
            eventoRetrived:{},
            form:{
                _id:'',
                titolo:'',
                descrizione:'',
                allDay:true,
                dataInizio:'',
                oraInizio:'',
                dataFine:'',
                oraFine:'',
                isAgente:false,
            },
            isFormValid:false,
            menuDataInizio:false,
            menuOraInizio:false,
            timeOraInizio:'',
            menuDataFine:false,
            menuOraFine:false,
            timeOraFine:'',
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['eventi','retrivedEventi']),
    methods: {
        ...mapActions(['editEvento','fecthAllEventi','deleteEvento']),
        setupForm(){
            const index = this.eventi.findIndex(evento=> evento._id == this.$route.params.id);
            if(index!=-1){
                var evento = this.eventi[index];
                this.eventoRetrived = evento;
                this.form._id = evento._id;
                this.form.allDay = evento.allDay;
                this.form.titolo = evento.titolo;
                this.form.descrizione = evento.descrizione;
                if(evento.allDay){
                    this.form.dataInizio = evento.dataInizio;
                }else{
                    var inizio = evento.dataInizio.split('/');
                    var fine = evento.dataFine.split('/');
                    this.form.dataInizio = inizio[0];
                    this.form.oraInizio = inizio[1];
                    this.form.dataFine = fine[0];
                    this.form.oraFine = fine[1];
                }
            }else{
                this.$router.replace('/admin/404');
            }
        },
        modificaEvento(){
            this.toogleLoading(true);
            this.editEvento(this.form).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Evento:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false);
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica Evento:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        },
        cancella(id){
            this.toogleLoading(true);
            this.deleteEvento({id:id}).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Evento:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$router.push('/admin/calendario');
                this.toogleLoading(false);
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione Evento:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false);
            });
        }
    },
    mounted() {
        if(!this.retrivedEventi){
          this.fecthAllEventi().then(()=>{this.setupForm()});
        }else{
            this.setupForm();
        }
    },
}
</script>

<style lang="scss" scoped>
.form{
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 15px;
}
</style>