<template>
    <div id="agenti">
        <div class="mainTitle">AGENTI</div>
        <div class="users">
            <UserPreview :user="user" v-for="user in agenti.slice(0,currentSeen)" :key="user._id"/>
        </div>

        <div class="btnMore">
            <v-btn color="primaryBG" dark block @click="loadMore()" v-if="this.agenti.length > this.currentSeen" >ALTRI {{this.agenti.length - this.currentSeen}}</v-btn>
        </div>

        <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="500px">
            <v-card>
                <v-toolbar dark color="primaryBG" height="40px">
                    <v-btn icon dark @click="dialog = false" > <v-icon>mdi-close</v-icon> </v-btn>
                    <v-toolbar-title>Filtri</v-toolbar-title>
                </v-toolbar>
                <div class="impostazioniDati">
                    <v-select :items="['Tutti','Head Executive','Top Executive','Executive','Team Leader','Auction Manager']" v-model="selectedFigura" label="Figura" @change="filtringFromModalFigura()" dense solo ></v-select>
                    <v-text-field v-model="ricercaText" label="Ricerca Testo"  @change="filtringFromModalText()" dense solo></v-text-field>
                </div>

            </v-card>
        </v-dialog>


        <v-btn class="filtringButton" elevation="4" color="primaryBG" dark fab small @click="dialog=true" ><v-icon dark>mdi-filter-variant</v-icon></v-btn>
        
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import UserPreview from '../../components/admin/user_preview.vue'
export default {
    components:{UserPreview},
    computed:{ ...mapGetters(['users','retrivedUsers'])},
    data() {
        return {
            agenti:[],
            dialog:false,
            selectedFigura:'Tutti',
            ricercaText:'',
            currentSeen:10,
        }
    },
    watch:{
        $route (){
            this.filtring()
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['adminGetAllUsers']),
        filtringFigura(figura){
            var buoni = []
            this.agenti.forEach(user => {
                if(user.figura == figura){
                    buoni.push(user);
                }
            });
            this.agenti = buoni;
            this.selectedFigura = figura;
        },
        filtringName(name){
            var buoni = [];
            this.agenti.forEach(user => {
                if(String(user.nome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(user)
                }else if(String(user.cognome).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(user)
                }else if(String(user.email).toLowerCase().includes(name.toLowerCase())){
                    buoni.push(user)
                }
            });
            this.agenti = buoni;
            this.ricercaText = name;
        },
        filtring(){
            this.toogleLoading(true)
            this.agenti = this.users;
            if(this.$route.query.filtringFigura){
                this.filtringFigura(this.$route.query.filtringFigura);
            }
            if(this.$route.query.filtringText){
                this.filtringName(this.$route.query.filtringText);
            }
            this.toogleLoading(false)
        },
        filtringFromModalFigura(){
            if(this.selectedFigura=='Tutti'){
                if(this.$route.query.filtringFigura){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringFigura: null }) }); 
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringFigura: this.selectedFigura }) });   
            }
        },

        filtringFromModalText(){
            if(this.ricercaText==''){
                if(this.$route.query.filtringText){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { filtringText: null }) });  
                }
            }else{
                this.$router.push({ query: Object.assign({}, this.$route.query, { filtringText: this.ricercaText }) });   
            }
        },
        loadMore(){
            if(this.agenti.length - this.currentSeen > 10){
                this.currentSeen = this.currentSeen+10;
            }else{
                this.currentSeen = this.agenti.length;
            }
        },
    },
    mounted() {
        if(!this.retrivedUsers){
            this.toogleLoading(true)
            this.adminGetAllUsers().then(()=>{this.toogleLoading(false);this.filtring()}).catch(()=>{
                this.toogleLoading(false)
            });
        }else{
            this.filtring()
        }
    },
}
</script>

<style lang="scss" scoped>
.users{
    display: flex;
    flex-flow:row wrap;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
}

.filtringButton{
    position: fixed;
    bottom: 10px; right:10px;
}

.impostazioniDati{
    padding: 20px;
}
.btnMore{
    max-width: 800px;
    margin: 0 auto 80px auto;
    padding: 0px 6%;
}
</style>