<template>
    <div id="uploader">
        <v-file-input multiple label="Documenti" v-model="inputFiles" @change="fileSelected" ></v-file-input>
        <div class="files">
            <div class="file" v-for="(file,index) in files" :key="index">

                <div class="icon">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-icon class="orangeColor" v-on="on"> mdi-cloud-upload </v-icon>
                        </template>
                        <span>Da Caricare</span>
                    </v-tooltip>
                </div>
                
                <v-text-field dense v-model="file.nome"></v-text-field>
                <div class="size">[{{bytesToSize(file.size)}}]</div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            file:'',
            files: [],
            inputFiles: undefined,
        }
    },
    methods: {
        fileSelected(e){
            this.files = [];
            e.forEach(file => {
                this.files.push({
                    nome: this.removePoint(file.name),
                    size: file.size,
                })
            });
        },
        bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        removePoint(string){
            return string.split('.')[0]
        }
    },
}
</script>

<style lang="scss" scoped>
.file{
    display: flex;
    margin-bottom: 10px;
    height: 100%;
}
.icon{
    width: 30px;
    margin-top: 5px;
}
.size{
    margin-top: 8px;
    margin-left: 5px;
    font-size: 10pt;
}
.files{
    max-height: 600px;
}
</style>
