<template>
    <v-app>
        <AdminNavbar :drawer="drawer" v-if="$cookies.get('adminToken')"/>
    <v-main class="generalContainer">
        <div class="loading" v-if="loading!=0">
            <div class="loader">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            
        </div>
        <router-view/>
    </v-main>
    </v-app>
</template>

<script>
import AdminNavbar from '../../components/admin/navbar.vue'
export default {
    components:{AdminNavbar},
    metaInfo() {
        return { 
            title: "AggiudicaRe - ADMIN",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - ADMIN"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
    data() {
        return {
            drawer:false,
            loading:0,
        }
    },
    beforeCreate(){
        if(!this.$cookies.get('adminToken') && this.$route.fullPath != '/admin/login'){
            this.$router.replace('/admin/login');
        }
    },
    provide(){
        return{
            toogleLoading: bool=> this.toogleLoading(bool),  
        }
    },
    methods: {
        toogleLoading(bool){
            if(bool){
                this.loading++;
            }else{
                if(this.loading>0){this.loading--;}
                
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.generalContainer{
  min-height: 100vh;
}
.loading{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.541);
    backdrop-filter: blur(3px);
    z-index: 999999;
}
.loader{
    position: absolute;
    top:50%;left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: max-content;
}
</style>