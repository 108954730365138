import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    incarichi:[],
    incarichiCounter:{
        total:0,
        inRicerca:0,
        ricercaSospesa:0,
        ricercaConclusa:0,
        inAsta:0,
        aggiudicata:0,
        persa:0,
        rinunciaRicerca:0,
        rinunciaAsta:0,
    },
    incarichiStats:{
        provvigioniPagate:0,
        provvigioniPagateVal:0,
        provvigioniDaPagare:0,
        provvigioniDaPagareVal:0,
        ricerchePagate:0,
        ricerchePagateVal:0,
        ricercheDaPagare:0,
        ricercheDaPagareVal:0,
    },
    retrivedIncarichi: false,
}

const getters = {
    incarichi: state => state.incarichi,
    retrivedIncarichi: state => state.retrivedIncarichi,
    incarichiCounter: state => state.incarichiCounter,
    incarichiStats: state => state.incarichiStats,
}

const actions = {
    async createIncaricoUser({commit},data){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('form',JSON.stringify(data.form));
            if(data.files){
                data.files.forEach(file => {
                    toSend.append('file',file);
                });    
            }
            
            var config = {
                method: 'post',
                url: Server+'/incarichi/createIncaricoUser',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookie.get('token')}`,
                },
                data : toSend
            };

            axios(config)
                .then(function (response) {
                    commit("addNewIncaricoUser",response.data)
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        })
    },
    async fetchAllIncarichiFromUser({commit}){
        return new Promise((resolve,reject)=>{
            var config = {
                method: 'get',
                url: Server+'/incarichi/getAllMineFromUser',
                headers: { 
                    'Authorization': `Bearer ${cookie.get('token')}`,
                },
            };
            axios(config)
            .then(function (response) {
                commit("storeAllIncarichi",response.data)
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async fetchAllIncarichiFromAdmin({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
        var response = await axios.get(Server+'/incarichi/getAllAsAdmin',config);
        commit("storeAllIncarichi",response.data)
    },
    async deleteIncaricoFromAdmin({commit},id){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/incarichi/deleteIncaricoAsAdmin',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("deleteIncaricoAsAdminFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async deleteIncaricoFromUser({commit},id){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
            axios.post(Server+'/incarichi/deleteIncaricoAsUser',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("deleteIncaricoFromUserFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async editIncaricoDocument({commit},data){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/documents/incarico/edit',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editIncaricoDocumentFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async deleteIncaricoDocument({commit},data){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/documents/incarico/remove',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("deleteIncaricoDocumentFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async addIncaricoDocument({commit},data){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('filesName',JSON.stringify(data.filesName));
            toSend.append('user_id',data.incarico.agente);
            toSend.append('incarico_id',data.incarico._id);
            if(data.files){
                data.files.forEach(file => {
                    toSend.append('file',file);
                });
            }
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            var config = {
                method: 'post',
                url: Server+'/documents/incarico/add',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                    'incarico_id': data.incarico._id,
                },
                data : toSend
            };

            axios(config)
            .then(function (response) {
                resolve(response.data);
                commit('addIncaricoDocumentFun',response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async editIncaricoFromAdmin({commit},incarico){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
            axios.post(Server+'/incarichi/editIncaricoAsAdmin',{
                incarico: incarico,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editIncaricoAsAdminFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async editIncaricoFromUser({commit},incarico){
        return new Promise((resolve,reject)=>{
            const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
            axios.post(Server+'/incarichi/editIncaricoAsUser',{
                incarico: incarico,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editIncaricoFromUserFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async createIncaricoFromAdmin({commit},data){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('form',JSON.stringify(data.form));
            if(data.files){
                data.files.forEach(file => {
                    toSend.append('file',file);
                });    
            }
            
            var config = {
                method: 'post',
                url: Server+'/incarichi/createIncaricoAsAdmin',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'agente_id':data.form.agente,
                    'Authorization': `Bearer ${cookie.get('adminToken')}`,
                },
                data : toSend
            };

            axios(config)
                .then(function (response) {
                    commit("createIncaricoAsAdminFun",response.data)
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        })
    },
    async sospendiIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/sospendi',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("sospendiIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async riprendiIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/riprendi',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("riprendiIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async rinunciaRicercaIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/rinunciaRicerca',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("rinunciaRicercaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async ricercaPagataIncaricoAction({commit},data){
        return new Promise((resolve,reject)=>{
            var token = cookie.get('adminToken');
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/pagataRicerca',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("ricercaPagataIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async ricercaConclusaIncaricoAction({commit},data){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/ricercaConclusa',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("ricercaConclusaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async inAstaIncaricoAction({commit},data){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/inAsta',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("inAstaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async astaPersaIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/astaPersa',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("astaPersaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async astaDesertaIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/astaDeserta',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("astaDesertaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async aggiudicataIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/aggiudicata',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("aggiudicataIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async rinunciaAstaIncaricoAction({commit},id){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }else{
                token = cookie.get('token');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/rinunciaAsta',{
                id: id,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("rinunciaAstaIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async provvigionePagataIncaricoAction({commit},data){
        return new Promise((resolve,reject)=>{
            var token = cookie.get('adminToken');
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/incarichi/pagataProvvigione',{
                data: data,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("provvigionePagataIncaricoActionFun",response.data);
                resolve(response.data); 
            })
        })
    },




}

const mutations = {
    addNewIncaricoUser(state,response){
        state.incarichi.push(response.incarico);
        groupIncarichi(state)
    },
    storeAllIncarichi(state,response){
        state.incarichi = response.incarichi;
        state.retrivedIncarichi = true;
        groupIncarichi(state);
    },
    deleteIncaricoAsAdminFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.deleted._id);
        if(index!=-1){
            state.incarichi.splice(index,1);
            groupIncarichi(state)
        }
    },
    deleteIncaricoFromUserFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.deleted._id);
        if(index!=-1){
            state.incarichi.splice(index,1);
            groupIncarichi(state)
        }
    },
    editIncaricoDocumentFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
        }
    },
    deleteIncaricoDocumentFun(state,response){
        const index = state.incarichi.findIndex(incarico=> incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
        }
    },
    addIncaricoDocumentFun(state,response){
        const index = state.incarichi.findIndex(incarico=> incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
        }
    },
    editIncaricoAsAdminFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state)
        }
    },
    editIncaricoFromUserFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state)
        }
    },
    createIncaricoAsAdminFun(state,response){
        state.incarichi.push(response.incarico);
        groupIncarichi(state)
    },
    sospendiIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state)
        }
    },
    riprendiIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state)
        }
    },
    rinunciaRicercaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state)
        }
    },
    ricercaPagataIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    ricercaConclusaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    inAstaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    astaPersaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    astaDesertaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    aggiudicataIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    rinunciaAstaIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
    provvigionePagataIncaricoActionFun(state,response){
        const index = state.incarichi.findIndex(incarico => incarico._id == response.incarico._id);
        if(index!=-1){
            state.incarichi[index] = response.incarico;
            groupIncarichi(state);
        }
    },
     
}


function groupIncarichi(state){
    state.incarichiCounter = {
        total:0,
        inRicerca:0,
        ricercaSospesa:0,
        ricercaConclusa:0,
        inAsta:0,
        aggiudicata:0,
        persa:0,
        rinunciaRicerca:0,
        rinunciaAsta:0,
    };
    state.incarichiStats = {
        provvigioniPagate:0,
        provvigioniPagateVal:0,
        provvigioniDaPagare:0,
        provvigioniDaPagareVal:0,
        ricerchePagate:0,
        ricerchePagateVal:0,
        ricercheDaPagare:0,
        ricercheDaPagareVal:0,
    };
    state.incarichiCounter.total = state.incarichi.length;
    state.incarichi.forEach(incarico=>{
        switch(incarico.stato){
            case 'In Ricerca': state.incarichiCounter.inRicerca++; break;
            case 'Ricerca Sospesa': state.incarichiCounter.ricercaSospesa++; break;
            case 'Ricerca Conclusa': state.incarichiCounter.ricercaConclusa++; break;
            case 'In Asta': state.incarichiCounter.inAsta++; break;
            case 'Aggiudicata': state.incarichiCounter.aggiudicata++; break;
            case 'Persa': state.incarichiCounter.persa++; break;
            case 'Rinuncia Ricerca': state.incarichiCounter.rinunciaRicerca++; break;
            case 'Rinuncia Asta': state.incarichiCounter.rinunciaAsta++; break;
        }
        if(incarico.stato=='Aggiudicata'){
            if(incarico.provvigione.pagato){
                state.incarichiStats.provvigioniPagate++;
                state.incarichiStats.provvigioniPagateVal = state.incarichiStats.provvigioniPagateVal + Number.parseFloat(incarico.provvigione.euro_num);
            }else{
                state.incarichiStats.provvigioniDaPagare++;
                state.incarichiStats.provvigioniDaPagareVal = state.incarichiStats.provvigioniDaPagareVal + Number.parseFloat(incarico.provvigione.euro_num);
            }
        }
        if(incarico.costo_ricerca.pagato){
            state.incarichiStats.ricerchePagate++;
            if(incarico.costo_ricerca.prezzo){
                state.incarichiStats.ricerchePagateVal = state.incarichiStats.ricerchePagateVal + Number.parseFloat(incarico.costo_ricerca.prezzo)
            }
        }else{
            state.incarichiStats.ricercheDaPagare++;
            if(incarico.costo_ricerca.prezzo){
                state.incarichiStats.ricercheDaPagareVal = state.incarichiStats.ricercheDaPagareVal + Number.parseFloat(incarico.costo_ricerca.prezzo)
            }
        }
    }) 
}




export default{
    state,
    getters,
    actions,
    mutations
}