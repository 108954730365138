import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    files:[],
    retrivedfiles:false,
}

const getters = {
    files: state => state.files,
    retrivedfiles: state => state.retrivedfiles,
}

const actions = {
    async fetchAllFiles({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
        var response = await axios.get(Server+'/files/fetchAll',config);
        commit("fetchAllFilesFun",response.data)
    },
    async fetchPublicFiles({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('token')}` }};
        var response = await axios.get(Server+'/files/fetchAllPublic',config);
        commit("fetchAllFilesFun",response.data)
    },
    async addFile({commit},documents){
        return new Promise((resolve,reject)=>{
            var toSend = new FormData();
            toSend.append('filesName',JSON.stringify(documents.filesName));
            if(documents.files){
                    documents.files.forEach(file => {
                   toSend.append('file',file);
               });    
            }
            var config = {
                method: 'post',
                url: Server+'/files/add',
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookie.get('adminToken')}`,
                },
                data : toSend
            };
            axios(config)
            .then(function (response) {
                commit('addFileFun',response.data);
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response.data);
            });
        })
    },
    async deleteFile({commit},file){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/files/remove',{
                file: file,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("deleteFileFun",response.data);
                resolve(response.data); 
            })
        })
    },
    async editFile({commit},file){
        return new Promise((resolve,reject)=>{
            var token;
            if(cookie.get('adminToken')!=null){
                token = cookie.get('adminToken');
            }
            const config = {headers: { Authorization: `Bearer ${token}` }};
            axios.post(Server+'/files/edit',{
                file: file,
            },config).catch((error)=>{
                reject(error.response.data);
            }).then(function (response){
                commit("editFileFun",response.data);
                resolve(response.data); 
            })
        })
    }
}

const mutations = {
    fetchAllFilesFun(state,response){
        state.files = response.files;
        state.retrivedfiles = true;
    },
    addFileFun(state,response){
        var newArray = state.files.concat(response.files);
        state.files = newArray;
    },
    deleteFileFun(state,response){
        const index = state.files.findIndex(file=> file._id == response.file._id);
        if(index!=-1){
            state.files.splice(index,1);
        }
    },
    editFileFun(state,response){
        const index = state.files.findIndex(file=> file._id == response.file._id);
        if(index!=-1){
            state.files[index] = response.file;
        }
    }
}



export default{
    state,
    getters,
    actions,
    mutations
}