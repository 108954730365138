<template>
    <div id="videoFormazione">
        <div class="videoFormazione elevation-9">
            <span class="data">{{formatDate(videoFormazione.data)}}</span>
            <div class="titolo"> {{videoFormazione.titolo}} </div>
            <iframe :src="'https://player.vimeo.com/video/'+videoFormazione.urlVideo" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            <div class="description">
                <p class="descrizioneTesto">{{videoFormazione.descrizione}}</p>
            </div>
            <div class="description">
                <span class="hashtag" v-for="(hashtag,index) in videoFormazione.hashtags" :key="index">#{{hashtag}}</span>
            </div>
            <div style="clear:both;"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:['videoFormazione'],
    methods: {
        formatDate(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            return giorno+'/'+mese+'/'+data.getFullYear();
        },
    },
}
</script>

<style lang="scss" scoped>
.videoFormazione{
    border-radius: 15px;
    padding-top: 10px;
    max-width: 300px;
    height: 100%;
    padding-bottom: 10px;
}

.titolo{
    font-size: 15pt;
    line-height: 20px;
    font-weight: 600;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    color: rgba(0, 0, 0, 0.705);
}
.description{
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
}
.descrizioneTesto{
    font-size: 10pt;
    line-height: 14px;
    margin-bottom: 0px;
}

.data{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 8pt;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
    padding: 1px 3px 1px 3px;
    border-radius: 5px;
    width: max-content;
    margin-left: 10px;
}
.hashtag{
    float: left;
    margin: 5px 2px 0px 2px;
    border: 1px solid var(--primaryColor);
    background-color: var(--primaryColor);
    padding: 1px 3px 1px 3px;
    font-size: 9pt;
    color: white;
    border-radius: 5px;
}

</style>