<template>
    <div id="incarico_tab">
        <template>
            <v-card>
                <v-tabs v-model="tab" centered icons-and-text >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#generale"> Generale <v-icon>mdi-focus-field</v-icon> </v-tab>
                    <v-tab href="#documenti"> Documenti <v-icon>mdi-file-document</v-icon> </v-tab>
                    <v-tab href="#impostazioni"> Impostazioni <v-icon>mdi-cog</v-icon> </v-tab>
                </v-tabs>
                <v-divider></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="generale" >
                        <IncaricoGenerale :incarico="incarico" />
                    </v-tab-item>
                    <v-tab-item value="documenti" >
                        <IncaricoDocumenti :incarico="incarico" :key="userDocKey" />
                    </v-tab-item>
                    <v-tab-item value="impostazioni" >
                        <IncaricoSettings :incarico="incarico" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>
    </div>
</template>

<script>
import IncaricoSettings from './incarico_settings.vue'
import IncaricoGenerale from './incarico_generale.vue'
import IncaricoDocumenti from './incarico_documents.vue'
export default {
    components:{IncaricoSettings,IncaricoGenerale,IncaricoDocumenti},
    props:['incarico'],
    data() {
        return {
            tab:null,
            userDocKey:0,
        }
    },
    watch:{
        tab(newV,oldV){
            if(oldV != null && newV!='generale'){
                if(this.tab != this.$route.query.tab){
                    this.$router.push({ query: Object.assign({}, this.$route.query, { tab: this.tab }) });    
                } 
            }
            if(oldV != null && newV=='generale'){
                this.$router.push(this.$route.path).catch(()=>{
                    
                })
            }
        },
        $route (){
            if(!this.$route.query.tab){
                this.tab='generale'
            }else{
                if(this.tab != this.$route.query.tab){
                    this.tab=this.$route.query.tab
                }
            }
        }
    },
    methods: {
        forceRerender() {
            console.log('rerendering')
            this.userDocKey += 1;  
        },
    },
    mounted() {
        if(this.$route.query.tab){
            if(this.tab != this.$route.query.tab){
                this.tab = this.$route.query.tab;    
            } 
        }else{
            this.tab='generale'
        }
    },
}
</script>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined){
    box-shadow: none;
}
</style>