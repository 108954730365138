var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"generale"}},[(_vm.incarichiCounter.total>0)?_c('div',{staticClass:"incarichi"},[_c('div',{staticClass:"secTitle notSelectableTxt"},[_vm._v("INCARICHI")]),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize,
                color:'inRicerca',
                widthUnder380:'100'
            },"percent":Math.floor((_vm.incarichiCounter.inRicerca*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.inRicerca,"name":'In Ricerca'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'ricercaSospesa',
                widthUnder380:'100'
            },"percent":Math.floor((_vm.incarichiCounter.ricercaSospesa*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.ricercaSospesa,"name":'Ricerca Sospesa'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'ricercaConclusa',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.ricercaConclusa*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.ricercaConclusa,"name":'Ricerca Conclusa'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize,
                color:'primaryColor',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.inAsta*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.inAsta,"name":'In Asta'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize,
                color:'aggiudicata',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.aggiudicata*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.aggiudicata,"name":'Aggiudicata'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize,
                color:'persa',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.persa*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.persa,"name":'Persa'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'rinunciaRicerca',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.rinunciaRicerca*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.rinunciaRicerca,"name":'Rinuncia Ricerca'}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-1,
                color:'rinunciaAsta',
                widthUnder380:'100'
                },"percent":Math.floor((_vm.incarichiCounter.rinunciaAsta*100)/_vm.incarichiCounter.total),"count":_vm.incarichiCounter.rinunciaAsta,"name":'Rinuncia Asta'}})],1):_vm._e(),(_vm.incarichiCounter.total>0)?_c('div',{staticClass:"incarichiStatistiche"},[_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'ricercaSospesa',
                widthUnder380:'100',
                font_sizeUnder380:8,
                },"percent":_vm.incarichiStats.ricercheDaPagare!=0?Math.floor((_vm.incarichiStats.ricercheDaPagare*100)/(_vm.incarichiStats.ricerchePagate+_vm.incarichiStats.ricercheDaPagare)):0,"count":_vm.moneyFormat(_vm.incarichiStats.ricercheDaPagareVal)+'€',"name":'Ricerche Da Pagare',"subb":_vm.incarichiStats.ricercheDaPagare}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'aggiudicata',
                widthUnder380:'100',
                font_sizeUnder380:8,
                },"percent":100-Math.floor((_vm.incarichiStats.ricercheDaPagare*100)/(_vm.incarichiStats.ricerchePagate+_vm.incarichiStats.ricercheDaPagare)),"count":_vm.moneyFormat(_vm.incarichiStats.ricerchePagateVal)+'€',"name":'Ricerche Pagate',"subb":_vm.incarichiStats.ricerchePagate}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'ricercaSospesa',
                widthUnder380:'100',
                font_sizeUnder380:8,
                },"percent":_vm.incarichiStats.provvigioniDaPagare!=0?Math.floor((_vm.incarichiStats.provvigioniDaPagare*100)/(_vm.incarichiStats.provvigioniDaPagare+_vm.incarichiStats.provvigioniPagate)):0,"count":_vm.moneyFormat(_vm.incarichiStats.provvigioniDaPagareVal)+'€',"name":'Provvigioni Da Pagare',"subb":_vm.incarichiStats.provvigioniDaPagare}}),_c('CircularChar',{attrs:{"options":{
                width:_vm.incaricoTortaWidth,
                font_size:_vm.incaricoTortaFontSize-2,
                color:'aggiudicata',
                widthUnder380:'100',
                font_sizeUnder380:8,
                },"percent":_vm.incarichiStats.provvigioniPagate!=0?100-Math.floor((_vm.incarichiStats.provvigioniDaPagare*100)/(_vm.incarichiStats.provvigioniDaPagare+_vm.incarichiStats.provvigioniPagate)):0,"count":_vm.moneyFormat(_vm.incarichiStats.provvigioniPagateVal)+'€',"name":'Provvigioni Pagate',"subb":_vm.incarichiStats.provvigioniPagate}})],1):_vm._e(),(_vm.incarichiCounter.total>0)?_c('v-divider'):_vm._e(),(_vm.user.usersMenaged && _vm.user.usersMenaged.length>0)?_c('div',{staticClass:"utentiGestiti"},[_c('div',{staticClass:"secTitle notSelectableTxt"},[_vm._v("AGENTI GESTITI")]),_vm._l((_vm.user.usersMenaged),function(agente){return _c('UserPreview',{key:agente._id,attrs:{"user":agente}})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }