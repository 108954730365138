<template>
    <div>
        <div class="storicoPassaggio" v-for="(step,index) in storico.slice().reverse()" :key="index">
            <div class="flexxed">
                <div class="left">
                    <v-icon dark small color="red" class="iconaStorico" v-if="step.chi=='Admin'" >mdi-alpha-a-circle</v-icon>
                    <v-icon dark small color="black" class="iconaStorico" v-if="step.chi=='User'" >mdi-account-circle</v-icon>
                
                    [<span class="data">{{formatDateHour(step.data)}}</span>]
                </div>
                <div class="right">
                    <v-chip class="ma-2" color="inRicerca" small label text-color="black" v-if="step.da=='In Ricerca'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="ricercaSospesa" small label text-color="black" v-if="step.da=='Ricerca Sospesa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="ricercaConclusa" small label text-color="black" v-if="step.da=='Ricerca Conclusa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="inAsta" small label v-if="step.da=='In Asta'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="aggiudicata" small label v-if="step.da=='Aggiudicata'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="persa" small label v-if="step.da=='Persa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="rinunciaRicerca" small label v-if="step.da=='Rinuncia Ricerca'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>
                    <v-chip class="ma-2" color="rinunciaAsta" small label v-if="step.da=='Rinuncia Asta'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.da}}
                    </v-chip>

                    <v-tooltip top v-if="step.dettagli">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small color="primary">mdi-arrow-right-bold</v-icon>
                        </template>
                        <span v-html="step.dettagli" class="dettagliSpan"></span>
                    </v-tooltip>
                    <v-icon v-if="!step.dettagli" small>mdi-arrow-right-bold</v-icon>

                    <v-chip class="ma-2" color="inRicerca" small label text-color="black" v-if="step.a=='In Ricerca'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="ricercaSospesa" small label text-color="black" v-if="step.a=='Ricerca Sospesa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="ricercaConclusa" small label text-color="black" v-if="step.a=='Ricerca Conclusa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="inAsta" small label v-if="step.a=='In Asta'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="aggiudicata" small label v-if="step.a=='Aggiudicata'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="persa" small label v-if="step.a=='Persa'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="rinunciaRicerca" small label v-if="step.a=='Rinuncia Ricerca'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                    <v-chip class="ma-2" color="rinunciaAsta" small label v-if="step.a=='Rinuncia Asta'" >
                        <v-icon left> mdi-state-machine </v-icon>
                        {{step.a}}
                    </v-chip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['storico'],
    methods: {
        formatDateHour(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            var ore = data.getHours()<10?'0'+data.getHours():data.getHours();
            var minuti = data.getMinutes()<10?'0'+data.getMinutes():data.getMinutes();
            return giorno+'/'+mese+'/'+data.getFullYear()+' - '+ore+':'+minuti;
        }
    },
}
</script>

<style lang="scss" scoped>
.iconaStorico{
    margin-right: 0px;
    margin-top: -3px;
}
.storicoPassaggio{
    padding-right: 5px;
    .data{font-size: 10pt; font-weight: 500;}
}
.v-application .ma-2{
    margin: 3px 2px!important;
}
.dettagliSpan{
    font-size: 9pt;
}
.flexxed{
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 477px) {flex-flow: column; align-items: center;margin-bottom: 10px;}
}
</style>