<template>
    <div id="immobile">
        <div class="immobile" v-if="immobile">
            <div class="offertaImperdibile" v-if="immobile.offertaImperdibile">OFFERTA<br>IMPERDIBILE</div>
            <div class="carosello">
                <carousel :perPage="1" :paginationEnabled="false" :autoplay="true" v-if="immobile.foto.length>0">
                    <slide v-for="(item,i) in immobile.foto" :key="i" :src="item" class="slideItem">
                        <div class="carousellImg" :style="'background-image: url(\''+item+'\')'"></div>
                        <div class="position">{{i+1}}/{{immobile.foto.length}}</div>
                    </slide>
                </carousel>
                <div class="notPhoto" v-else>
                    <div class="content notSelectableTxt">
                        <v-icon>mdi-camera-off</v-icon>
                        <div>NESSUNA FOTO</div>
                    </div>
                </div>
            </div>
            <div class="dati">
                <div class="titolo">
                    <span v-for="(categoria,index) in immobile.categoria" :key="index">{{categoria.nome}}</span>
                </div>
                <div class="posizione">
                    <v-icon>mdi-map-marker</v-icon>
                    {{immobile.posizione.comune}} ({{immobile.posizione.provincia}}) - {{immobile.posizione.regione}} ({{immobile.posizione.nazione}})
                </div>
                <div class="prezzo">
                    <v-icon>mdi-currency-eur</v-icon>
                    <span class="fullMoney">
                        Da <span class="money">{{moneyFormat(immobile.prezzo)}}€</span>
                    </span>
                </div>
                <div class="flexxed">
                    <div class="descrizione">
                        <div class="absTitle">DESCRIZIONE</div>
                        {{immobile.descrizione}}
                    </div>
                    <div class="stats">
                        <div class="absTitle">DATI</div>
                        <div class="field"><span>Asta:</span> {{immobile.asta?'Si':'No'}}</div>
                        <div class="field" v-if="immobile.classe"><span>Classe:</span> {{immobile.classe}}</div>
                        <div class="field" v-if="immobile.metriQuadri"><span>Dim:</span> {{immobile.metriQuadri}} <small>M2</small> </div>
                        <div class="field" v-if="immobile.metriQuadriGiardino"><span>Dim. Giardino:</span> {{immobile.metriQuadriGiardino}}<small>M2</small></div>
                        <div class="field" v-if="immobile.metriQuadriTerrazzo"><span>Dim. Terrazzo:</span> {{immobile.metriQuadriTerrazzo}}<small>M2</small></div>
                        <div v-if="immobile.camere">
                            <div class="field" v-if="immobile.camere.totale"><span>Camere:</span> {{immobile.camere.totale}}</div>
                            <div class="field" v-if="immobile.camere.singole"><span>Camere Singole:</span> {{immobile.camere.singole}}</div>
                            <div class="field" v-if="immobile.camere.doppie"><span>Camere Doppie:</span> {{immobile.camere.doppie}}</div>
                        </div>
                        <div class="field" v-if="immobile.bagni"><span>Bagni:</span> {{immobile.bagni}}</div>
                        <div class="field" v-if="immobile.balconi"><span>Balconi:</span> {{immobile.balconi}}</div>
                        <div class="field" v-if="immobile.terrazzi"><span>Terrazzi:</span> {{immobile.terrazzi}}</div>
                        <div class="field" v-if="immobile.postiAuto"><span>Posti Auto:</span> {{immobile.postiAuto}}</div>
                        <div class="field" v-if="immobile.piani"><span>Piani:</span> {{immobile.piani}}</div>
                        <div class="field" v-if="immobile.vani"><span>Vani:</span> {{immobile.vani}}</div>
                        <div class="field" v-if="immobile.box"><span>Box:</span> {{immobile.box}}</div>
                        <div class="field" v-if="immobile.giardino"><span>Giardino:</span> {{immobile.giardino}}</div>
                        <div class="field" v-if="immobile.accessori && immobile.accessori.length>0">
                            <span>Accessori:</span> 
                            <span class="accessorio" v-for="(accessorio,index) in immobile.accessori" :key="index">{{accessorio}}</span>
                        </div>
                        <div class="field" v-if="immobile.riscaldamento"><span>Riscaldamento:</span> {{immobile.riscaldamento}}</div>
                        <div class="field" v-if="immobile.posizioneScheda"><span>Posizione:</span> {{immobile.posizioneScheda}}</div>
                        <div class="field" v-if="immobile.statoImmobile"><span>Stato Immobile:</span> {{immobile.statoImmobile}}</div>
                        
                    </div>
                </div>
            </div>
              
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
export default {
    components: {
        Carousel,
        Slide
    },
    computed: mapGetters(['immobili','retrivedImmobili']),
    metaInfo() {
        return { 
            title: "AggiudicaRe - Immobile",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Immobile"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
    data() {
        return {
            immobile:null,
        }
    },
    methods: {
        ...mapActions(['fetchAllImmobili']),
        setup(){
            const index = this.immobili.findIndex(imm => imm._id == this.$route.params.id)
            if(index!=-1){
                this.immobile = this.immobili[index];
            }else{
                this.$router.push('/404');
            }
        },
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        }
    },
    mounted() {
        if(!this.retrivedImmobili){
            this.fetchAllImmobili().then(()=>{
                this.setup();
            })
        }else{
            this.setup();
        }
    },
}
</script>

<style lang="scss" scoped>
.immobile{
    max-width: 1000px;
    margin: 20px auto 20px auto;
    @media screen and (max-width: 1000px) { margin-top: 0px; }
    border-radius: 15px;
    @media screen and (max-width: 1000px) { border-radius: 0px 0px 15px 15px; }
    overflow: hidden;
    box-shadow: 0 5px 6px -3px rgba(0,0,0,.2),0 9px 12px 1px rgba(0,0,0,.14),0 3px 16px 2px rgba(0,0,0,.12)!important;
    position: relative;
    .offertaImperdibile{
        position: absolute;
        top:10px;left:10px;
        background-color: rgb(78, 195, 78);
        color: white;
        font-weight: 800;
        font-size: 12pt;
        padding: 3px 5px;
        border-radius: 10px;
        text-align: center;
        line-height: 14px;
        z-index: 5;
    }
    .notPhoto{
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        .content{
            position: absolute;
            top:50%;left:50%;
            transform: translateX(-50%) translateY(-50%);
            color: rgb(194, 194, 194);
            font-weight: 600;
            text-align: center;
            line-height: 15px;
            font-size: 30pt;
            .v-icon{
                font-size: 170pt;
                color: rgb(194, 194, 194);
            }
        }
    }
    .carosello{
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        border-bottom: 1px solid rgb(223, 223, 223);
        .carousellImg{
            height: 0;
            padding-top: 56.25%;
            width: 100%;
            background-size: cover;
            background-position: center center;
        }
    }
    .dati{
        padding: 10px 20px 20px 20px;
        .posizione{
            margin-top: 10px;
        }
        .titolo{
            font-size: 22pt;
            line-height: 27px;
            color: var(--primaryColor);
            font-weight: 500;
            span{&::before{content: ', ';}}
            span:first-child{
                &::before{content: '';}
            }
        }
        .flexxed{
            display: flex;
            column-gap: 10px;
            row-gap: 10px;
            @media screen and (max-width: 500px) {
                flex-flow: column;
            }
            .descrizione{
                flex:1;
                border: 1px solid var(--primaryColor);
                border-radius: 15px;
                padding: 10px;
                position: relative;
                margin-top: 10px;
                color: rgb(65, 65, 65);
                font-size: 10pt;
                .absTitle{
                    font-size: 9pt;
                    position: absolute;
                    top:-9px;left:50%;
                    padding: 0px 5px;
                    color: var(--primaryColor);
                    font-weight: 600;
                    background-color: #fff;
                    transform: translateX(-50%);
                }
            }
            .stats{
                width: max-content;
                min-width: 30%;
                max-width: 40%;
                border: 1px solid var(--primaryColor);
                border-radius: 15px;
                padding: 10px;
                position: relative;
                margin-top: 10px;
                color: rgb(65, 65, 65);
                font-size: 10pt;
                @media screen and (max-width: 500px) {
                    width: 100%;
                    max-width: 100%;
                }
                .absTitle{
                    font-size: 9pt;
                    position: absolute;
                    top:-9px;left:50%;
                    padding: 0px 5px;
                    color: var(--primaryColor);
                    font-weight: 600;
                    background-color: #fff;
                    transform: translateX(-50%);
                }
                .field{
                    span{
                        font-weight: 600;
                    }
                    .accessorio{
                        font-weight: 400;
                    }
                    .accessorio{&::before{content: ', ';}}
                    .accessorio:first-child{
                        &::before{content: '';}
                    }
                }
            }
        }
        .prezzo{
            text-align: left;
            display: flex;
            flex-flow: row;
            align-items: center;
            .fullMoney{
                margin-left: 5px;
                .money{
                    color: var(--primaryColor);
                    font-weight: 700;
                    font-size: 20pt;
                }
            }

        }
    }
}
.slideItem{
    position: relative;
    .position{
        background-color: rgba(0, 0, 0, 0.123);
        font-size: 12pt;
        backdrop-filter: blur(5px);
        position: absolute;
        color: white;
        z-index: 6;
        bottom:5px;
        padding: 5px 9px;
        border-radius: 5px;
        font-weight: 600;
        right:5px;
    }
}
</style>