<template>
    <div id="agenti">
        <div class="mainTitle">HOME</div>
        <div class="createForm elevation-9">
            <v-form ref="form" @submit.prevent="submit" v-model="isFormValid">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-row>
                            <v-col><v-text-field v-model="form.nome" :rules="requiredRules" label="Nome *" required clearable outlined dense></v-text-field></v-col>
                            <v-col><v-text-field v-model="form.cognome" :rules="requiredRules" label="Cognome *" required clearable outlined dense></v-text-field></v-col>
                        </v-row>
                        <v-text-field v-model="form.email" :rules="emailRules" label="Email *" required clearable outlined dense></v-text-field>
                        <v-select v-model="form.figura" :rules="requiredRules" :items="figure" label="Figura *" required outlined dense></v-select>

                        <v-text-field v-model="form.cellulare" label="Cellulare"  clearable outlined dense></v-text-field>

                        <v-dialog ref="dialog" v-model="modal1" :return-value.sync="form.dataContr" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="form.dataContr" label="Data Contratt." readonly v-bind="attrs" v-on="on" clearable outlined dense></v-text-field>
                            </template>
                            <v-date-picker v-model="form.dataContr" scrollable >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal1 = false" > Cancel </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(form.dataContr)" > OK </v-btn>
                            </v-date-picker>
                        </v-dialog>


                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">UFFICIO</div>
                        </div>
                        <v-text-field v-model="form.ufficio.via" label="Via"  clearable outlined dense></v-text-field>
                        <v-row>
                            
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="form.ufficio.comune" label="Comune"  clearable outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <model-select  class="mb-20" :options="provincieOptions"
                                v-model="form.ufficio.provincia"
                                placeholder="Provincia">
                                </model-select>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top: -22px">
                            <v-col><v-text-field v-model="form.ufficio.cap" label="CAP"  clearable outlined dense></v-text-field></v-col>
                            <v-col>
                                <model-select class="mb-20" :options="regioniOptions" v-model="form.ufficio.regione" placeholder="Regione"></model-select>
                            </v-col>
                        </v-row>
                        <div class="divisore">
                            <v-divider></v-divider>
                            <div class="divisoreTitolo">SISTEMA</div>
                        </div>
                        
                        
                        <model-select class="mb-20" :options="topExecutiveOptions" v-model="form.topExecutive" placeholder="Top Executive"></model-select>
                        <model-select class="mb-20" :options="executiveOptions" v-model="form.executive" placeholder="Executive"></model-select>
                        <model-select class="mb-20" :options="teamLeaderOptions" v-model="form.teamLeader" placeholder="Team Leader"></model-select>
                        
                        
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="12" sm="6">
                            <div class="divisore">
                                <v-divider></v-divider>
                                <div class="divisoreTitolo">DOCUMENTI</div>
                            </div>
                          <fileUploader ref="fileUploader"/>
                    </v-col>
                </v-row>
                <v-btn block color="mainBtn" type="submit" class="btnSubmit" :disabled="!isFormValid">
                    CREA
                </v-btn>
                
            </v-form>
        </div>
    </div>
</template>

<script>
import fileUploader from '../../components/admin/documentsUploader.vue'
import { ModelSelect } from 'vue-search-select'
import ProvincieJson from '../../utils/provincie.json'
import RegioniJson from '../../utils/regioni.json'
import { mapActions,mapGetters } from 'vuex'
export default {
    components:{fileUploader,ModelSelect},
    data() {
        return {
            form:{
                email:undefined,
                nome:undefined,
                cognome:undefined,
                cellulare:undefined,
                dataContr:undefined,
                ufficio:{
                    via:undefined,
                    comune:undefined,
                    provincia:undefined,
                    cap:undefined,
                    regione:undefined,
                },
                figura:undefined,
                topExecutive:undefined,
                executive:undefined,
                teamLeader:undefined,
                documenti:[]
            },
            figure:["Head Executive","Top Executive","Executive","Team Leader","Auction Manager"],
            provincieOptions:[],
            regioniOptions:[],
            modal1:false,
            isFormValid:false,
            requiredRules:[
                v => !!v || 'Campo necessario',
            ],
            emailRules: [
                v => !!v || 'E-mail è necessaria',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail non valida',
            ],
            topExecutiveOptions:[],
            executiveOptions:[],
            teamLeaderOptions:[],
        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['usersExecutive','usersTeamLeader','usersTopExecutive','retrivedUsers']),
    methods: {
        ...mapActions(['createUser','adminGetAllUsers']),
        submitForm(){
            this.$refs.form.validate();
        },
        submit(){
            this.toogleLoading(true)
            this.form.documenti = this.$refs.fileUploader.files?this.$refs.fileUploader.files:[];
            this.createUser({form:this.form,files:this.$refs.fileUploader.inputFiles}).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Nuovo Utente',
                    text: response.email,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
                this.$router.push('/admin/agenti/'+response._id);
            }).catch((error)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Errore Creazione',
                    text: error.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            });
        },
        loadProvincie(){
            ProvincieJson.forEach(provincia => {
                this.provincieOptions.push({
                    value: provincia.nome,
                    text: provincia.nome,
                })
            });
        },
        loadRegioni(){
            RegioniJson.forEach(regione => {
                this.regioniOptions.push({
                    value: regione.nome,
                    text: regione.nome,
                })
            });
        },
        loadTopExectuvie(){
            this.usersTopExecutive.forEach(user=>{
                this.topExecutiveOptions.push({
                    value: user._id,
                    text: user.email,
                })
            })
        },
        loadExecutive(){
            this.usersExecutive.forEach(user=>{
                this.executiveOptions.push({
                    value: user._id,
                    text: user.email,
                })
            })
        },
        loadTeamLeader(){
            this.usersTeamLeader.forEach(user=>{
                this.teamLeaderOptions.push({
                    value: user._id,
                    text: user.email,
                })
            })
        },
        start(){
            this.loadProvincie();
            this.loadRegioni();
            this.loadTopExectuvie();
            this.loadExecutive();
            this.loadTeamLeader();
            this.toogleLoading(false)
        }
    },
    mounted() {
        if(!this.retrivedUsers){
            this.toogleLoading(true)
            this.adminGetAllUsers().then(()=>{
                this.start();
            })
        }else{
            this.toogleLoading(true)
            this.start();
        }

    }

}
</script>

<style lang="scss" scoped>
.createForm{
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    padding: 30px;
    border-radius: 15px;
}
.divisore{
    margin-bottom: 25px;
    position: relative;
}
.divisoreTitolo{
    position: absolute;
    font-size: 9pt;
    font-weight: 600;
    color: rgb(124, 124, 124);
    top:-8px;
    left:50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.btnSubmit{
    margin-top: 20px;
}
</style>