<template>
    <v-app>
        <AreaRiservataNavbar :drawer="drawer" v-if="$cookies.get('token')"/>
        <v-main class="generalContainer">
                <div class="loading" v-if="loading!=0">
                    <div class="loader">
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
                <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import AreaRiservataNavbar from '../../components/area-riservata/navbar.vue'
import { mapGetters,mapActions } from 'vuex';
export default {
    components:{AreaRiservataNavbar},
    metaInfo() {
        return { 
            title: "AggiudicaRe - Area Riservata",
            meta: [
                { name: 'description', content:  "Il network leader nel settore delle esecuzioni immobiliari a 360°, l'unico gruppo immobiliare accessibile a tutti"},
                { property: 'og:title', content: "AggiudicaRe - Area Riservata"},
                { property: 'og:site_name', content: 'AggiudicaRe'},
            ]
        }
    },
    data() {
        return {
            drawer:false,
            loading:0,
        }
    },
    computed: mapGetters(['me','retrivedMe']),
    beforeCreate(){
        if(!this.$cookies.get('token') && this.$route.fullPath != '/area-riservata/login'){
            this.$router.replace('/area-riservata/login');
        }
    },
    created(){
        if(this.$cookies.get('token') && !this.retrivedMe){
            this.fetchUserFromToken();
        }
    },
    mounted(){
        
    },
    provide(){
        return{
            toogleLoading: bool=> this.toogleLoading(bool),  
        }
    },
    methods: {
        ...mapActions(['fetchUserFromToken']),
        toogleLoading(bool){
            if(bool){
                this.loading++;
            }else{
                if(this.loading>0){this.loading--;}
                
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.generalContainer{
  min-height: 100vh;
}
.loading{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.541);
    backdrop-filter: blur(3px);
    z-index: 999999;
}
.loader{
    position: absolute;
    top:50%;left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: max-content;
}
</style>