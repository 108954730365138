<template>
    <div id="documenti">
        <div class="mainTitle">FILES</div>
        <div class="input elevation-9">
            <v-form class="form" @submit.prevent="loadFiles">
                <div class="flexed">
                    <v-file-input multiple label="Carica" v-model="inputFiles" @change="fileSelected" class="input" ></v-file-input>
                    <div class="bottone">
                        <v-btn class="mx-2" small fab dark color="green" type="submit" > <v-icon dark> mdi-plus </v-icon> </v-btn>    
                    </div>
                </div>
                <div class="files">
                    <div class="file" v-for="(file,index) in selectedDocs" :key="index">

                        <div class="icon">
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-icon class="orangeColor" v-on="on"> mdi-cloud-upload </v-icon>
                                </template>
                                <span>Da Caricare</span>
                            </v-tooltip>
                        </div>
                        
                        <v-text-field dense v-model="file.nome"></v-text-field>
                        <div class="size">[{{bytesToSize(file.size)}}]</div>
                        
                    </div>
                </div>
                
            </v-form>
        </div>
        <div class="tabella elevation-9" :key="reloadKey">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left"> NOME </th>
                        <th class="text-left"> DATA </th>
                        <th class="text-left"> FORMATO </th>
                        <th class="text-left"> DIMENSIONI </th>
                        <th class="text-left"> PUBBLICO </th>
                        <th class="text-left"> AZIONI </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="documento in files" :key="documento._id">
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ documento.nome }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ formatDateHour(documento.data) }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ getExt(documento.indirizzo) }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">{{ bytesToSize(documento.size) }}</td>
                        <td @click="linkto(documento.indirizzo)" style="cursor:pointer">
                            <v-icon v-if="documento.public" color="primary">mdi-check-circle</v-icon>
                            <v-icon v-if="!documento.public" color="error">mdi-close-circle</v-icon>
                        </td>
                        <td>
                            <v-dialog v-model="documento.modalEdit" width="500" >
                                
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" > mdi-pencil </v-icon>
                                    </template>
                                    
                                <v-card>
                                    <v-card-title><span class="text-h5">Modifica File</span></v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-form @submit.prevent="edit(documento)">
                                                <v-text-field style="display:none" dense v-model="documento._id" label="ID" required disabled></v-text-field>
                                                <v-text-field dense v-model="documento.newName" label="NOME" required></v-text-field>
                                                <v-checkbox v-model="documento.newPublic" :label="`Pubblico`" ></v-checkbox>
                                                <v-btn block color="orange" type="submit"> Modifica </v-btn>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>


                            <v-dialog width="500" >
                                
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="error" dark v-bind="attrs" v-on="on" > mdi-delete </v-icon>
                                    </template>
                                    
                                <v-card>
                                    <v-card-title><span class="text-h5">Cancella File</span></v-card-title>
                                    <v-card-text>
                                        Sei sicuro di voler <b class="redText">cancellare</b> il file <br>( {{documento.nome}} )?
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cancella(documento)" > CANCELLA </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            modalDelete:false,
            documenti:[],
            selectedDocs: [],
            inputFiles: undefined,
            reloadKey:0,
        }
    },
    inject: ['toogleLoading'],
    computed: mapGetters(['files','retrivedfiles']),
    mounted() {
        if(!this.retrivedfiles){
            this.fetchAllFiles().then(()=>{
                this.addModal(this.files);
            });
        }else{this.addModal(this.files);}
    },
    watch:{
        files(){
            this.addModal(this.files);
        }
    },
    methods: {
        ...mapActions(['addFile','fetchAllFiles','deleteFile','editFile']),
        fileSelected(e){
            this.selectedDocs = [];
            e.forEach(file => {
                this.selectedDocs.push({
                    nome: this.removePoint(file.name),
                    size: file.size,
                })
            });
        },
        removePoint(string){
            return string.split('.')[0]
        },
        bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        getExt(string){
            var str = string.split('.');
            return '.'+str[str.length-1];
        },linkto(link){
            window.open(link, '_blank');
        },        
        addModal(files){
            files.forEach(file => {
                file.modalEdit = false;
                file.modalDelete = false;
                file.newName = file.nome;
                file.newPublic = file.public;
            });
        },
        formatDateHour(date){
            var data = new Date(date);
            var giorno = data.getDate()<10?'0'+data.getDate():data.getDate();
            var mese = (data.getMonth()+1)<10?'0'+(data.getMonth()+1):(data.getMonth()+1);
            var ore = data.getHours()<10?'0'+data.getHours():data.getHours();
            var minuti = data.getMinutes()<10?'0'+data.getMinutes():data.getMinutes();
            return giorno+'/'+mese+'/'+data.getFullYear()+' - '+ore+':'+minuti;
        },
        loadFiles(){
            if(this.selectedDocs.length==0){
                return this.$notify({
                    group: 'baseNotification',
                    title: 'Errore Caricamento',
                    text: 'Nessun file selezionato',
                    duration: 2000,
                    type: 'error'
                });
            }
            this.toogleLoading(true)
            this.addFile({filesName:this.selectedDocs,files:this.inputFiles}).then((response)=>{
                this.inputFiles = undefined;
                this.selectedDocs= [];
                this.$notify({
                    group: 'baseNotification',
                    title: 'Caricamento Files',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Caricamento Files:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        },
        edit(file){
            this.toogleLoading(true)
            this.editFile(file).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica File',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.reloadKey++;
                this.addModal(this.files);
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Modifica File:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
            file.modalEdit = false;
        },
        cancella(file){
            this.toogleLoading(true)
            this.deleteFile(file).then((response)=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione File',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.toogleLoading(false)
            }).catch(error=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Cancellazione File:',
                    text: error.response.data.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
            file.modalDelete = false;
        }
    },
}
</script>

<style lang="scss" scoped>
.tabella{
    max-width: 1000px;
    margin: 20px auto;
    border-radius: 15px;
    overflow: hidden;
}
.input{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 20px;
    padding: 10px;
}
.bottone{
    min-height: 100%;
    display: flex;
    align-items: center;
}
.flexed{
    display: flex;
}
.file{
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
}
.icon{
    width: 30px;
    margin-top: 5px;
}
.size{
    margin-top: 8px;
    margin-left: 5px;
    font-size: 10pt;
}

</style>