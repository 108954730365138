<template>
    <div class="flexibleData">
        <div class="datiUtente boxed" v-if="incarico.utente">
            <div class="boxedTitle notSelectableTxt">UTENTE</div>
            <div class="field"><span>NOME: </span>{{incarico.utente.nome}} {{incarico.utente.cognome}}</div>
            <div class="field"><span>EMAIL: </span>{{incarico.utente.email}}</div>
            <div class="field"><span>CELLULARE: </span>{{incarico.utente.cellulare}}</div>
        </div>
        <div class="ricerca boxed" v-if="incarico.costo_ricerca">
            <div class="boxedTitle notSelectableTxt">DATI RICERCA</div>
            <div class="field"><span>COSTO: </span>{{incarico.costo_ricerca.prezzo}} €</div>
            <div class="field"><span>PAGATO: </span>{{incarico.costo_ricerca.pagato?'Si':'No'}}</div>
            <div class="field" v-if="incarico.costo_ricerca.pagato"><span>DATA: </span>{{$parent.formatDate(incarico.costo_ricerca.dataPagamento)}}</div>
        </div>
        <div class="immobile boxed" v-if="incarico.stato != 'In Ricerca' && incarico.stato != 'Ricerca Sospesa' && incarico.immobile">
            <div class="boxedTitle notSelectableTxt">DATI IMMOBILE</div>
            <div class="field"><span>INDIRIZZO: </span>{{incarico.immobile.indirizzo}}</div>
            <div class="field"><span>VALORE: </span>{{moneyFormat(incarico.immobile.valore)}} €</div>
        </div>
        <div class="asta boxed" v-if="incarico.stato != 'In Ricerca' && incarico.stato != 'Ricerca Sospesa' && incarico.stato != 'Ricerca Conclusa'">
            <div class="boxedTitle notSelectableTxt">DATI ASTA</div>
            <div class="field"><span>DATA: </span>{{$parent.formatDate(incarico.data_asta)}}</div>
            <div class="field"><span>IMPORTO: </span>{{moneyFormat(incarico.importo_asta)}} €</div>
            <div v-if="incarico.provvigione">
                <div class="field" v-if="incarico.provvigione.perc"><span>% PROV: </span>{{incarico.provvigione.perc}}%</div>
                <div class="field" v-if="incarico.provvigione.euro_num"><span>€ PROV: </span>{{moneyFormat(incarico.provvigione.euro_num)}} €</div>
                <div class="field" v-if="incarico.provvigione.euro_let"><span>PROV: </span>{{incarico.provvigione.euro_let}}</div>
                <div class="field" v-if="incarico.provvigione.pagato"><span>PAGATO: </span>{{incarico.provvigione.pagato?'Si':'No'}} 
                    <span class="noWeight" v-if="incarico.provvigione.pagato"> - {{$parent.formatDate(incarico.provvigione.dataPagamento)}}</span>
                </div>
            </div>
        </div>

        <div v-if="incarico.storico">
            <div class="storico boxed" v-if="incarico.storico.length>0">
                <div class="boxedTitle notSelectableTxt">STORICO</div>
                <div class="datiStorico">
                    <StoricoIncarico :storico="incarico.storico" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StoricoIncarico from './storicoIncarico.vue'
export default {
    components:{StoricoIncarico},
    props:['incarico'],
    methods: {
        moneyFormat(val){
            let dollarUSLocale = Intl.NumberFormat('it-IT');
            return dollarUSLocale.format(val);
        }
    },
}
</script>

<style lang="scss" scoped>
    .flexibleData{
        display: flex;
        column-gap: 10px;
        flex-flow: row wrap;
        justify-content: left;
        justify-content: center;
    }
    .boxed{
        border: 1px solid rgb(194, 194, 194);
        border-radius: 10px;
        padding: 10px;
        width: max-content;
        max-width: 100%;
        position: relative;
        margin-top: 10px;
        .boxedTitle{
            position: absolute;
            color: rgb(194, 194, 194);
            background: white;
            padding: 0px 5px;
            font-size: 9pt;
            line-height: 9px;
            top: -4px;
        }
        .field{
            font-size: 11pt;
            span{
                font-size: 10pt;
                font-weight: 600;
            }
            .noWeight{
                font-size: 11pt;
                font-weight: 400;
            }
        }
    }

    .boxed{
        border: 1px solid rgb(194, 194, 194);
        border-radius: 10px;
        padding: 10px;
        width: max-content;
        position: relative;
        margin-top: 10px;
        .boxedTitle{
            position: absolute;
            color: rgb(194, 194, 194);
            background: white;
            padding: 0px 5px;
            font-size: 9pt;
            line-height: 9px;
            top: -4px;
        }
        .field{
            font-size: 11pt;
            span{
                font-size: 10pt;
                font-weight: 600;
            }
            .noWeight{
                font-size: 11pt;
                font-weight: 400;
            }
        }
    }

.datiStorico{
    max-height: 150px;
    overflow: auto;
}

.immobile{
    max-width: 250px;
    line-height: 15px;
    padding-top: 13px;
    padding-bottom: 4px;
    .field{
        margin-bottom: 8px;
    }
}

/* width */
.datiStorico::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.datiStorico::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.datiStorico::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
.datiStorico::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>