import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import Incarichi from './modules/incarichi'
import Accessi from './modules/accessi'
import Immobili from './modules/immobili'
import Files from './modules/files'
import Agenzie from './modules/agenzie'
import VideoFormazione from './modules/videoFormazione'
import Eventi from './modules/eventi'
import StoricoIncarichi from './modules/storicoIncarichi'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    User,Incarichi,Accessi,Immobili,Files,VideoFormazione,Agenzie,Eventi,StoricoIncarichi
  }
})
