<template>
    <div id="aggiudicataAsta">
        <v-dialog v-model="dialog" width="500" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="maxlarge" color="aggiudicata" v-bind="attrs" v-on="on" > ASTA VINTA </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 aggiudicata lighten-2"> ASTA VINTA? </v-card-title>
                <v-card-text>
                    <b>Aggiudicare</b> l'asta, porterà l'incarico nello stato di 'Aggiudicata', e sblocchera la possibilità del pagamento delle provvigioni.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn depressed color="aggiudicata" @click="dialog = false;AggiudicataAsta()" > VINTA </v-btn>
                        <v-btn depressed @click="dialog = false;" > ANNULLA </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props:['incarico'],
    data() {
        return {
            dialog:false,
        }
    },
    inject: ['toogleLoading'],
    methods: {
        ...mapActions(['aggiudicataIncaricoAction']),
        AggiudicataAsta(){
            this.toogleLoading(true)
            this.aggiudicataIncaricoAction(this.incarico._id).then(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Asta Aggiudicata:',
                    text: response.message,
                    duration: 2000,
                    type: 'success'
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.forceRerender();
                this.toogleLoading(false)
            }).catch(response=>{
                this.$notify({
                    group: 'baseNotification',
                    title: 'Asta Aggiudicata:',
                    text: response.message,
                    duration: 2000,
                    type: 'error'
                });
                this.toogleLoading(false)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.maxlarge{
    width: 100%;
}
.v-dialog > .v-card > .v-card__text{
    padding: 20px;
}
</style>