import axios from 'axios';
import { Server } from '../../utils/variabili';
import cookie from 'vue-cookies'


const state = {
    storicoIncarichi: [],
    retrivedStoricoIncarichi: false,
}

const getters = {
    storicoIncarichi: state => state.storicoIncarichi,
    retrivedStoricoIncarichi: state => state.retrivedStoricoIncarichi,
}

const actions = {
    async fetchStoricoIncarichi({commit}){
        const config = {headers: { Authorization: `Bearer ${cookie.get('adminToken')}` }};
        var response = await axios.get(Server+'/storicoIncarichi/getAllAsAdmin',config);
        commit("fetchStoricoIncarichiFun",response.data)
    }
}

const mutations = {
    fetchStoricoIncarichiFun(state,data){
        state.storicoIncarichi = data.storicoIncarichi;
        state.retrivedStoricoIncarichi = true;
        console.log(state.storicoIncarichi);
    }
}



export default{
    state,
    getters,
    actions,
    mutations
}